import React, { useEffect } from 'react';
import './Wrapper.scss';
import useAccountData from '../../../hooks/account/UseAccountData';
import Loader from '../loader/Loader';
import { useNavigate } from 'react-router-dom';
import { PROJECTS } from '../../../routes/path';
import { useAccount } from '../../../contexts/account/AccountContexts';

interface WrapperProps {
  children: React.ReactNode;
  checkSuperAdmin?: boolean | false;
}

const Wrapper: React.FC<WrapperProps> = ({ children, checkSuperAdmin }) => {
  const loading = useAccountData();
  const navigate = useNavigate();
  const { accountResponse } = useAccount();

  useEffect(() => {
    if (!loading && checkSuperAdmin && (!accountResponse || !accountResponse.isSuperAdmin)) {
      navigate(PROJECTS);
    }
  }, [checkSuperAdmin, navigate, loading]);

  if (loading) {
    return <Loader />;
  }

  return <div>{children}</div>;
};

export default Wrapper;
