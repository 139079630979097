import React from 'react';
import './Title.scss';
import { TitleProps } from './models/TitleModel';
import { NavLink } from 'react-router-dom';

const Title: React.FC<TitleProps> = ({
  link,
  mainCategory,
  subCategory,
  showStatusDot = false,
  children,
  childrenOnMain,
}) => {
  return (
    <div className="title-box">
      <>
        <div className="title-box__title">
          <div className="title-box__nav">
            {showStatusDot && (
              <div className="status status status--active">
                <div className="status__ring" />
                <div className="status__dot" />
              </div>
            )}
            <>
              {link ? (
                <NavLink to={link} className="title-box__category">
                  {mainCategory}
                </NavLink>
              ) : (
                <span className="title-box__category">{mainCategory}</span>
              )}
              {subCategory && (
                <span className="title-box__arrow material-symbols-outlined">arrow_forward_ios</span>
              )}
            </>
          </div>
          {subCategory && <span className="title-box__subcategory">{subCategory}</span>}
          {childrenOnMain != null && <div className="title-box__main-child">{childrenOnMain}</div>}
        </div>
        {children !== null && children}
      </>
    </div>
  );
};

export default Title;
