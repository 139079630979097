import React, { useState } from 'react';
import './ChangePasswordPage.scss';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useForm } from 'react-hook-form';
import { PasswordData } from './models/PasswordData';
import { changePassword } from './services/ChangePasswordService';
import { useAuth } from '../../../shared/contexts/auth/AuthContext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useTranslation } from 'react-i18next';

const ChangePasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const [apiError, setApiError] = useState<string | null>(null);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { logout } = useAuth();

  const passwordChangedDialog = () => {
    confirmDialog({
      message: 'Your password has been successfully changed.',
      header: 'Password Changed',
      defaultFocus: 'accept',
      acceptLabel: 'OK',
      accept: () => logout(),
      reject: () => logout(),
    });
  };

  const onSubmit = async (formData: any) => {
    await changePassword(formData as PasswordData)
      .then(() => passwordChangedDialog())
      .catch((err) => setApiError(err.response.data.error));
  };

  return (
    <div className="change-password-page">
      <ConfirmDialog className="dialog-box dialog-box--accept" />

      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <div className="form__row">
          <div className="form__label">{t('newPassword')}:</div>
          <InputText
            {...register('password', {
              required: 'Password is required',
              minLength: {
                value: 6,
                message: 'Password must be at least 6 characters long',
              },
            })}
            type="password"
            className={errors.password || apiError ? 'form__invalid' : ''}
          />
          {errors.password && <span>{errors.password.message as string}</span>}
        </div>
        <div className="form__row">
          <div className="form__label">{t('rePassword')}:</div>
          <InputText
            {...register('rePassword', {
              validate: (value) => value === watch('password') || 'Passwords do not match',
            })}
            type="password"
            className={errors.rePassword || apiError ? 'form__invalid' : ''}
          />
          {errors.rePassword && <span>{errors.rePassword.message as string}</span>}
          {apiError && <span>{apiError}</span>}
        </div>

        <Button className="btn btn--sm" label={t('save')} />
      </form>
      <div className="change-password-page__help">
        <div className="help-box">
          <label className="help-box__title">{t('changePassword')}</label>
          <div>{t('changePasswordDesc')}
            In this section, you can update your account password for better security. Please ensure that
            your new password is at least 6 characters long and difficult to guess. After successfully
            changing your password, you will be automatically logged out for security reasons, and you
            will need to log in again with your new password.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordPage;
