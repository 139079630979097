import React from 'react';
import './SimplePage.scss';
import Panel from '../panel/Panel';
import { NavLink } from 'react-router-dom';
import { FAQ, LOGIN, PROJECTS } from '../../../routes/path';
import { Button } from 'primereact/button';
import LoginBox from '../../login-box/LoginBox';

interface SimplePageProps {
  children: React.ReactNode;
}

const SimplePage: React.FC<SimplePageProps> = ({ children }) => {
  return (
    <div className="simple-page">
      <div className="simple-page__page">

        <div className="simple-page__right">
          <main>
            <>
              <div className="simple-page__belt">
                <div className="logo">
                  <NavLink to={LOGIN}>
                    <div className="logo-box" />
                  </NavLink>
                </div>
              </div>
              {children}
            </>
          </main>
        </div>
      </div>
    </div>
  );
};

export default SimplePage;
