import './PromptsTable.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React, { useEffect, useState } from 'react';
import {
  ProjectPromptsTableData,
  PromptsData,
  PromptsTableData,
} from '../../../pages/prompt/models/PromptsData';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { ADD, PROJECTS, QUESTIONS } from '../../routes/path';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Tooltip } from 'primereact/tooltip';
import { useAccount } from '../../contexts/account/AccountContexts';
import { TablePlaceType } from './types/TablePlaceType';
import {
  refreshStats,
  removePrompt,
  setPromptProject,
} from '../../services/monitor/prompt/PromptService';
import { PeriodType } from '../../types/prompt/PeriodType';
import { ChatType } from '../../types/monitor/ChatType';
import { getChatUrl, getPeriodUrl } from '../../helpers/StatsHelpers';
import ChangeProject from '../common/change-project/ChangeProject';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import useCanManage from '../../hooks/account/UseCanManage';
import LimitTooltipComponent from '../common/limit-tooltip/LimitTooltipComponent';

interface PromptsTableProp {
  tableTitle: string;
  data: PromptsData[];
  place: TablePlaceType;
  periodType: PeriodType;
  chatType: ChatType;
  projectId?: number | undefined;
  refreshAfterDelete: () => void;
}

const PromptsTable: React.FC<PromptsTableProp> = ({
  tableTitle,
  data,
  place,
  periodType,
  chatType,
  projectId,
  refreshAfterDelete,
}) => {
  const { t } = useTranslation();
  const { accountResponse } = useAccount();
  const navigate = useNavigate();
  const canManage = useCanManage();

  const [tableData, setTableData] = useState<PromptsTableData[] | ProjectPromptsTableData[]>([]);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  let selectedProjectId: number | null = null;

  useEffect(() => {
    prepareTable(data);
  }, [data]);

  const prepareTable = (monitorData: PromptsData[]) => {
    let tableData;
    if (place === 'QUESTIONS') {
      tableData = [] as PromptsTableData[];
    } else {
      tableData = [] as ProjectPromptsTableData[];
    }

    monitorData.forEach((data) => {
      const date = new Date(data.created?.toString() || '');
      const formattedDate = date.toLocaleDateString('pl-PL');
      const formattedTime = date.toLocaleTimeString('pl-PL');

      if (place === 'QUESTIONS') {
        const created = `${formattedDate} ${formattedTime}`;

        tableData.push({
          status: data.status,
          id: data.id,
          prompt: data.prompt,
          keyword: data.keyword,
          created,
        });
      } else {
        tableData.push({
          status: data.status,
          id: data.id,
          prompt: data.prompt,
          keyword: data.keyword,
          averagePosition: data.averagePosition,
          shareOfVoice: data.shareOfVoice,
        });
      }
    });

    setTableData(tableData);
  };

  const confirmRemove = (id: number | undefined) => {
    if (!canManage || tableData.length <= 1) {
      return;
    }

    confirmDialog({
      message: t('areYouSureProceed'),
      header: t('areYouSure'),
      defaultFocus: 'accept',
      accept: () => accept(id),
    });
  };

  const changProjectDialog = (id: number | undefined) => {
    if (place === 'PROJECT' && tableData.length <= 1) {
      return;
    }

    const monitorData = place === 'PROJECT' ? null : getCurrentMonitorData(id);
    const project = place === 'PROJECT' ? Number(projectId) : monitorData ? monitorData.projectId : null;
    confirmDialog({
      message: (
        <ChangeProject
          selectedProject={project}
          onChangeProject={(selectedProject) => {
            selectedProjectId = selectedProject;
          }}
        />
      ),
      header: t('changeProject'),
      acceptLabel: t('save'),
      rejectLabel: t('cancel'),
      accept: () => changeProject(id),
      reject: () => (selectedProjectId = null),
    });
  };

  const changeProject = (id: number | undefined) => {
    const monitorData = getCurrentMonitorData(id);
    if (!id || monitorData?.projectId === selectedProjectId) {
      return;
    }

    setPromptProject(id, { project: selectedProjectId })
      .then((isUpdated) => {
        if (isUpdated) {
          selectedProjectId = null;
        }
      })
      .then(refreshAfterDelete)
      .catch((err) => err);
  };

  const accept = (id: number | undefined) => {
    removePrompt(id)
      .then(refreshAfterDelete)
      .catch((err) => err);
  };

  const statusBodyTemplate = (rowData: PromptsTableData) => {
    return (
      <div className={`status status--${rowData.status.toLowerCase()}`}>
        <div className="status__ring" />
        <div className="status__dot" />
      </div>
    );
  };

  const keywordBodyTemplate = (rowData: PromptsTableData) => {
    const monitorData = getCurrentMonitorData(rowData.id);
    const tag = (
      <span className={`tag ${place === 'PROJECT' || !monitorData?.projectId ? 'tag--second' : ''}`}>
        {rowData.keyword}
      </span>
    );

    if (place !== 'PROJECT' && monitorData && monitorData.projectId) {
      return <NavLink to={`${PROJECTS}/${monitorData.projectId}`}>{tag}</NavLink>;
    }

    return tag;
  };

  const promptBodyTemplate = (rowData: PromptsTableData) => {
    const prompt = rowData.prompt;
    const link =
      place === 'PROJECT'
        ? `${PROJECTS}/${projectId}${QUESTIONS}/${rowData.id}/${getPeriodUrl(periodType)}/${getChatUrl(
            'GPT',
            chatType
          )}`
        : `${QUESTIONS}/${rowData.id}/${getPeriodUrl(periodType)}/${getChatUrl('ALL', chatType)}`;
    const monitorData = getCurrentMonitorData(rowData.id);
    const countIndicators = data.filter((promptsData) => promptsData.isProductModelQuestion).length;
    const canShowIndicator =
      monitorData &&
      monitorData.isProductModelQuestion &&
      place === 'PROJECT' &&
      data.length > countIndicators;
    const indicator = canShowIndicator && (
      <>
        <span className="material-symbols-outlined" id={`warming-icon-${rowData.id}`}>
          warning
        </span>
        <Tooltip
          target={`#warming-icon-${rowData.id}`}
          content={t('mixedWarning')}
          className="tooltip--top"
          position="top"
          style={{ width: '220px' }}
        />
      </>
    );

    return rowData.status === 'PENDING' ? (
      <div className="cell-warning">
        {prompt}
        {indicator}
      </div>
    ) : (
      <div className="cell-warning">
        <NavLink to={link}>{prompt}</NavLink>
        {indicator}
      </div>
    );
  };

  const getCurrentMonitorData = (id: number | undefined): PromptsData | undefined => {
    return data.find((promptsData) => promptsData.id === id);
  };

  const actionBodyTemplate = (rowData: PromptsTableData) => {
    let count = 2;
    if (rowData.status === 'ACTIVE') {
      count += 1;
    }

    return (
      <div className="more-btns">
        <Button className="material-symbols-outlined">more_vert</Button>
        <ul className={`action-panel  action-panel--${count}`}>
          {rowData.status === 'ACTIVE' && (
            <>
              <Button
                onClick={() => navigate(`${QUESTIONS}/${rowData?.id}`)}
                className="material-symbols-outlined"
              >
                bar_chart
              </Button>
            </>
          )}
          <>
            <Button
              onClick={() => changProjectDialog(rowData?.id)}
              className={`material-symbols-outlined ${
                place === 'PROJECT' && tableData.length > 1 ? '' : 'btn--disabled-tooltip'
              }`}
            >
              move_up
            </Button>
          </>
          <Button
            onClick={() => confirmRemove(rowData?.id)}
            className={`material-symbols-outlined ${
              canManage && tableData.length > 1 ? '' : 'btn--disabled-tooltip'
            }`}
            id={`remove-question-${rowData.id}`}
          >
            delete
          </Button>
          {!canManage && <LimitTooltipComponent id={`remove-question-${rowData.id}`} direction="top" />}
        </ul>
      </div>
    );
  };

  const runRefreshStats = () => {
    if (!projectId || !accountResponse?.isSuperAdmin) {
      return;
    }

    refreshStats(projectId)
      .then(refreshAfterDelete)
      .catch((err) => err);
  };

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderButton = () => {
    if (place !== 'PROJECT' && (!accountResponse || !accountResponse.isSuperAdmin)) {
      return (
        <div className="page-box__buttons">
          <div className="input-search-box">
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search..."
              className="input-search"
            />
          </div>
        </div>
      );
    }

    const address =
      place === 'PROJECT' ? `${PROJECTS}/${projectId}${QUESTIONS}${ADD}` : `${QUESTIONS}${ADD}`;
    return (
      <div className="page-box__buttons">
        <div className="input-search-box">
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search..."
            className="input-search"
          />
        </div>

        {accountResponse?.isSuperAdmin && place === 'PROJECT' && (
          <Button
            className={`btn btn--sm btn--dev`}
            onClick={() => runRefreshStats()}
            label={t('refreshAllStats')}
          />
        )}

        <Button
          className={`btn btn--sm ${!canManage ? 'btn--disabled-tooltip-color' : ''}`}
          onClick={() => {
            if (canManage) {
              navigate(address);
            }
          }}
          label={t('addNewQuestion')}
          id="add-prompt"
        />
        {!canManage && <LimitTooltipComponent id="add-prompt" direction="bottom" />}
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="no-results">
        <div className="no-results__icon material-symbols-outlined">search</div>
        <div className="no-results__title">{t('noResultsFound')}</div>
        <div className="no-results__desc">{t('noResultsFoundDesc')}</div>
      </div>
    );
  };

  if (!tableData) {
    return <></>;
  }

  return (
    <>
      <ConfirmDialog className="dialog-box" />

      <div className="page-box__title-action">
        <div className="page-box__title">{tableTitle}</div>
        {renderButton()}
      </div>
      <DataTable
        stripedRows
        paginator
        paginatorClassName="pagination"
        removableSort
        rows={10}
        rowsPerPageOptions={[10, 25, 50]}
        value={tableData}
        tableStyle={{ minWidth: '50rem' }}
        tableClassName="table"
        className="table-wrapper"
        filters={filters}
        emptyMessage={emptyTemplate}
      >
        <Column
          body={statusBodyTemplate}
          header="Status"
          headerClassName="table__header"
          style={{ width: '37px' }}
        />
        <Column
          sortable
          field="prompt"
          header={t('prompt')}
          body={promptBodyTemplate}
          headerClassName="table__header"
        />
        <Column
          sortable
          body={keywordBodyTemplate}
          field="keyword"
          header={t('keyword')}
          headerClassName="table__header"
          style={{ width: '220px' }}
        />
        <Column
          sortable
          field={place === 'PROJECT' ? 'averagePosition' : 'created'}
          header={place === 'PROJECT' ? t('averagePosition') : t('created')}
          headerClassName="table__header"
          style={{ width: '150px' }}
        />
        {place === 'PROJECT' && (
          <Column
            sortable
            field={'shareOfVoice'}
            header={t('averageShareOfVoice')}
            headerClassName="table__header"
            style={{ width: '200px' }}
          />
        )}
        <Column
          header={t('action')}
          body={actionBodyTemplate}
          headerClassName="table__header"
          align={'center'}
          style={{ width: '37px' }}
        />
      </DataTable>
    </>
  );
};

export default PromptsTable;
