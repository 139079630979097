import React, { useEffect, useState } from 'react';
import './ProjectWizardPage.scss';
import { useAccount } from '../../../shared/contexts/account/AccountContexts';
import { getAccount } from '../../../shared/services/account/AccountService';
import FirstStep from './components/first-step/FirstStep';
import SecondStep from './components/second-step/SecondStep';
import { useProjectWizard } from './contexts/ProjectWizardContext';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { PROJECTS } from '../../../shared/routes/path';
import Loader from '../../../shared/components/common/loader/Loader';

const ProjectWizardPage: React.FC = () => {
  const { t } = useTranslation();
  const { setAccountResponse, projectResponse } = useAccount();
  const navigation = useNavigate();
  const { activeStep } = useProjectWizard();
  const [loader, setLoader] = useState<boolean>(true);

  useEffect(() => {
    setLoader(true);

    getAccount().then(async (updatedAccountResponse) => {
      //jeśli przyjdzie błąd powinniśmy wyświetlić komunikat i zapisać to do bazy
      setAccountResponse(updatedAccountResponse);

      const canManage =
        updatedAccountResponse &&
        (updatedAccountResponse.accountType === 'PAID' ||
          (updatedAccountResponse.accountType === 'TRIAL' &&
            updatedAccountResponse.createdMonitorCount < updatedAccountResponse.maxMonitorCount));
      setLoader(false);
      if (!canManage) {
        navigation('/');
      }
    });
  }, []);

  if (loader) {
    return <Loader />;
  }

  return (
    <div className="project-wizard">
      <div className={`${activeStep === 2 ? 'project-wizard__page--second' : ''} project-wizard__page`}>
        <main>
          <>
            <div className="project-wizard__belt">
              <div className="logo">
                <div className="logo-box" />
              </div>
              {projectResponse && projectResponse.length > 0 && (
                <NavLink to={PROJECTS} className="btn-link">
                  <span className="material-symbols-outlined">arrow_back_ios</span>
                  {t('backToProjects')}
                </NavLink>
              )}
            </div>
          </>
        </main>
        <div className="project-wizard__content">
          {activeStep === 1 && <FirstStep />}
          {activeStep === 2 && <SecondStep />}
          <div />
        </div>
      </div>
    </div>
  );
};

export default ProjectWizardPage;
