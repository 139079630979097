import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import './ForgotPage.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { forgot } from './services/ForgotService';
import { LOGIN } from '../../shared/routes/path';

const ForgotPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [apiError, setApiError] = useState<string | null>(null);

  const onSubmit = async (formData: any) => {
    const data = {
      email: formData.email,
    };
    await forgot(data)
      .then(() => navigate(LOGIN))
      .catch((err) => setApiError(err.response.data.error));
  };

  const clearApiError = () => {
    setApiError(null);
  };

  return (
    <div className="forgot-page">
      <div className="logo-box" />
      <form onSubmit={handleSubmit(onSubmit)} className="form-box">
        <div className="form-box__title">{t('passwordReset')}</div>
        <div className="form-box__form">
          <div className="form-box__row form-box__row--info">{t('passwordResetDesc')} </div>
          <div className="form-box__row">
            <div className="form-box__label">{t('emailAddress')}:</div>
            <InputText
              onInput={clearApiError}
              {...register('email', {
                required: t('fieldIsRequired'),
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: t('wrongEmailAddress'),
                },
              })}
              className={errors.email || apiError ? 'form-box__invalid' : ''}
            />
            {errors.email && <span>{errors.email.message as string}</span>}
          </div>
          <div className="form-box__actions">
            <Button className="btn btn--sm" label={t('continue')} />
            <Button className="btn btn--sm btn--secondary" label={t('back')} onClick={() => navigate(LOGIN)} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForgotPage;
