import React from 'react';
import './IntegrationsPage.scss';
import Title from '../../shared/components/title/Title';
import { InputSwitch } from 'primereact/inputswitch';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'primereact/tooltip';
import { useAccount } from '../../shared/contexts/account/AccountContexts';
import Loader from '../../shared/components/common/loader/Loader';
import { MonitorType } from '../../shared/types/monitor/MonitorType';

const IntegrationsPage: React.FC = () => {
  const { t } = useTranslation();
  const { accountResponse } = useAccount();

  if (!accountResponse) {
    return <Loader />;
  }

  const hasFeature = (feature: MonitorType) => {
    return accountResponse.features.includes(feature);
  };

  return (
    <>
      <Title mainCategory={t('integrations')} />
      <div className="integration-page page-content">
        <div className="page-box">
          <div className="page-box__title-action">
            <div className="page-box__title">{t('availableIntegrations')}</div>
          </div>
          <div className="page-box__content">
            <div className="integration-page__content">
              <div className="integration-page__integration-box">
                <div
                  className={`integration ${
                    hasFeature('GPT_API_BROWSER') || hasFeature('GPT_API') ? 'integration--active' : ''
                  }`}
                >
                  <div className="integration__box">
                    <div className="integration__image integration__image--gpt" />
                    <div className="integration__content">
                      <div className="integration__title">ChatGPT (4o & 4o mini)</div>
                      <div className="integration__desc">{t('chatGPTDesc')}</div>
                    </div>
                  </div>
                  <div className="integration__action">
                    <InputSwitch
                      checked={hasFeature('GPT_API_BROWSER') || hasFeature('GPT_API')}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className={`integration ${hasFeature('GEMINI_API') ? 'integration--active' : ''}`}>
                  <div className="integration__box">
                    <div className="integration__image integration__image--gemini" />
                    <div className="integration__content">
                      <div className="integration__title">Gemini (Flash)</div>
                      <div className="integration__desc">{t('geminiDesc')}</div>
                    </div>
                  </div>
                  <InputSwitch checked={hasFeature('GEMINI_API')} disabled={true} />
                </div>
                <div
                  className={`integration ${
                    hasFeature('GEMINI_API_BROWSER') ? 'integration--active' : ''
                  }`}
                >
                  <div className="integration__box">
                    <div className="integration__image integration__image--gemini" />
                    <div className="integration__content">
                      <div className="integration__title">Gemini (Flash Pro)</div>
                      <div className="integration__desc">{t('geminiDesc')}</div>
                    </div>
                  </div>
                  <InputSwitch
                    id="gemini-tooltip"
                    checked={hasFeature('GEMINI_API_BROWSER')}
                    disabled={true}
                  />
                  {!hasFeature('GEMINI_API_BROWSER') && (
                    <Tooltip
                      target="#gemini-tooltip"
                      className="tooltip--top"
                      position="top"
                      style={{ width: '220px' }}
                      autoHide={false}
                      hideDelay={100}
                    >
                      <div dangerouslySetInnerHTML={{ __html: t('unlockFeature') }} />
                    </Tooltip>
                  )}
                </div>
                <div className={`integration ${hasFeature('CLAUDE_API') ? 'integration--active' : ''}`}>
                  <div className="integration__box">
                    <div className="integration__image integration__image--claude" />
                    <div className="integration__content">
                      <div className="integration__title">Claude (Sonnet)</div>
                      <div className="integration__desc">{t('claudeDesc')}</div>
                    </div>
                  </div>
                  <InputSwitch id="claude-tooltip" checked={hasFeature('CLAUDE_API')} disabled={true} />
                  {!hasFeature('CLAUDE_API') && (
                    <Tooltip
                      target="#claude-tooltip"
                      className="tooltip--top"
                      position="top"
                      style={{ width: '220px' }}
                      autoHide={false}
                      hideDelay={100}
                    >
                      <div dangerouslySetInnerHTML={{ __html: t('unlockFeature') }} />
                    </Tooltip>
                  )}
                </div>
                <div
                  className={`integration ${hasFeature('DEEP_SEEK_API') ? 'integration--active' : ''}`}
                >
                  <div className="integration__box">
                    <div className="integration__image integration__image--deep-seek" />
                    <div className="integration__content">
                      <div className="integration__title">DeepSeek (R1)</div>
                      <div className="integration__desc">{t('deepSeekDesc')}</div>
                    </div>
                  </div>
                  <InputSwitch
                    id="deepseek-tooltip"
                    checked={hasFeature('DEEP_SEEK_API')}
                    disabled={true}
                  />
                  {!hasFeature('DEEP_SEEK_API') && (
                    <Tooltip
                      target="#deepseek-tooltip"
                      className="tooltip--top"
                      position="top"
                      style={{ width: '220px' }}
                      autoHide={false}
                      hideDelay={100}
                    >
                      <div dangerouslySetInnerHTML={{ __html: t('unlockFeature') }} />
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className="subscription-page__help">
                <div className="help-box">
                  <label className="help-box__title">{t('whatAreIntegrationsInApp')}</label>
                  <div>{t('integrationDesc')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IntegrationsPage;
