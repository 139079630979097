import React from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import Layout from '../components/common/layout/Layout';
import LoginPage from '../../pages/login/LoginPage';
import SignupPage from '../../pages/signup/SignupPage';
import ForgotPage from '../../pages/forgot/ForgotPage';
import PromptsPage from '../../pages/prompt/PromptsPage';
import IntegrationsPage from '../../pages/integrations/IntegrationsPage';
import PricingPage from '../../pages/pricing/PricingPage';
import SettingsPage from '../../pages/settings/SettingsPage';
import PrivateRoutes from './PrivateRoutes';
import AddPromptPage from '../../pages/prompt/add/AddPromptPage';
import PreviewPromptPage from '../../pages/prompt/preview/PreviewPromptPage';
import TeamPage from '../../pages/settings/team/TeamPage';
import ChangePasswordPage from '../../pages/settings/change-password/ChangePasswordPage';
import SubscriptionPage from '../../pages/settings/subscription/SubscriptionPage';
import MyAccountPage from '../../pages/settings/my-account/MyAccountPage';
import { StatsContextProvider } from '../contexts/StatsContexts';
import OrdersPage from '../../pages/settings/orders/OrdersPage';
import TeamEditPage from '../../pages/settings/team/edit/TeamEditPage';
import TeamAddPage from '../../pages/settings/team/add/TeamAddPage';
import BillingDataPage from '../../pages/settings/billing-data/BillingDataPage';
import ResetPasswordPage from '../../pages/reset-password/ResetPasswordPage';
import {
  ADD,
  ADMIN,
  BILLING_DATA,
  CHANGE_PASSWORD,
  DASHBOARD,
  EDIT,
  FAQ,
  FORGOT,
  INTEGRATIONS,
  LOGIN,
  MY_ACCOUNT,
  ORDERS,
  PROJECTS,
  PROJECTS_ADD,
  QUEST_FAQ,
  QUESTIONS,
  QUESTIONS_ID,
  SET_PASSWORD,
  SETTINGS,
  SIGNUP,
  SUBSCRIPTION,
  TEAM,
  TEAM_ADD,
  TEAM_PREVIEW,
  USERS,
} from './path';
import ProjectsPage from '../../pages/projects/ProjectsPage';
import ProjectWizardPage from '../../pages/projects/wizard/ProjectWizardPage';
import { ProjectWizardProvider } from '../../pages/projects/wizard/contexts/ProjectWizardContext';
import Wrapper from '../components/common/wrapper/Wrapper';
import ProjectsPreviewPage from '../../pages/projects/preview/ProjectsPreviewPage';
import AdminPage from '../../pages/admin/AdminPage';
import DashboardPage from '../../pages/admin/dashboard/DashboardPage';
import FaqPage from '../../pages/faq/FaqPage';
import SimplePage from '../components/common/simple-page/SimplePage';
import AdminSettingsPage from '../../pages/admin/settings/AdminSettingsPage';
import AdminUsersPage from '../../pages/admin/users/AdminUsersPage';
import AdminUsersEditPage from '../../pages/admin/users/edit/AdminUsersEditPage';

const AppRoutes: React.FC = () => {
  interface RedirectProps {
    restUrl: string;
  }

  const RedirectToMonitoringStats: React.FC<RedirectProps> = ({ restUrl }) => {
    const { id } = useParams();
    return <Navigate to={`${QUESTIONS}/${id}/${restUrl}/all`} replace />;
  };

  const RedirectToProjectStats: React.FC<RedirectProps> = ({ restUrl }) => {
    const { projectId } = useParams();
    return <Navigate to={`${PROJECTS}/${projectId}/${restUrl}/gpt`} replace />;
  };

  const RedirectToProjectMonitorStats: React.FC<RedirectProps> = ({ restUrl }) => {
    const { projectId, id } = useParams();
    return <Navigate to={`${PROJECTS}/${projectId}/${QUESTIONS}/${id}/${restUrl}/gpt`} replace />;
  };

  const showPricing = Boolean(process.env.REACT_SHOW_PRICING) || false;

  return (
    <Routes>
      <Route path={LOGIN} element={<LoginPage />} />
      <Route path={SIGNUP} element={<SignupPage />} />
      <Route path={FORGOT} element={<ForgotPage />} />
      <Route path={SET_PASSWORD} element={<ResetPasswordPage />} />

      <Route
        path={PROJECTS}
        element={
          <PrivateRoutes>
            <Wrapper>
              <Layout>
                <ProjectsPage />
              </Layout>
            </Wrapper>
          </PrivateRoutes>
        }
      />
      <Route
        path={`${PROJECTS}/:projectId${QUESTIONS}${ADD}`}
        element={
          <PrivateRoutes>
            <Wrapper>
              <Layout>
                <AddPromptPage />
              </Layout>
            </Wrapper>
          </PrivateRoutes>
        }
      />
      <Route
        path={`${PROJECTS}/:projectId/:period/:chat`}
        element={
          <PrivateRoutes>
            <Wrapper>
              <Layout>
                <StatsContextProvider>
                  <ProjectsPreviewPage />
                </StatsContextProvider>
              </Layout>
            </Wrapper>
          </PrivateRoutes>
        }
      />
      <Route
        path={`${PROJECTS}/:projectId${QUESTIONS_ID}/:period/:chat`}
        element={
          <PrivateRoutes>
            <Wrapper>
              <Layout>
                <StatsContextProvider>
                  <PreviewPromptPage force={true} />
                </StatsContextProvider>
              </Layout>
            </Wrapper>
          </PrivateRoutes>
        }
      />
      <Route
        path={PROJECTS_ADD}
        element={
          <PrivateRoutes>
            <Wrapper>
              <ProjectWizardProvider>
                <ProjectWizardPage />
              </ProjectWizardProvider>
            </Wrapper>
          </PrivateRoutes>
        }
      />
      <Route
        path={QUESTIONS}
        element={
          <PrivateRoutes>
            <Wrapper>
              <Layout>
                <StatsContextProvider>
                  <PromptsPage />
                </StatsContextProvider>
              </Layout>
            </Wrapper>
          </PrivateRoutes>
        }
      />
      <Route
        path={`${QUESTIONS}${ADD}`}
        element={
          <PrivateRoutes>
            <Wrapper>
              <Layout>
                <AddPromptPage />
              </Layout>
            </Wrapper>
          </PrivateRoutes>
        }
      />
      <Route
        path={`${QUESTIONS_ID}/:period/:chat`}
        element={
          <PrivateRoutes>
            <Wrapper>
              <Layout>
                <StatsContextProvider>
                  <PreviewPromptPage force={false} />
                </StatsContextProvider>
              </Layout>
            </Wrapper>
          </PrivateRoutes>
        }
      />
      <Route
        path={INTEGRATIONS}
        element={
          <PrivateRoutes>
            <Wrapper>
              <Layout>
                <IntegrationsPage />
              </Layout>
            </Wrapper>
          </PrivateRoutes>
        }
      />
      <Route
        path={FAQ}
        element={
          <PrivateRoutes>
            <Wrapper>
              <Layout>
                <FaqPage />
              </Layout>
            </Wrapper>
          </PrivateRoutes>
        }
      />
      <Route
        path={QUEST_FAQ}
        element={
          <SimplePage>
            <FaqPage />
          </SimplePage>
        }
      />
      <Route
        path={`${ADMIN}${DASHBOARD}`}
        element={
          <PrivateRoutes>
            <Wrapper checkSuperAdmin={true}>
              <Layout>
                <AdminPage children={<DashboardPage />} restUrl="dashboard" />
              </Layout>
            </Wrapper>
          </PrivateRoutes>
        }
      />
      <Route
        path={`${ADMIN}${USERS}`}
        element={
          <PrivateRoutes>
            <Wrapper checkSuperAdmin={true}>
              <Layout>
                <AdminPage children={<AdminUsersPage />} restUrl="users" />
              </Layout>
            </Wrapper>
          </PrivateRoutes>
        }
      />
      <Route
        path={`${ADMIN}${USERS}/:id`}
        element={
          <PrivateRoutes>
            <Wrapper checkSuperAdmin={true}>
              <Layout>
                <AdminPage children={<AdminUsersEditPage />} restUrl="users" />
              </Layout>
            </Wrapper>
          </PrivateRoutes>
        }
      />
      <Route
        path={`${ADMIN}${SETTINGS}`}
        element={
          <PrivateRoutes>
            <Wrapper checkSuperAdmin={true}>
              <Layout>
                <AdminPage children={<AdminSettingsPage />} restUrl="settings" />
              </Layout>
            </Wrapper>
          </PrivateRoutes>
        }
      />
      {showPricing && (
        <Route
          path="/pricing"
          element={
            <PrivateRoutes>
              <Wrapper>
                <Layout>
                  <PricingPage />
                </Layout>
              </Wrapper>
            </PrivateRoutes>
          }
        />
      )}
      <Route
        path={MY_ACCOUNT}
        element={
          <PrivateRoutes>
            <Wrapper>
              <Layout>
                <SettingsPage children={<MyAccountPage />} restUrl="my-account" />
              </Layout>
            </Wrapper>
          </PrivateRoutes>
        }
      />
      <Route
        path={CHANGE_PASSWORD}
        element={
          <PrivateRoutes>
            <Wrapper>
              <Layout>
                <SettingsPage children={<ChangePasswordPage />} restUrl="change-password" />
              </Layout>
            </Wrapper>
          </PrivateRoutes>
        }
      />
      {showPricing && (
        <>
          <Route
            path={BILLING_DATA}
            element={
              <PrivateRoutes>
                <Wrapper>
                  <Layout>
                    <SettingsPage children={<BillingDataPage />} restUrl="billing-data" />
                  </Layout>
                </Wrapper>
              </PrivateRoutes>
            }
          />
          <Route
            path={SUBSCRIPTION}
            element={
              <PrivateRoutes>
                <Wrapper>
                  <Layout>
                    <SettingsPage children={<SubscriptionPage />} restUrl="subscription" />
                  </Layout>
                </Wrapper>
              </PrivateRoutes>
            }
          />
          <Route
            path={ORDERS}
            element={
              <PrivateRoutes>
                <Wrapper>
                  <Layout>
                    <SettingsPage children={<OrdersPage />} restUrl="orders" />
                  </Layout>
                </Wrapper>
              </PrivateRoutes>
            }
          />
        </>
      )}
      <Route
        path={TEAM}
        element={
          <PrivateRoutes>
            <Wrapper>
              <Layout>
                <SettingsPage children={<TeamPage />} restUrl="team" />
              </Layout>
            </Wrapper>
          </PrivateRoutes>
        }
      />
      <Route
        path={TEAM_ADD}
        element={
          <PrivateRoutes>
            <Wrapper>
              <Layout>
                <SettingsPage children={<TeamAddPage />} restUrl="team" />
              </Layout>
            </Wrapper>
          </PrivateRoutes>
        }
      />
      <Route
        path={TEAM_PREVIEW}
        element={
          <PrivateRoutes>
            <Wrapper>
              <Layout>
                <SettingsPage children={<TeamEditPage />} restUrl="team" />
              </Layout>
            </Wrapper>
          </PrivateRoutes>
        }
      />
      <Route path={`${PROJECTS}/:projectId`} element={<RedirectToProjectStats restUrl="daily" />} />
      <Route
        path={`${PROJECTS}/:projectId/daily`}
        element={<RedirectToProjectStats restUrl="daily" />}
      />
      <Route
        path={`${PROJECTS}/:projectId/weekly`}
        element={<RedirectToProjectStats restUrl="weekly" />}
      />
      <Route
        path={`${PROJECTS}/:projectId/monthly`}
        element={<RedirectToProjectStats restUrl="monthly" />}
      />

      <Route path={QUESTIONS_ID} element={<RedirectToMonitoringStats restUrl="daily" />} />
      <Route path={`${QUESTIONS_ID}/daily`} element={<RedirectToMonitoringStats restUrl="daily" />} />
      <Route path={`${QUESTIONS_ID}/weekly`} element={<RedirectToMonitoringStats restUrl="weekly" />} />
      <Route
        path={`${QUESTIONS_ID}/monthly`}
        element={<RedirectToMonitoringStats restUrl="monthly" />}
      />
      <Route
        path={`${PROJECTS}/:projectId${QUESTIONS_ID}`}
        element={<RedirectToProjectMonitorStats restUrl="daily" />}
      />
      <Route
        path={`${PROJECTS}/:projectId${QUESTIONS_ID}/daily`}
        element={<RedirectToProjectMonitorStats restUrl="daily" />}
      />
      <Route
        path={`${PROJECTS}/:projectId${QUESTIONS_ID}/weekly`}
        element={<RedirectToProjectMonitorStats restUrl="weekly" />}
      />
      <Route
        path={`${PROJECTS}/:projectId${QUESTIONS_ID}/monthly`}
        element={<RedirectToProjectMonitorStats restUrl="monthly" />}
      />
      <Route path={SETTINGS} element={<Navigate to="/settings/my-account" />} />
      <Route path={ADMIN} element={<Navigate to="/admin/dashboard" />} />
      <Route path="/*" element={<Navigate to={PROJECTS} />} />
    </Routes>
  );
};

export default AppRoutes;
