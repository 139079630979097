import { useAccount } from '../../contexts/account/AccountContexts';

const useCanManage = () => {
  const { accountResponse } = useAccount();

  return (
    accountResponse &&
    (accountResponse.accountType === 'PAID' ||
      (accountResponse.accountType === 'TRIAL' &&
        accountResponse.createdMonitorCount < accountResponse.maxMonitorCount))
  );
};

export default useCanManage;
