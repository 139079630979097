import React, { useCallback, useEffect, useState } from 'react';
import './Stats.scss';

import Chart from '../chart/Chart';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { MonitorDataResponse } from '../../models/monitor/MonitorDataResponse';
import { ChartMatrixData, GeneralData, StatsProps, StatsTableData } from './models/StatsModel';
import { Dropdown } from 'primereact/dropdown';
import { useForm } from 'react-hook-form';
import { Tooltip } from 'primereact/tooltip';
import { useStats } from '../../contexts/StatsContexts';
import { useTranslation } from 'react-i18next';
import {
  createBrandsMatrix,
  createKeywordMatrix,
  getApiText,
  prepareGeneralData,
  prepareStatsTableData,
} from './services/StatsService';
import { format } from 'date-fns';
import EmptyState from '../common/empty-state/EmptyState';
import { EmptyStateData } from '../common/empty-state/models/EmptyStateData';
import { addKeyword, regenerateKeywords } from '../../services/monitor/keywords/KeywordsService';
import { FirmData } from '../../models/monitor/FirmData';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import ReactMarkdown from 'react-markdown';
import { useAccount } from '../../contexts/account/AccountContexts';
import Loader from '../common/loader/Loader';

const Stats: React.FC<StatsProps> = ({
  promptData,
  monitorData,
  keywordsData,
  keywordsDailyStatsData,
  onSelect,
}) => {
  const {
    register,
    setValue,
    clearErrors,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();
  const { monitorPeriod, selectedFirm } = useStats();
  const { accountResponse } = useAccount();
  const [, setMainChartLegendMap] = useState<Map<string, boolean> | null>(null);
  const [, setBrandsChartLegendMap] = useState<Map<string, boolean> | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [monitorHeaders, setMonitorHeaders] = useState<string[]>([]);
  const [brandHeadersMap, setBrandHeadersMap] = useState<string[]>([]);
  const [keywordMatrixData, setKeywordMatrixData] = useState<ChartMatrixData | null>(null);
  const [brandsMatrixData, setBrandsMatrixData] = useState<ChartMatrixData | null>(null);
  const [generalData, setGeneralData] = useState<GeneralData | null>(null);

  const [tableData, setTableData] = useState<StatsTableData[]>([]);
  const [expandedRows, setExpandedRows] = useState<any>(null);
  const [isGeneratingStats, setGeneratingStats] = useState<boolean>(false);

  const onKeywordSave = async (form: any) => {
    if (!onSelect) {
      return;
    }

    const keyword = form.keyword;
    const filteredKeywords = keywordsData?.filter(
      (keywordResponse) => keywordResponse.name.trim().toUpperCase() === keyword.trim().toUpperCase()
    );

    if (!filteredKeywords || filteredKeywords.length > 0) {
      setError('keyword', { message: t('thisCompanyAlreadyExist') });
      return;
    }

    addKeyword(promptData.id, keyword)
      .then(() => {
        onSelect({ type: keyword, name: keyword } as FirmData);
        setValue('keyword', '');
      })
      .catch(() => console.error('Error'));
  };

  const noDataShareOfVoiceEmptyState = {
    title: t('noShareOfVoiceData'),
    content: t('noShareOfVoiceDataDesc'),
  } as EmptyStateData;

  const noDataBrandPositioningEmptyState = {
    title: t('noPositioningData'),
    content: t('noPositioningDataDesc'),
  } as EmptyStateData;

  useEffect(() => {
    if (!monitorData) {
      return;
    }

    prepareData(monitorData);
  }, [monitorData]);

  const prepareData = (monitorData: MonitorDataResponse): void => {
    if (!monitorPeriod) {
      return;
    }

    setKeywordMatrixData(null);
    setBrandsMatrixData(null);

    const keywordsDailyStatsRecord = keywordsDailyStatsData
      ? keywordsDailyStatsData[promptData.keyword]
      : [];

    setGeneratingStats(
      !keywordsDailyStatsData ||
        keywordsDailyStatsRecord === undefined ||
        keywordsDailyStatsRecord.length === 0
    );
    setTableData(prepareStatsTableData(promptData.prompt, monitorData, keywordsDailyStatsRecord));

    if (keywordsDailyStatsData && Object.keys(keywordsDailyStatsData).length > 0) {
      const keywordMatrix = createKeywordMatrix(monitorPeriod, keywordsDailyStatsRecord, t);
      const brandsMatrix = createBrandsMatrix(monitorPeriod, keywordsDailyStatsData, promptData.keyword);
      const generalDataStructure = prepareGeneralData(keywordsDailyStatsData, promptData.keyword, t);

      setKeywordMatrixData(keywordMatrix);
      setBrandsMatrixData(brandsMatrix);

      //, 'Share of voice'
      setMonitorHeaders([t('averagePosition')]);
      setBrandHeadersMap(prepareAndGetBrandsHeaderMap(brandsMatrix.matrix));
      setGeneralData(generalDataStructure);
    }
  };

  const prepareAndGetBrandsHeaderMap = (brandsMatrix: (string | number)[][]) => {
    const headers = [];
    for (let i = 1; i < brandsMatrix[0].length; i++) {
      headers.push(brandsMatrix[0][i].toString());
    }

    return headers;
  };

  const onLegendMainChartChange = useCallback(
    (legendStateMap: Map<string, boolean>) => setMainChartLegendMap(legendStateMap),
    [monitorData]
  );

  const onLegendBrandsChartChange = useCallback(
    (legendStateMap: Map<string, boolean>) => setBrandsChartLegendMap(legendStateMap),
    [monitorData]
  );

  const getTrends = (trends: number | undefined) => {
    if (trends === undefined) {
      return 'No data';
    } else if (trends === 0) {
      return (
        <div className="trends trends--grey">
          <label className="trends__icon">－</label>
        </div>
      );
    } else if (trends < 0) {
      return (
        <div className="trends trends--red">
          <label className="trends__icon">south</label>
          <label>{Math.abs(trends)}</label>
        </div>
      );
    } else if (trends > 0) {
      return (
        <div className="trends trends--green">
          <label className="trends__icon">north</label>
          <label>{Math.abs(trends)}</label>
        </div>
      );
    }
  };

  const regenerate = () => {
    setLoader(true);

    regenerateKeywords(promptData.id).then(() => {
      setLoader(false);
      window.location.reload();
    });
  };

  if (loader) {
    return <Loader />;
  }

  const rowExpansionTemplate = (statsTableData: StatsTableData) => {
    const records = monitorData[statsTableData.created.toString()];
    if (!records) {
      return <></>;
    }

    return (
      <div className="table-message">
        {records
          .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
          .map((monitorDataRecord, index) => {
            return (
              <div
                className="table-message__box"
                key={`message-content-${monitorPeriod?.toLowerCase()}-${index}`}
              >
                <div className="table-message__header">
                  <div className="table-message__position">
                    <span>{t('position')}:</span>
                    {!monitorDataRecord.position ? '—' : `#${monitorDataRecord.position}`}
                    {monitorDataRecord && monitorDataRecord.type && (
                      <span className="tag">{t(getApiText(monitorDataRecord.type))}</span>
                    )}
                  </div>
                  <div className="table-message__date">
                    <span>{t('date')}:</span>
                    {format(monitorDataRecord.created, 'yyyy-MM-dd HH:mm:ss')}
                  </div>
                </div>
                {monitorDataRecord.type !== 'GPT' ? (
                  <ReactMarkdown className="table-message__content">
                    {monitorDataRecord.content}
                  </ReactMarkdown>
                ) : (
                  <div className="table-message__content">{monitorDataRecord.content}</div>
                )}
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <>
      <div className="tiles tiles--page">
        <div className="tile-box">
          <label className="tile-box__name">
            {t('averagePosition')}
            <span
              id="average-position"
              className="material-symbols-outlined question-mark question-mark--left"
            >
              help
            </span>
            <Tooltip
              target="#average-position"
              content={t('tooltipAveragePosition')}
              className="tooltip--top"
              position="top"
              style={{ width: '220px' }}
            />
          </label>
          <label className="tile-box__value">
            {generalData?.cumulativeData[promptData.keyword]?.averagePosition || 'No data'}
          </label>
        </div>
        <div className="tile-box">
          <label className="tile-box__name">
            {t('averageShareOfVoice')}
            <span
              id="share-of-voice"
              className="material-symbols-outlined question-mark question-mark--left"
            >
              help
            </span>
            <Tooltip
              target="#share-of-voice"
              content={t('tooltipShareOfVoice')}
              className="tooltip--top"
              position="top"
              style={{ width: '220px' }}
            />
          </label>
          <label className="tile-box__value">
            {generalData?.cumulativeData[promptData.keyword]?.averageShareOfVoice
              ? generalData?.cumulativeData[promptData.keyword]?.averageShareOfVoice + '%'
              : 'No data'}
          </label>
        </div>
        <div className="tile-box">
          <label className="tile-box__name">
            {t('trends')}
            <span id="trends" className="material-symbols-outlined question-mark question-mark--left">
              help
            </span>
            <Tooltip
              target="#trends"
              content={t('tooltipTrends')}
              className="tooltip--top"
              position="top"
              style={{ width: '220px' }}
            />
          </label>
          <label className="tile-box__value">
            {getTrends(generalData?.cumulativeData[promptData.keyword]?.trend) ?? undefined}
          </label>
        </div>
      </div>
      <div className="stats">
        <div className="stats-box stats-box--chart page-box">
          <div className="page-box__title-action">
            <div className="page-box__title">{t('brandPositioningOverview')}</div>
          </div>

          <div className="page-box__content">
            {keywordMatrixData && keywordMatrixData.matrix.length > 0 ? (
              <Chart
                lineChartData={keywordMatrixData.matrix}
                // lineChartSecondData={keywordMatrixData.secondMatrix}
                headers={monitorHeaders}
                onLegendClick={onLegendMainChartChange}
                type="line"
                inverseY={true}
              />
            ) : (
              <EmptyState emptyStateData={noDataBrandPositioningEmptyState} />
            )}
          </div>
        </div>
        <div>
          <div className="stats-box stats-box--pie-chart page-box">
            <div className="page-box__title-action">
              <div className="page-box__title">
                {t('averageShareOfVoice')}
                <span
                  id="share-of-voice-pie"
                  className="material-symbols-outlined question-mark question-mark--left"
                >
                  help
                </span>
              </div>
              <Tooltip
                target="#share-of-voice-pie"
                content={t('tooltipShareOfVoice')}
                className="tooltip--top"
                position="top"
                style={{ width: '220px' }}
              />
            </div>
            <div className="page-box__content">
              {generalData && generalData.shareOfVoiceChartData.length > 0 ? (
                <Chart
                  pieChartData={generalData.shareOfVoiceChartData}
                  type="pie"
                  lineChartDataColorsPalette={brandsMatrixData?.colorsPalette}
                />
              ) : (
                <EmptyState emptyStateData={noDataShareOfVoiceEmptyState} />
              )}
            </div>
          </div>
          <div className="stats-box page-box">
            <div className="page-box__title-action">
              <div className="page-box__title">{t('stats')}</div>
            </div>
            <div className="page-box__content">
              <div className="page-box__row page-box__row--select">
                <div className="page-box__subtitle">
                  {t('selectBrandForMonitoring')}
                  <span
                    id="keyword"
                    className="material-symbols-outlined question-mark question-mark--left"
                  >
                    help
                  </span>
                  <Tooltip
                    target="#keyword"
                    content={t('tooltipKeyword')}
                    className="tooltip--top"
                    position="top"
                    style={{ width: '220px' }}
                  />
                </div>
                {onSelect && (
                  <div className="page-box__content-box">
                    <Dropdown
                      value={selectedFirm}
                      onChange={(e) => {
                        setValue('keyword', '');
                        clearErrors('keyword');
                        onSelect(e.value);
                      }}
                      options={keywordsData}
                      className="dropdown"
                      optionLabel="name"
                    />
                  </div>
                )}
                <div className="page-box__subtitle">{t('orWriteCustomName')}:</div>
                <form onSubmit={handleSubmit(onKeywordSave)} className="page-box__content-box">
                  <div className="page-box__input">
                    <InputText
                      {...register('keyword', { required: 'This field is required' })}
                      className={errors.keyword && 'form-box__invalid'}
                    />
                    {errors.keyword && <span>{errors.keyword.message as string}</span>}
                  </div>

                  <Button label={t('addFirm')} className="btn btn--sm" type="submit" />
                </form>
                {accountResponse && accountResponse.isSuperAdmin && (
                  <Button
                    label={t('regenerateKeywords')}
                    className="btn btn--sm btn--dev"
                    onClick={regenerate}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {brandsMatrixData && (
        <>
          <div className="stats-box page-box">
            <div className="page-box__title-action">
              <div className="page-box__title">{t('brandVsTop5')}</div>
            </div>
            <div className="page-box__content">
              {brandsMatrixData.matrix.length > 0 ? (
                <Chart
                  lineChartData={brandsMatrixData.matrix}
                  lineChartDataColorsPalette={brandsMatrixData.colorsPalette}
                  headers={brandHeadersMap}
                  onLegendClick={onLegendBrandsChartChange}
                  type="line"
                  labelY={t('averagePosition')}
                  inverseY={true}
                  selectedBrand={promptData.keyword}
                />
              ) : (
                <EmptyState emptyStateData={noDataBrandPositioningEmptyState} />
              )}
            </div>
          </div>
        </>
      )}
      <>
        <div className="stats-box stats-box--table page-box">
          <div className="page-box__title-action">
            <div className="page-box__title">{t('exploreAIAnswers')}</div>
          </div>
          <div className="page-box__content page-box__content--without-left-right-padding">
            <DataTable
              stripedRows
              paginator
              paginatorClassName="pagination"
              removableSort
              sortField="created"
              sortOrder={-1}
              rows={10}
              rowsPerPageOptions={[10, 25, 50]}
              value={tableData}
              tableStyle={{ minWidth: '50rem' }}
              tableClassName="table"
              className="table-wrapper table-wrapper--accordion"
              expandedRows={expandedRows}
              onRowToggle={(e) => setExpandedRows(e.data)}
              rowExpansionTemplate={rowExpansionTemplate}
              dataKey="created"
            >
              <Column expander={true} style={{ width: '25px' }} />
              <Column
                sortable
                field="created"
                header={t('date')}
                headerClassName="table__header"
                style={{ width: '90px' }}
              />
              <Column
                sortable
                field="prompt"
                header={t('prompt')}
                headerClassName="table__header"
                style={{ minWidth: '300px' }}
              />
              <Column
                field="averagePosition"
                header={t('averagePosition')}
                headerClassName="table__header table__cell--center"
                bodyClassName="table__cell--center"
                style={{ width: '110px' }}
              />
              <Column
                field="averageShareOfVoice"
                header={t('shareOfVoice')}
                headerClassName="table__header table__cell--center"
                bodyClassName="table__cell--center"
                style={{ width: '95px' }}
              />
            </DataTable>
          </div>
        </div>
      </>
      {isGeneratingStats && <div className="toast">{t('gatheringStatsForThisRecord')}</div>}
    </>
  );
};

export default Stats;
