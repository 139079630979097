import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import './ResetPasswordPage.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { AuthData } from '../login/models/LoginFormData';
import { LOGIN } from '../../shared/routes/path';
import { reset } from './services/ResetPasswordService';

const ResetPasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const { hash } = useParams();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [apiError, setApiError] = useState<string | null>(null);

  const onSubmit = async (formData: any) => {
    if (!hash) {
      setApiError(t('wrongHash'));
      return;
    }

    const data = {
      ...formData,
    };

    const urlEncodedData = new URLSearchParams();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        urlEncodedData.append(key, data[key as keyof AuthData]);
      }
    }

    await reset(hash, urlEncodedData)
      .then(() => navigate(LOGIN))
      .catch((err) => setApiError(err.response.data.error));
  };

  const clearApiError = () => {
    setApiError(null);
  };

  return (
    <div className="reset-page">
      <div className="logo-box" />
      <form onSubmit={handleSubmit(onSubmit)} className="form-box">
        <div className="form-box__title">{t('setNewPassword')}:</div>
        <div className="form-box__form">
          <div className="form-box__row">
            <div className="form-box__label">{t('password')}:</div>
            <InputText
              type="password"
              {...register('password', { required: t('fieldIsRequired') })}
              className={errors.password || apiError ? 'form-box__invalid' : ''}
              onInput={clearApiError}
            />
            {errors.password && <span>{errors.password.message as string}</span>}
          </div>

          <div className="form-box__row">
            <div className="form-box__label">{t('rePassword')}:</div>
            <InputText
              type="password"
              {...register('rePassword', { required: t('fieldIsRequired') })}
              className={errors.rePassword || apiError ? 'form-box__invalid' : ''}
              onInput={clearApiError}
            />
            {errors.rePassword && <span>{errors.rePassword.message as string}</span>}
            {apiError && <span>{apiError}</span>}
          </div>
          <div className="form-box__actions">
            <Button className="btn btn--sm" label={t('continue')} />
            <Button
              className="btn btn--sm btn--secondary"
              label={t('back')}
              onClick={() => navigate(LOGIN)}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordPage;
