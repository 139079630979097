import React from 'react';
import './OrdersPage.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useAccount } from '../../../shared/contexts/account/AccountContexts';
import { isAdminOrOwner } from '../../../shared/utils/utils';
import { useNavigate } from 'react-router-dom';

const OrdersPage: React.FC = () => {
  const { accountResponse } = useAccount();
  const navigate = useNavigate();

  if (accountResponse && !isAdminOrOwner(accountResponse.role)) {
    navigate('/settings/my-account');
    return <></>
  }

  return (
    <div className="orders-page">
      <DataTable
        stripedRows
        paginator
        paginatorClassName="pagination"
        removableSort
        sortField="invoice"
        sortOrder={-1}
        rows={10}
        rowsPerPageOptions={[10, 25, 50]}
        tableStyle={{ minWidth: '50rem' }}
        tableClassName="table"
        className="table-wrapper table-wrapper--accordion"
        dataKey="created"
      >
        <Column
          sortable
          field="invoice"
          header="Invoice"
          headerClassName="table__header"
          style={{ width: '90px' }}
        />
        <Column
          field="plan"
          header="Plan"
          headerClassName="table__header table__cell--center"
          bodyClassName="table__cell--center"
          style={{ minWidth: '250px' }}
        />
        <Column
          sortable
          field="date"
          header="Date"
          headerClassName="table__header"
          style={{ width: '90px' }}
        />
        <Column
          field="status"
          header="Status"
          headerClassName="table__header table__cell--center"
          bodyClassName="table__cell--center"
          style={{ width: '150px' }}
        />
        <Column
          field="total"
          header="Total"
          headerClassName="table__header table__cell--center"
          bodyClassName="table__cell--center"
          style={{ width: '150px' }}
        />
      </DataTable>
      <div className="subscription-page__help">
        <div className="help-box">
          <label className="help-box__title">Order history</label>
          <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec id condimentum massa. Nunc ac
            dolor pharetra, eleifend urna eget, lobortis diam. Quisque et urna est. Duis volutpat erat
            vitae felis lacinia sollicitudin. Donec fringilla odio id pellentesque aliquam. Praesent
            rutrum, nisl vel tincidunt posuere, mauris erat egestas nunc, quis dictum leo augue sed ex.
            Fusce pulvinar, turpis in malesuada dapibus, ligula purus iaculis mauris, sed ultricies velit
            diam eu mi. Nullam blandit mi vitae tellus faucibus, nec tristique massa eleifend. Cras
            maximus vulputate vestibulum.
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersPage;
