import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';
import { PeriodType } from '../types/prompt/PeriodType';
import { getDefaultRange } from '../utils/utils';
import { FirmData } from '../models/monitor/FirmData';
import { Nullable } from 'primereact/ts-helpers';
import { ChatType } from '../types/monitor/ChatType';

interface StatsContextType {
  monitorPeriod: PeriodType | null;
  setMonitorPeriod: Dispatch<SetStateAction<PeriodType | null>>;
  selectedFirm: FirmData | null;
  setSelectedFirm: Dispatch<SetStateAction<FirmData | null>>;
  range: Nullable<(Date | null)[]>;
  setRange: Dispatch<SetStateAction<Nullable<(Date | null)[]>>>;
  selectedChatType: ChatType | null;
  setSelectedChatType: Dispatch<SetStateAction<ChatType | null>>;
}

export const StatsContext = createContext<StatsContextType | undefined>(undefined);

export const StatsContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [monitorPeriod, setMonitorPeriod] = useState<PeriodType | null>(null);
  const [selectedFirm, setSelectedFirm] = useState<FirmData | null>(null);
  const [range, setRange] = useState<Nullable<(Date | null)[]>>(getDefaultRange);
  const [selectedChatType, setSelectedChatType] = useState<ChatType | null>(null);

  return (
    <StatsContext.Provider
      value={{
        monitorPeriod,
        selectedFirm,
        range,
        selectedChatType,
        setMonitorPeriod,
        setSelectedFirm,
        setRange,
        setSelectedChatType,
      }}
    >
      {children}
    </StatsContext.Provider>
  );
};

export const useStats = () => {
  const context = useContext(StatsContext);
  if (!context) {
    throw Error('useStats must be used with an StatsContextProvider');
  }

  return context;
};
