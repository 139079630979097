import React, { useEffect, useState, useTransition } from 'react';
import './ChangeProject.scss';
import { Dropdown } from 'primereact/dropdown';
import { useAccount } from '../../../contexts/account/AccountContexts';
import { useTranslation } from 'react-i18next';

interface ChangeProjectProps {
  selectedProject: number | null;
  onChangeProject: (projectId: number | null) => void;
}

const ChangeProject: React.FC<ChangeProjectProps> = ({ selectedProject, onChangeProject }) => {
  const { t } = useTranslation();
  const { projectResponse } = useAccount();
  const [item, setItem] = useState<{ name: string; type: number | null } | null>(null);

  useEffect(() => {
    setItem(getCurrent());
  }, []);

  const prepareOptions = () => {
    const defaultData = { name: t('withoutAProject'), type: null };
    const projects = projectResponse?.map((responseItem) => ({
      name: responseItem.firmName,
      type: responseItem.id,
    }));

    if (!projects) {
      return [defaultData];
    }

    return [defaultData, ...projects];
  };

  const itemTemplate = (option: { name: string; type: number | null }) => {
    return <div className="p-dropdown-item">{option.name}</div>;
  };

  const getCurrent = () => {
    const currentItem = prepareOptions().find((ee) => ee.type === selectedProject);
    return currentItem ? currentItem : { name: t('withoutAProject'), type: null };
  };

  return (
    <div>
      <Dropdown
        value={item}
        onChange={(e) => {
          console.log(e.value.type);
          setItem(e.value);
          onChangeProject(e.value.type);
        }}
        options={prepareOptions()}
        className="dropdown"
        optionLabel="name"
        itemTemplate={itemTemplate}
      />
    </div>
  );
};

export default ChangeProject;
