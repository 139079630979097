import React, { useEffect, useState } from 'react';
import './DashboardPage.scss';
import { useTranslation } from 'react-i18next';
import { AdminMonitorStatsResponse } from '../../../shared/models/admin/dashboard/AdminMonitorStatsResponse';
import {
  getActiveMonitorStats,
  getActiveUsersStats,
  getConfiguration,
  getDailyRequestStats,
} from '../../../shared/services/admin/dashboard/AdminDashboardService';
import Loader from '../../../shared/components/common/loader/Loader';
import { AdminConfigurationResponse } from '../../../shared/models/admin/dashboard/AdminConfigurationResponse';

const DashboardPage: React.FC = () => {
  const { t } = useTranslation();
  const [adminGPTAPIStats, setAdminGPTAPIStats] = useState<AdminMonitorStatsResponse[] | null>(null);
  const [adminGPTBrowserAPIStats, setAdminGPTBrowserAPIStats] = useState<
    AdminMonitorStatsResponse[] | null
  >(null);
  const [activeMonitors, setActiveMonitors] = useState<number | null>(null);
  const [activeUsers, setActiveUsers] = useState<number | null>(null);
  const [configuration, setConfiguration] = useState<AdminConfigurationResponse[] | null>(null);
  const [adminGeminiStats, setAdminGeminiStats] = useState<AdminMonitorStatsResponse[] | null>(null);
  const [adminGeminiBrowserAPIStats, setAdminGeminiBrowserAPIStats] = useState<
    AdminMonitorStatsResponse[] | null
  >(null);
  const [adminClaudeStats, setAdminClaudeStats] = useState<AdminMonitorStatsResponse[] | null>(null);
  const [adminDeepSeekAPIStats, setAdminDeepSeekAPIStats] = useState<AdminMonitorStatsResponse[] | null>(
    null
  );

  useEffect(() => {
    Promise.all([
      getActiveMonitorStats(),
      getActiveUsersStats(),
      getConfiguration(),
      getDailyRequestStats(),
    ]).then(([activeMonitorsData, activeUsersData, configurationData, data]) => {
      setActiveMonitors(activeMonitorsData || 0);
      setActiveUsers(activeUsersData || 0);
      setConfiguration(configurationData ?? []);
      setAdminGPTAPIStats(
        data.filter((field) => field.features.includes('GPT_API') && field.todayDataCountAPI > 0) ?? []
      );
      setAdminGPTBrowserAPIStats(
        data.filter(
          (field) => field.features.includes('GPT_API_BROWSER') && field.todayDataCountAPIBrowser > 0
        ) ?? []
      );
      setAdminGeminiStats(
        data.filter(
          (field) => field.features.includes('GEMINI_API') && field.todayDataCountGeminiAPI > 0
        ) ?? []
      );
      setAdminGeminiBrowserAPIStats(
        data.filter(
          (field) =>
            field.features.includes('GEMINI_API_BROWSER') && field.todayDataCountGeminiAPIBrowser > 0
        ) ?? []
      );
      setAdminClaudeStats(
        data.filter(
          (field) => field.features.includes('CLAUDE_API') && field.todayDataCountClaudeAPI > 0
        ) ?? []
      );
      setAdminDeepSeekAPIStats(
        data.filter(
          (field) => field.features.includes('DEEP_SEEK_API') && field.todayDataCountDeepSeekAPI > 0
        ) ?? []
      );
    });
  }, []);

  if (
    !adminGPTAPIStats &&
    !adminGeminiStats &&
    !adminClaudeStats &&
    !adminGeminiBrowserAPIStats &&
    !adminDeepSeekAPIStats &&
    !activeMonitors &&
    !activeUsers
  ) {
    return <Loader />;
  }

  const allRequest =
    (adminGPTAPIStats ? adminGPTAPIStats.length : 0) +
    (adminGPTBrowserAPIStats ? adminGPTBrowserAPIStats.length : 0) +
    (adminGeminiStats ? adminGeminiStats.length : 0) +
    (adminGeminiBrowserAPIStats ? adminGeminiBrowserAPIStats.length : 0) +
    (adminClaudeStats ? adminClaudeStats.length : 0) +
    (adminDeepSeekAPIStats ? adminDeepSeekAPIStats.length : 0);

  return (
    <div className="dashboard">
      <div className="dashboard__row">
        <div className="circle-data">
          <div className="circle-data__title">{t('activeMonitors')}</div>
          <div className="circle-data__value">{activeMonitors}</div>
        </div>
        <div className="circle-data">
          <div className="circle-data__title">{t('dailyMonitorRequest')}</div>
          <div className="circle-data__value">{allRequest}</div>
        </div>
        <div className="circle-data">
          <div className="circle-data__title">{t('activeUsers')}</div>
          <div className="circle-data__value">{activeUsers}</div>
        </div>
      </div>

      <div className="dashboard__row dashboard__row--rect">
        <div className="rect-data">
          <div
            className={`rect-data__title ${
              configuration?.find((data) => data.feature === 'SEMRUSH')?.isEnabled
                ? 'rect-data__title--on'
                : 'rect-data__title--off'
            }`}
          >
            {t('semrushAPI')}
          </div>
        </div>
        <div className="rect-data">
          <div className={`rect-data__title rect-data__title--on`}>{t('gptApi')}</div>
        </div>
        <div className="rect-data">
          <div
            className={`rect-data__title ${
              configuration?.find((data) => data.feature === 'BROWSER')?.isEnabled
                ? 'rect-data__title--on'
                : 'rect-data__title--off'
            }`}
          >
            {t('gpt')}
          </div>
        </div>
      </div>

      <div className="dashboard__row dashboard__row--rect">
        <div className="rect-data">
          <div
            className={`rect-data__title ${
              configuration?.find((data) => data.feature === 'GEMINI')?.isEnabled
                ? 'rect-data__title--on'
                : 'rect-data__title--off'
            }`}
          >
            {t('geminiFlash')}
          </div>
        </div>
        <div className="rect-data">
          <div
            className={`rect-data__title ${
              configuration?.find((data) => data.feature === 'GEMINI_BROWSER')?.isEnabled
                ? 'rect-data__title--on'
                : 'rect-data__title--off'
            }`}
          >
            {t('geminiFlashPro')}
          </div>
          <div className="rect-data__value" />
        </div>
        <div className="rect-data">
          <div
            className={`rect-data__title ${
              configuration?.find((data) => data.feature === 'CLAUDE')?.isEnabled
                ? 'rect-data__title--on'
                : 'rect-data__title--off'
            }`}
          >
            {t('claudeApi')}
          </div>
        </div>
      </div>
      <div className="dashboard__row dashboard__row--rect">
        <div className="rect-data">
          <div
            className={`rect-data__title ${
              configuration?.find((data) => data.feature === 'BING')?.isEnabled
                ? 'rect-data__title--on'
                : 'rect-data__title--off'
            }`}
          >
            {t('bing')}
          </div>
        </div>
        <div className="rect-data">
          <div
            className={`rect-data__title ${
              configuration?.find((data) => data.feature === 'PROJECT_DAILY_STATS_MIGRATION')?.isEnabled
                ? 'rect-data__title--on'
                : 'rect-data__title--off'
            }`}
          >
            {t('projectDailyStatsMigration')}
          </div>
        </div>
        <div className="rect-data">
          <div
            className={`rect-data__title ${
              configuration?.find((data) => data.feature === 'PROJECT_MIGRATION')?.isEnabled
                ? 'rect-data__title--on'
                : 'rect-data__title--off'
            }`}
          >
            {t('statsMigration')}
          </div>
        </div>
      </div>
      <div className="dashboard__content">
        {adminGPTAPIStats && adminGPTAPIStats.length > 0 && (
          <div className="dashboard__table">
            <div className="dashboard__title">GPT 4o mini</div>
            <div className="row row--header">
              <div>{t('lp')}</div>
              <div>{t('monitorId')}</div>
              <div>{t('requestCount')}</div>
            </div>
            {adminGPTAPIStats.map((data, index) => (
              <div
                key={data.id}
                className={`row ${
                  data.status === 'DELETED'
                    ? 'row--deleted'
                    : data.status === 'ACTIVE'
                    ? 'row--active'
                    : ''
                }`}
              >
                <div>{index + 1}</div>
                <div>{data.id}</div>
                <div>{data.todayDataCountAPI}</div>
              </div>
            ))}
          </div>
        )}
        {adminGPTBrowserAPIStats && adminGPTBrowserAPIStats.length > 0 && (
          <div className="dashboard__table">
            <div className="dashboard__title">GPT 4o</div>
            <div className="row row--header">
              <div>{t('lp')}</div>
              <div>{t('monitorId')}</div>
              <div>{t('requestCount')}</div>
            </div>
            {adminGPTBrowserAPIStats.map((data, index) => (
              <div
                key={data.id}
                className={`row ${
                  data.status === 'DELETED'
                    ? 'row--deleted'
                    : data.status === 'ACTIVE'
                    ? 'row--active'
                    : ''
                }`}
              >
                <div>{index + 1}</div>
                <div>{data.id}</div>
                <div>{data.todayDataCountAPIBrowser}</div>
              </div>
            ))}
          </div>
        )}
        {adminGeminiStats && adminGeminiStats.length > 0 && (
          <div className="dashboard__table">
            <div className="dashboard__title">Gemini Flash</div>
            <div className="row row--header">
              <div>{t('lp')}</div>
              <div>{t('monitorId')}</div>
              <div>{t('requestCount')}</div>
            </div>
            {adminGeminiStats.map((data, index) => (
              <div
                key={data.id}
                className={`row ${
                  data.status === 'DELETED'
                    ? 'row--deleted'
                    : data.status === 'ACTIVE'
                    ? 'row--active'
                    : ''
                }`}
              >
                <div>{index + 1}</div>
                <div>{data.id}</div>
                <div>{data.todayDataCountGeminiAPI}</div>
              </div>
            ))}
          </div>
        )}
        {adminGeminiBrowserAPIStats && adminGeminiBrowserAPIStats.length > 0 && (
          <div className="dashboard__table">
            <div className="dashboard__title">Gemini Flash Pro</div>
            <div className="row row--header">
              <div>{t('lp')}</div>
              <div>{t('monitorId')}</div>
              <div>{t('requestCount')}</div>
            </div>
            {adminGeminiBrowserAPIStats.map((data, index) => (
              <div
                key={data.id}
                className={`row ${
                  data.status === 'DELETED'
                    ? 'row--deleted'
                    : data.status === 'ACTIVE'
                    ? 'row--active'
                    : ''
                }`}
              >
                <div>{index + 1}</div>
                <div>{data.id}</div>
                <div>{data.todayDataCountAPIBrowser}</div>
              </div>
            ))}
          </div>
        )}
        {adminClaudeStats && adminClaudeStats.length > 0 && (
          <div className="dashboard__table">
            <div className="dashboard__title">Claude Sonnet</div>
            <div className="row row--header">
              <div>{t('lp')}</div>
              <div>{t('monitorId')}</div>
              <div>{t('requestCount')}</div>
            </div>
            {adminClaudeStats.map((data, index) => (
              <div
                key={data.id}
                className={`row ${
                  data.status === 'DELETED'
                    ? 'row--deleted'
                    : data.status === 'ACTIVE'
                    ? 'row--active'
                    : ''
                }`}
              >
                <div>{index + 1}</div>
                <div>{data.id}</div>
                <div>{data.todayDataCountClaudeAPI}</div>
              </div>
            ))}
          </div>
        )}
        {adminDeepSeekAPIStats && adminDeepSeekAPIStats.length > 0 && (
          <div className="dashboard__table">
            <div className="dashboard__title">DeepSeek R1</div>
            <div className="row row--header">
              <div>{t('lp')}</div>
              <div>{t('monitorId')}</div>
              <div>{t('requestCount')}</div>
            </div>
            {adminDeepSeekAPIStats.map((data, index) => (
              <div
                key={data.id}
                className={`row ${
                  data.status === 'DELETED'
                    ? 'row--deleted'
                    : data.status === 'ACTIVE'
                    ? 'row--active'
                    : ''
                }`}
              >
                <div>{index + 1}</div>
                <div>{data.id}</div>
                <div>{data.todayDataCountDeepSeekAPI}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardPage;
