import React, { useState } from 'react';
import './TeamAddPage.scss';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { inviteUser } from '../services/TeamService';
import { UserTeamData } from '../models/TeamResponse';

const TeamAddPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [apiError, setApiError] = useState<string | null>(null);
  const [role, setRole] = useState<{ name: string; type: string } | null>(null);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const roleOptions = [
    { name: t('admin'), type: 'ADMIN' },
    { name: t('user'), type: 'USER' },
  ];
  const onSubmit = async (formData: any) => {
    if (!role) {
      setApiError('No role');
      return;
    }

    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      role: role.type,
    } as UserTeamData;

    inviteUser(data)
      .then(() => navigate('/settings/team'))
      .catch((err) => setApiError(err.response.data.error));
  };

  const itemTemplate = (option: any) => {
    return <div className="p-dropdown-item">{option.name}</div>;
  };

  return (
    <div className="team-add-page">
      <ConfirmDialog className="dialog-box dialog-box--accept" />

      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <div className="form__row">
          <div className="form__label">{t('firstName')}:</div>
          <InputText
            {...register('firstName', {
              required: 'First name is required',
            })}
            type="text"
            className={errors.firstName || apiError ? 'form__invalid' : ''}
          />
          {errors.firstName && <span>{errors.firstName.message as string}</span>}
        </div>
        <div className="form__row">
          <div className="form__label">{t('lastName')}:</div>
          <InputText
            {...register('lastName', {
              required: 'Last name is required',
            })}
            type="text"
            className={errors.lastName || apiError ? 'form__invalid' : ''}
          />
          {errors.lastName && <span>{errors.lastName.message as string}</span>}
        </div>
        <div className="form__row">
          <div className="form__label">{t('emailAddress')}:</div>
          <InputText
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: 'Please enter a valid email address',
              },
            })}
            type="text"
            className={errors.email || apiError ? 'form__invalid' : ''}
          />
          {errors.email && <span>{errors.email.message as string}</span>}
        </div>
        <div className="form__row">
          <div className="form__label">{t('role')}:</div>
          <Dropdown
            value={role}
            placeholder={t('selectRole')}
            onChange={(e) => setRole(e.value)}
            options={roleOptions}
            className="dropdown"
            optionLabel="name"
            itemTemplate={itemTemplate}
          />
        </div>

        <Button className="btn btn--sm" label={t('invite')} />
      </form>
      <div className="change-password-page__help">
        <div className="help-box">
          <label className="help-box__title">{t('inviteUser')}</label>
          <div>{t('inviteUserDesc')} </div>
        </div>
      </div>
    </div>
  );
};

export default TeamAddPage;
