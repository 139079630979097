import React, { useEffect, useRef, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import './Chart.scss';
import { EChartsOption } from 'echarts';
import { ChartProps, PieChartData } from './models/ChartModel';
import { COLORS_FIRST_SERIES, COLORS_SECOND_SERIES, formatLargeNumber } from '../../utils/utils';
import { useTranslation } from 'react-i18next';

const Chart: React.FC<ChartProps> = ({
  lineChartData,
  lineChartDataColorsPalette = COLORS_FIRST_SERIES,
  lineChartSecondData,
  lineChartSecondDataColorsPalette = COLORS_SECOND_SERIES,
  pieChartData,
  headers,
  onLegendClick,
  chartSize,
  type,
  labelY,
  labelSecondY,
  inverseY = false,
  inverseSecondY,
  labels = true,
  selectedBrand,
}) => {
  const eChartsRef = useRef<ReactECharts>(null);
  const { t } = useTranslation();

  const getLineSeries = (
    lineChartData: (string | number)[][] | undefined,
    lineChartSecondData: (string | number)[][] | undefined
  ): Object[] => {
    if (!lineChartData && !lineChartSecondData) {
      return [];
    }

    const series: Object[] = [];

    if (lineChartData) {
      for (let i = 1; i < lineChartData[0].length; i++) {
        let data = {
          type,
          yAxisIndex: 0,
          datasetIndex: 0,
          itemStyle: {
            color: lineChartDataColorsPalette[i - 1],
          },
          lineStyle: {
            color: lineChartDataColorsPalette[i - 1],
          },
        } as any;

        if (selectedBrand) {
          const brandName = lineChartData[0][i];
          const isHighlighted = brandName === selectedBrand;

          data.itemStyle.opacity = isHighlighted ? 1 : 0.3;
          data.lineStyle.opacity = isHighlighted ? 1 : 0.3;
          data.emphasis = {
            focus: 'series',
            itemStyle: {
              opacity: 1,
            },
            lineStyle: {
              opacity: 1,
            },
          };
        }

        series.push(data);
      }
    }

    if (lineChartSecondData) {
      for (let i = 1; i < lineChartSecondData[0].length; i++) {
        series.push({
          type,
          yAxisIndex: 1,
          datasetIndex: 1,
          itemStyle: {
            color: lineChartSecondDataColorsPalette[i - 1],
          },
          lineStyle: {
            color: lineChartSecondDataColorsPalette[i - 1],
          },
        });
      }
    }

    return series;
  };

  const getPieSeries = (pieChartData: PieChartData[] | undefined): Object[] => {
    if (!pieChartData) {
      return [];
    }

    return [
      {
        type: 'pie',
        data: pieChartData,
        radius: ['40%', '70%'],
        padAngle: 0.5,
        labelLine: {
          show: true,
        },
        itemStyle: {
          borderRadius: 2,
        },
      },
    ];
  };

  const isPieType = type === 'pie';

  const color = isPieType ? lineChartDataColorsPalette : undefined;

  const xAxisOption = {
    type: 'category',
    axisTick: {
      show: false,
    },
    axisLabel: {
      margin: 24,
    },
    axisLine: {
      onZero: false,
    },
    boundaryGap: true,
  } as any;

  const yAxisOption = {
    inverse: inverseY ?? false,
    axisLine: {
      show: true,
      onZero: false,
    },

    axisLabel: {
      show: true,
      fontSize: 10,
      padding: [0, -2, 0, 0],
    },
    min: 1,
    max: 30,
  } as any;

  const yAxisSecondOption = lineChartSecondData
    ? ({
        inverse: inverseSecondY ?? false,
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: function (value: number) {
            return `${value}%`;
          },
          fontSize: 10,
          padding: [0, -2, 0, 0],
        },
        min: 0,
        max: 100,
      } as any)
    : {};

  const eChartsOptions: EChartsOption = {
    legend: {
      orient: 'horizontal',
      top: -1000,
      right: 0,
    },
    tooltip: {
      trigger: isPieType ? 'item' : 'axis',
      position: function (point, params, dom, rect, size) {
        const [x, y] = point;
        const { contentSize } = size;
        const tooltipWidth = contentSize[0];
        const posX = x + 10;
        const finalPosX = posX + tooltipWidth > size.viewSize[0] ? x - tooltipWidth + 180 : posX;

        return [finalPosX, y];
      },
      formatter: function (params: any) {
        let dateText = params[0]?.value[0];
        let tooltipText = isPieType ? '' : dateText;
        let style = '';
        if (isPieType) {
          style += 'chart-tooltip__content--pie';
        }

        tooltipText += `<div class="chart-tooltip__content ${style}">`;

        if (isPieType) {
          tooltipText += `<div class="chart-tooltip__row"><div class="chart-tooltip__marker">${
            params.marker
          }</div><div class="chart-tooltip__text"><span>${getPromptByLabel(
            params.data.name
          )}</span></div><strong>${params.data.value ? params.data.value + '%' : '-'}</strong></div>`;
          tooltipText += '</div>';
          return tooltipText;
        }

        const indexArray: number[] = [];
        params.forEach((param: any) => {
          const index = param.dimensionNames.findIndex((name: any) => param.seriesName === name);
          const hasEntry = indexArray.includes(index);

          if (!hasEntry) {
            indexArray.push(index);
          }

          let value = param.value[index];
          if (!isPieType && param.seriesName.includes(t('shareOfVoice'))) {
            value = value === 0 ? '-' : formatLargeNumber(value);
          } else if (!isPieType && param.seriesName.includes(t('brandScore'))) {
            value = value ? `${value}%` : '-';
          } else if (!isPieType && !chartSize) {
            value = value > 30 ? t('outsideTop30') : formatLargeNumber(value);
          } else {
            value = formatLargeNumber(value);
          }

          tooltipText += `<div class="chart-tooltip__row"><div class="chart-tooltip__marker">${
            param.marker
          }</div><div class="chart-tooltip__text"><span>${param.seriesName}</span></div><strong>${
            formatLargeNumber(value) ?? '-'
          }</strong></div>`;
        });

        tooltipText += '</div>';
        return tooltipText;
      },
    },
    dataset: [
      {
        source: lineChartData,
      },
      {
        source: lineChartSecondData,
      },
    ],
    xAxis: isPieType ? undefined : xAxisOption,
    yAxis: isPieType ? undefined : [yAxisOption, yAxisSecondOption],
    grid: {
      top: 60,
      bottom: 60,
      left: '30px',
      right: '30px',
    },
    color,
    series: isPieType ? getPieSeries(pieChartData) : getLineSeries(lineChartData, lineChartSecondData),
  };

  const [legendStateMap, setLegendStateMap] = useState<Map<string, boolean>>(
    new Map<string, boolean>(headers?.map((header) => [header, true]))
  );

  useEffect(() => {
    if (eChartsRef.current) {
      const chart = eChartsRef.current.getEchartsInstance();
      chart.setOption(eChartsOptions);

      if (selectedBrand) {
        chart.on('showTip', () => {
          const updatedSeries = (
            Array.isArray(eChartsOptions.series) ? eChartsOptions.series : [eChartsOptions.series]
          ).map((s: any) => ({
            ...s,
            itemStyle: { ...s.itemStyle, opacity: 1 },
            lineStyle: { ...s.lineStyle, opacity: 1 },
          }));

          chart.setOption({ series: updatedSeries });
        });

        chart.on('hideTip', () => {
          const updatedSeries = (
            Array.isArray(eChartsOptions.series) ? eChartsOptions.series : [eChartsOptions.series]
          ).map((s: any, i: number) => {
            const index = !lineChartData
              ? null
              : lineChartData[0]?.findIndex((name: any) => selectedBrand === name);
            return {
              ...s,
              itemStyle: {
                ...s.itemStyle,
                opacity: index === i + 1 ? 1 : 0.3,
              },
              lineStyle: {
                ...s.lineStyle,
                opacity: index === i + 1 ? 1 : 0.3,
              },
            };
          });

          chart.setOption({ series: updatedSeries });
        });
      }
    }
  }, [lineChartData, lineChartSecondData, eChartsOptions]);

  const handleLegendItemClick = (label: string) => {
    setLegendStateMap((prevState) => {
      const updatedMap = new Map(prevState);
      const currentVisibility = updatedMap.has(label) ? !updatedMap.get(label) : false;
      updatedMap.set(label, currentVisibility);

      const canManage =
        Array.from(updatedMap.values()).filter((value) => !value).length + 1 <= updatedMap.size;

      if (!eChartsRef.current || (!canManage && prevState.get(label) === true)) {
        return prevState;
      }

      const instance = eChartsRef.current.getEchartsInstance();
      instance.dispatchAction({
        type: 'legendToggleSelect',
        name: label,
      });

      return updatedMap;
    });
  };

  const getPromptByLabel = (label: string): string => {
    return headers?.find((header) => label === header) ?? label;
  };

  useEffect(() => {
    if (onLegendClick) {
      onLegendClick(legendStateMap);
    }
  }, [legendStateMap, onLegendClick]);

  const renderLegendItem = (index: number, label: string, isAveragePosition: boolean) => {
    return (
      <div
        key={index}
        className={`${
          !legendStateMap.get(label as string) ? '' : 'chart__legend-item--active'
        }  chart__legend-item`}
        onClick={() => handleLegendItemClick(label as string)}
      >
        <span
          className="chart__legend-color"
          style={{
            background: isAveragePosition
              ? lineChartDataColorsPalette[index]
              : lineChartSecondDataColorsPalette[index],
          }}
        />
        {getPromptByLabel(label as string)}
      </div>
    );
  };

  const chartSizeStyle = {
    height: lineChartData ? (chartSize ? '367px' : '530px') : '220px',
    width: '100%',
  };

  return (
    <div className={`${chartSize && 'chart--sm'} chart`}>
      <div className="chart__box">
        {lineChartData && labels && <label className="chart__label chart__label--left">{labelY}</label>}
        {lineChartSecondData && labels && (
          <label className="chart__label chart__label--right">{labelSecondY}</label>
        )}
        <ReactECharts ref={eChartsRef} option={eChartsOptions} style={chartSizeStyle} />
        {lineChartData && !chartSize && (
          <label className="chart__label chart__label--bottom-left">{t('outsideTop30')}</label>
        )}
      </div>
      {(lineChartData || lineChartSecondData) && (
        <div className="chart__legend">
          {lineChartData &&
            lineChartData[0]
              ?.slice(1)
              .map((label, index) => renderLegendItem(index, label as string, true))}
          {lineChartSecondData &&
            lineChartSecondData[0]
              ?.slice(1)
              .map((label, index) => renderLegendItem(index, label as string, false))}
        </div>
      )}
    </div>
  );
};

export default Chart;
