import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';
import { QuestionResponse } from '../../../../shared/models/projects/QuestionResponse';

interface ProjectWizardContextType {
  domain: string | null;
  setDomain: Dispatch<SetStateAction<string | null>>;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  questionResponse: QuestionResponse | null;
  setQuestionResponse: Dispatch<SetStateAction<QuestionResponse | null>>;
}

export const ProjectWizardContext = createContext<ProjectWizardContextType | undefined>(undefined);

export const ProjectWizardProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [domain, setDomain] = useState<string | null>(null);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [questionResponse, setQuestionResponse] = useState<QuestionResponse | null>(null);

  return (
    <ProjectWizardContext.Provider
      value={{
        domain,
        setDomain,
        activeStep,
        setActiveStep,
        questionResponse,
        setQuestionResponse,
      }}
    >
      {children}
    </ProjectWizardContext.Provider>
  );
};

export const useProjectWizard = () => {
  const context = useContext(ProjectWizardContext);
  if (!context) {
    throw Error('useProjectWizard must be used with an ProjectWizardProvider');
  }

  return context;
};
