import React, { useEffect, useState } from 'react';
import './ChangeName.scss';
import { useTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { ChangeProjectNameType } from '../../../types/projects/ChangeProjectNameType';
import { Tooltip } from 'primereact/tooltip';
import { useForm } from 'react-hook-form';

interface ChangeNameProps {
  selectedProject: string;
  onChangeProjectName: (projectId: string) => void;
  onChangeProjectMode: (projectMode: ChangeProjectNameType) => void;
  isSaveDisabled: (disabled: boolean) => void;
}

const ChangeName: React.FC<ChangeNameProps> = ({
  selectedProject,
  onChangeProjectName,
  onChangeProjectMode,
  isSaveDisabled,
}) => {
  const { t } = useTranslation();
  const [selectedMode] = useState<ChangeProjectNameType>('ALL');
  const [isRequiredError, setRequiredError] = useState<boolean>(true);
  let isFirstLoad = true;

  useEffect(() => {
    isSaveDisabled(isRequiredError);
  }, [isRequiredError]);

  const handleOnChange = async (ev: React.FocusEvent<HTMLInputElement>) => {
    isFirstLoad = false;
    const isInvalid =
      ev.target.value.trim() === '' ||
      ev.target.value.toLowerCase().trim() === selectedProject.toLowerCase().trim();
    setRequiredError(isInvalid);

    if (!isInvalid) {
      onChangeProjectName(ev.target.value);
    }
  };

  return (
    <div className="change-name-dialog">
      <div className="change-name-dialog__input">
        <InputText
          type="text"
          defaultValue={selectedProject}
          onInput={handleOnChange}
          className={isRequiredError && !isFirstLoad ? 'form-box__invalid' : ''}
        />
        {isRequiredError && !isFirstLoad && <span>{t('fieldIsRequired')}</span>}
      </div>

      <div className="change-name-dialog__content">
        <div className="change-name-dialog__title">
          {t('whereShouldISetTheKeyword')}
          <span className="material-symbols-outlined question-mark question-mark--left" id="change-name">
            help
          </span>
          <Tooltip
            target="#change-name"
            content={t('changeNameTootlip')}
            className="tooltip--top"
            position="top"
            style={{ width: '220px' }}
          />
        </div>
        <div className="change-name-dialog__row">
          <RadioButton
            inputId="all"
            checked={selectedMode === 'ALL'}
            value={'ALL' as ChangeProjectNameType}
            name="options"
            onChange={(ev) => onChangeProjectMode(ev.target.value)}
          />
          <label htmlFor="all">{t('applyToAllQuestions')}</label>
        </div>
        <div className="change-name-dialog__row">
          <RadioButton
            inputId="mix"
            checked={selectedMode === 'MIX'}
            value={'MIX' as ChangeProjectNameType}
            name="options"
            onChange={(ev) => onChangeProjectMode(ev.target.value)}
          />
          <label htmlFor="mix">{t('applyToPartQuestions')}</label>
        </div>
      </div>
    </div>
  );
};

export default ChangeName;
