import React, { useCallback, useEffect, useState } from 'react';
import './ProjectsPreviewPage.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useAccount } from '../../../shared/contexts/account/AccountContexts';
import Loader from '../../../shared/components/common/loader/Loader';
import { PROJECTS } from '../../../shared/routes/path';
import { useTranslation } from 'react-i18next';
import Title from '../../../shared/components/title/Title';
import { PromptsData } from '../../prompt/models/PromptsData';
import PromptsTable from '../../../shared/components/prompts-table/PromptsTable';
import { Tooltip } from 'primereact/tooltip';
import FilterBox from '../../../shared/components/filter-box/FilterBox';
import { FilterItem } from '../../../shared/components/filter-box/models/FilterBoxModel';
import {
  changeProjectName,
  getProjects,
  getPromptsForProject,
} from '../../../shared/services/projects/ProjectService';
import Chart from '../../../shared/components/chart/Chart';
import { ChartMatrixData } from '../../../shared/components/stats/models/StatsModel';
import { createProjectStatsMatrix, prepareProjectGeneralData } from './services/ProjectPreviewService';
import { PieChartData } from '../../../shared/components/chart/models/ChartModel';
import {
  COLORS_FIRST_SERIES,
  COLORS_SECOND_SERIES,
  getTextForVisibility,
} from '../../../shared/utils/utils';
import { getPeriod, prepareAndGetChatType } from '../../../shared/helpers/StatsHelpers';
import { useStats } from '../../../shared/contexts/StatsContexts';
import { PromptStatus } from '../../prompt/types/PromptStatus';
import { Calendar } from 'primereact/calendar';
import { MonitorPrompt } from '../../../shared/models/monitor/MonitorPrompt';
import {
  getProjectCumulativeDailyStats,
  getProjectShareOfVoice,
} from '../../../shared/services/projects/stats/ProjectDailyStatsService';
import { StatsKeywordsData } from './models/StatsKeywordsData';
import { CumulativeProjectDailyStats } from './models/CumulativeProjectDailyStats';
import { MonitorPromptsKeywordsStatsResponse } from '../../../shared/services/monitor/keywords/stats/MonitorPromptsKeywordsStatsResponse';
import { ProjectGeneralData } from './models/ProjectGeneralData';
import EmptyState from '../../../shared/components/common/empty-state/EmptyState';
import { EmptyStateData } from '../../../shared/components/common/empty-state/models/EmptyStateData';
import { ProjectDailyStatsResponse } from '../../../shared/models/projects/stats/ProjectDailyStatsResponse';
import { getPieChartWithOthers } from '../../../shared/components/stats/services/StatsService';
import { format } from 'date-fns';
import { Dropdown } from 'primereact/dropdown';
import { chatTypeOptions } from '../../../shared/helpers/MonitorTypeHelpers';
import { getKeywordsDailyStatsForProject } from '../../../shared/services/monitor/keywords/stats/KeywordsStatsService';
import { confirmDialog } from 'primereact/confirmdialog';
import ChangeName from '../../../shared/components/common/change-name/ChangeName';
import { Button } from 'primereact/button';
import { ChangeProjectNameType } from '../../../shared/types/projects/ChangeProjectNameType';
import { ChangeProjectNameData } from '../../../shared/models/projects/ChangeProjectNameData';

const ProjectsPreviewPage: React.FC = () => {
  const navigate = useNavigate();
  let { projectId, type, period, chat } = useParams();
  const { t } = useTranslation();
  const { monitorPeriod, setMonitorPeriod, range, setRange, selectedChatType, setSelectedChatType } =
    useStats();
  const { projectResponse, setProjectResponse, setCanFetch } = useAccount();

  const [monitorPrompts, setMonitorPrompts] = useState<MonitorPrompt[] | []>([]);
  const [projectStatsMatrixData, setProjectStatsMatrixData] = useState<ChartMatrixData | null>(null);
  const [monitorPromptsKeywordsStatsData, setMonitorPromptsKeywordsStatsData] = useState<
    MonitorPromptsKeywordsStatsResponse[] | null
  >(null);
  const [projectDailyStatsData, setProjectDailyStatsData] = useState<ProjectDailyStatsResponse | null>(
    null
  );
  const [projectGeneralData, setProjectGeneralData] = useState<ProjectGeneralData | null>(null);
  const [shareOfVoiceData, setShareOfVoiceData] = useState<PieChartData[] | undefined>([]);
  const [, setMainChartLegendMap] = useState<Map<string, boolean> | null>(null);
  const [monitorHeaders, setMonitorHeaders] = useState<string[]>([]);

  const projectIdNumber = Number(projectId);
  const [loading, setLoading] = useState<boolean>(true);
  const [isSaveDisabled, setSaveDisabled] = useState<boolean>(true);

  useEffect(() => {
    setSaveDisabled(true);
  }, []);

  const canAddClaude = projectIdNumber === 1;
  let newProjectName = '';
  let projectChangeMode: ChangeProjectNameType = 'ALL';

  const noDataEmptyState = {
    title: t('noDataAvailable'),
    content: t('projectNoDataAvailableDesc'),
  } as EmptyStateData;

  const noDataAiVisibilityEmptyState = {
    title: t('noAIData'),
    content: t('noAIDataDesc'),
  } as EmptyStateData;

  useEffect(() => {
    const projectsIds = projectResponse?.map((project) => project.id);
    if (isNaN(projectIdNumber) || !projectsIds?.includes(projectIdNumber)) {
      navigate(PROJECTS);
    }
  }, []);

  useEffect(() => {
    const newMonitorPeriod = getPeriod(period);
    setMonitorPeriod(newMonitorPeriod);
    setSelectedChatType(prepareAndGetChatType('GPT', chat));
  }, [type, period, chat]);

  useEffect(() => {
    const acceptButton = document.querySelector('.confirm-change-name-btn') as HTMLElement;

    if (acceptButton) {
      if (isSaveDisabled) {
        acceptButton.classList.add('btn--disabled');
      } else {
        acceptButton.classList.remove('btn--disabled');
      }
    }
  }, [isSaveDisabled]);

  const fetchMonitorData = async () => {
    if (range && range[1]) {
      refresh();
    }
  };

  const editName = (id: number) => {
    if (!projectResponse) {
      return;
    }

    const currentProject = projectResponse.find((project) => project.id === id);
    newProjectName = currentProject?.firmName || '';
    confirmDialog({
      message: (
        <ChangeName
          selectedProject={newProjectName}
          onChangeProjectName={(projectName) => (newProjectName = projectName)}
          onChangeProjectMode={(projectMode) => (projectChangeMode = projectMode)}
          isSaveDisabled={(disabled) => setSaveDisabled(disabled)}
        />
      ),
      header: t('changeProjectName'),
      defaultFocus: 'accept',
      rejectLabel: t('cancel'),
      acceptLabel: t('save'),
      acceptClassName: isSaveDisabled
        ? 'confirm-change-name-btn btn--disabled'
        : 'confirm-change-name-btn',
      accept: () => changeProjectNameEvent(id),
      onHide: () => resetValues(),
    });
  };

  const changeProjectNameEvent = (id: number) => {
    const currentProject = projectResponse?.find((project) => project.id === id);
    const oldProjectName = currentProject?.firmName || '';

    if (
      !id ||
      !newProjectName ||
      !projectChangeMode ||
      oldProjectName.toLowerCase().trim() === newProjectName.toLowerCase().trim() ||
      newProjectName.trim() === ''
    ) {
      return;
    }

    setCanFetch(false);
    const data = {
      projectId: id,
      projectName: newProjectName.trim(),
      mode: projectChangeMode,
    } as ChangeProjectNameData;

    changeProjectName(data)
      .then(() => getProjectAndRefresh())
      .catch(() => getProjectAndRefresh());
  };

  const resetValues = () => {
    newProjectName = '';
    projectChangeMode = 'ALL';
    setSaveDisabled(true);
  };

  // useAutoRefreshView(fetchMonitorData, 60_000);
  useEffect(() => {
    fetchMonitorData();
  }, [monitorPeriod, range, selectedChatType]);

  const getProjectAndRefresh = () => {
    setCanFetch(true);
    setProjectResponse(null);
    refresh();
  };

  const refresh = useCallback(() => {
    if (!projectResponse) {
      return;
    }

    resetValues();

    if (!projectIdNumber || !monitorPeriod || !selectedChatType) {
      return;
    }

    setMonitorPrompts([]);
    setMonitorPromptsKeywordsStatsData(null);
    setShareOfVoiceData([]);
    setProjectDailyStatsData(null);
    setProjectGeneralData(null);
    setProjectStatsMatrixData(null);
    setLoading(true);
    const startDate = range ? range[0] : null;
    const endDate = range ? range[1] : null;

    if (!projectId || !monitorPeriod || !selectedChatType || !startDate || !endDate) {
      return;
    }

    Promise.all([
      getPromptsForProject(projectIdNumber),
      getKeywordsDailyStatsForProject(
        projectIdNumber,
        selectedChatType,
        monitorPeriod,
        startDate,
        endDate
      ),
      getProjectShareOfVoice(projectIdNumber, selectedChatType, startDate, endDate),
    ])
      .then(
        ([projectPromptsResponse, monitorPromptsKeywordsStatsResponse, projectShareOfVoiceResponse]) => {
          const keywords: StatsKeywordsData[] =
            projectPromptsResponse.map((prompt) => ({
              [prompt.keyword]: prompt.id,
            })) ?? [];

          const cumulativeProjectDailyStats: CumulativeProjectDailyStats = {
            projectId: projectIdNumber,
            keywords,
            period: monitorPeriod,
            chatType: selectedChatType,
            startDate: new Date(format(startDate, 'yyyy-MM-dd HH:mm:ss')),
            endDate: new Date(format(endDate, 'yyyy-MM-dd HH:mm:ss')),
          };
          setMonitorPrompts(projectPromptsResponse);
          setMonitorPromptsKeywordsStatsData(monitorPromptsKeywordsStatsResponse);

          const hasAnyData = projectShareOfVoiceResponse.filter((data) => data.value > 0).length > 0;
          if (hasAnyData) {
            if (projectResponse) {
              const project = projectResponse.find((project) => project.id === projectIdNumber);
              setShareOfVoiceData(
                getPieChartWithOthers(projectShareOfVoiceResponse, project?.firmName || '', t)
              );
            } else {
              getProjects().then((projects) => {
                setProjectResponse(projects);

                const project = projects.find((project) => project.id === projectIdNumber);
                setShareOfVoiceData(
                  getPieChartWithOthers(projectShareOfVoiceResponse, project?.firmName || '', t)
                );
              });
            }
          }

          getProjectCumulativeDailyStats(cumulativeProjectDailyStats).then(
            (projectDailyStatsRecords) => {
              const projectStatsMatrix = createProjectStatsMatrix(
                monitorPeriod,
                projectDailyStatsRecords,
                t
              );
              const projectGeneralDataStats = prepareProjectGeneralData(projectDailyStatsRecords);
              setProjectStatsMatrixData(projectStatsMatrix);
              setProjectGeneralData(projectGeneralDataStats);
              setProjectDailyStatsData(projectDailyStatsRecords);
              setMonitorHeaders([
                t('brandScore'),
                // 'Share of voice',
                // 'AI Visibility score',
                t('averagePosition'),
              ]);
            }
          );
        }
      )
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, [projectIdNumber, monitorPeriod, range, selectedChatType]);

  const getPromptData = (): PromptsData[] => {
    return monitorPrompts
      .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
      .map((prompt) => {
        const monitorData = monitorPromptsKeywordsStatsData?.find(
          (monitorPromptKeywordsStatsData) =>
            monitorPromptKeywordsStatsData.monitorId === prompt.id &&
            monitorPromptKeywordsStatsData.keyword === prompt.keyword
        );
        return {
          id: prompt.id,
          prompt: prompt.prompt,
          status: prompt.status as PromptStatus,
          averagePosition: monitorData?.averagePosition ?? '-',
          shareOfVoice: monitorData?.averageShareOfVoice ? monitorData.averageShareOfVoice + '%' : '-',
          keyword: prompt.keyword,
          isProductModelQuestion: prompt.isProductModelQuestion,
        } as PromptsData;
      });
  };

  const onLegendMainChartChange = useCallback(
    (legendStateMap: Map<string, boolean>) => setMainChartLegendMap(legendStateMap),
    []
  );

  const getPeriodAndChatParam = (period: string): string => {
    const chatParam = chat ? chat.toLowerCase() : 'gpt';
    return `${period}/${chatParam}`;
  };

  const getPeriodParam = (): string => {
    return monitorPeriod ? monitorPeriod.toLowerCase() : 'daily';
  };

  const periodFilter: FilterItem[] = [
    {
      url: `${PROJECTS}/${projectIdNumber}/${getPeriodAndChatParam('daily')}`,
      label: t('dailyFilter'),
      isActive: monitorPeriod === 'DAILY',
    },
    {
      url: `${PROJECTS}/${projectIdNumber}/${getPeriodAndChatParam('weekly')}`,
      label: t('weeklyFilter'),
      isActive: monitorPeriod === 'WEEKLY',
    },
    {
      url: `${PROJECTS}/${projectIdNumber}/${getPeriodAndChatParam('monthly')}`,
      label: t('monthlyFilter'),
      isActive: monitorPeriod === 'MONTHLY',
    },
  ];

  const typeTemplate = (option: any) => {
    return <div className="p-dropdown-item">{option.name}</div>;
  };

  if (!projectResponse || !shareOfVoiceData || !projectDailyStatsData || loading) {
    return <Loader />;
  }

  const getEditButton = () => {
    if (
      !projectResponse ||
      !monitorPrompts ||
      monitorPrompts.length <= 0 ||
      monitorPrompts.filter((prompt) => prompt.status === 'ACTIVE').length <= 0
    ) {
      return <></>;
    }

    return (
      <Button
        className="action-btn material-symbols-outlined"
        onClick={() => editName(Number(projectId))}
      >
        edit
      </Button>
    );
  };

  const filtersPanel = () => {
    if (monitorPromptsKeywordsStatsData === null || selectedChatType === null) {
      return <></>;
    }

    return (
      <div className="filters">
        <Dropdown
          value={
            chatTypeOptions(t, canAddClaude).find((type) => selectedChatType === type.type) ||
            chatTypeOptions(t, canAddClaude)[0]
          }
          onChange={(e) =>
            navigate(`${PROJECTS}/${projectId}/${getPeriodParam()}/${e.value.type.toLowerCase()}`)
          }
          options={chatTypeOptions(t, canAddClaude)}
          className="dropdown"
          optionLabel="name"
          itemTemplate={typeTemplate}
        />
        <div className="calendar">
          <Calendar
            value={range}
            onChange={(e) => setRange(e.value as Date[])}
            selectionMode="range"
            readOnlyInput
            hideOnRangeSelection
            dateFormat="yy-mm-dd"
            pt={{
              root: {
                className: 'calendar',
              },
              panel: {
                className: 'calendar-panel',
              },
            }}
          />

          <span id="range" className="material-symbols-outlined question-mark question-mark--left">
            help
          </span>
          <Tooltip
            target="#range"
            content={t('rangeTooltip')}
            className="tooltip--top"
            position="top"
            style={{ width: '220px' }}
          />
        </div>
        <FilterBox items={periodFilter} />
      </div>
    );
  };

  if (!projectIdNumber) {
    navigate(PROJECTS);
    return null;
  }

  const project = projectResponse.filter((project) => project.id === projectIdNumber);
  if (!project || project.length <= 0) {
    navigate(PROJECTS);
    return null;
  }
  const countModelQuestions = monitorPrompts.filter(
    (monitorPrompt) => monitorPrompt.isProductModelQuestion
  ).length;

  return (
    <>
      <Title
        mainCategory={t('projects')}
        subCategory={project[0].firmName}
        link={PROJECTS}
        children={filtersPanel()}
        childrenOnMain={getEditButton()}
      />
      <div className="preview-project-page page-content">
        {monitorPrompts.length > 0 &&
        monitorPrompts.filter((prompt) => prompt.status === 'ACTIVE').length > 0 ? (
          <>
            {countModelQuestions > 0 && monitorPrompts.length > countModelQuestions && (
              <div className="stats-box warning-box">
                <span className="material-symbols-outlined">warning</span>
                {t('mixedQuestionWarning')}
              </div>
            )}

            <div className="stats">
              <div className="stats-box stats-box--without-margin stats-box--chart page-box">
                <div className="page-box__title-action">
                  <div className="page-box__title">{t('brandScoreAndAveragePosition')}</div>
                </div>

                <div className="page-box__content">
                  {projectStatsMatrixData &&
                  (projectStatsMatrixData.matrix.length > 1 ||
                    (projectStatsMatrixData.secondMatrix &&
                      projectStatsMatrixData.secondMatrix.length > 1)) ? (
                    <Chart
                      lineChartData={projectStatsMatrixData.secondMatrix}
                      lineChartDataColorsPalette={COLORS_SECOND_SERIES}
                      lineChartSecondData={projectStatsMatrixData.matrix}
                      lineChartSecondDataColorsPalette={COLORS_FIRST_SERIES}
                      headers={monitorHeaders}
                      onLegendClick={onLegendMainChartChange}
                      chartSize="SM"
                      type="line"
                      inverseY={true}
                      labelY={t('averagePosition')}
                      labelSecondY={t('brandScore')}
                    />
                  ) : (
                    <EmptyState emptyStateData={noDataEmptyState} />
                  )}
                </div>
              </div>
              <div>
                <div className="preview-project-page__twin-box">
                  <div className="stats-box stats-box--without-margin page-box">
                    <div className="page-box__title-action page-box__title-action--center">
                      <div className="page-box__title">{t('brandScore')}</div>
                    </div>
                    <div className="page-box__content page-box__content--value page-box__content--center">
                      {`${
                        projectGeneralData ? Math.round(projectGeneralData.averageVisibilityScore) : 0
                      }%`}
                    </div>
                  </div>
                  <div className="stats-box stats-box--without-margin page-box">
                    <div className="page-box__title-action page-box__title-action--center">
                      <div className="page-box__title">{t('visibility')} </div>
                    </div>
                    <div
                      id={projectGeneralData ? 'range2' : ''}
                      className={`page-box__content page-box__content--value page-box__content--center ${
                        projectGeneralData &&
                        `visibility visibility--${getTextForVisibility(
                          projectGeneralData.averageVisibilityScore
                        )}`
                      }`}
                    >
                      {`${
                        projectGeneralData
                          ? t(getTextForVisibility(projectGeneralData.averageVisibilityScore))
                          : '-'
                      }`}
                    </div>
                    {projectGeneralData && (
                      <Tooltip
                        target="#range2"
                        content={t(
                          getTextForVisibility(projectGeneralData.averageVisibilityScore) + 'Tooltip'
                        )}
                        className="tooltip--top"
                        position="top"
                        style={{ width: '220px' }}
                      />
                    )}
                  </div>
                </div>

                <div className="stats-box stats-box--pie-chart page-box">
                  <div className="page-box__title-action">
                    <div className="page-box__title">{t('shareOfVoice')}</div>
                  </div>
                  <div className="page-box__content">
                    {shareOfVoiceData && shareOfVoiceData.length > 0 ? (
                      <Chart
                        pieChartData={shareOfVoiceData}
                        type="pie"
                        lineChartDataColorsPalette={COLORS_FIRST_SERIES}
                      />
                    ) : (
                      <EmptyState emptyStateData={noDataAiVisibilityEmptyState} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="stats-box page-box">
              <PromptsTable
                tableTitle={`${t('popularQuestionsFor')} ${project[0].firmName}`}
                data={getPromptData()}
                refreshAfterDelete={refresh}
                place="PROJECT"
                projectId={projectIdNumber}
                chatType={selectedChatType ?? 'GPT'}
                periodType={monitorPeriod ?? 'DAILY'}
              />
            </div>
          </>
        ) : (
          <>
            <div className="stats">
              <div className="stats-box stats-box--without-margin stats-box--chart page-box page-box--skeleton" />
              <div>
                <div className="preview-project-page__twin-box">
                  <div className="stats-box stats-box--without-margin page-box page-box--skeleton" />
                  <div className="stats-box stats-box--without-margin page-box page-box--skeleton" />
                </div>
                <div className="stats-box stats-box--pie-chart page-box page-box--skeleton" />
              </div>
            </div>
            <div className="stats-box stats-box--table page-box page-box--skeleton" />
            <div className="toast">{t('gatheringInformation')}</div>
          </>
        )}
      </div>
    </>
  );
};

export default ProjectsPreviewPage;
