import { client } from '../apiClient';
import { MonitorDataResponse } from '../../models/monitor/MonitorDataResponse';
import { format } from 'date-fns';
import { getDefaultRange } from '../../utils/utils';
import { MonitorPeriod } from '../../types/monitor/MonitorPeriod';
import { MonitorType } from '../../types/monitor/MonitorType';

export const getMonitorData = async (
  id: string,
  keyword: string,
  period?: MonitorPeriod | 'DAILY',
  type?: MonitorType | 'ALL',
  from?: Date | null,
  to?: Date | null
): Promise<MonitorDataResponse> => {
  const defaultRange = getDefaultRange();
  from = from ? new Date(from) : defaultRange[0];
  to = to ? new Date(to) : defaultRange[1];

  const formattedFrom = format(from, 'yyyy-MM-dd');
  const formattedTo = format(to, 'yyyy-MM-dd');

  return client(true, true)
    .get<MonitorDataResponse>(
      `/monitor/data/list/${id}/${keyword}/${period}/${type}/${formattedFrom}/${formattedTo}`
    )
    .then((response) => response.data)
    .catch((error) => error);
};
