import React from 'react';
import { useAuth } from '../contexts/auth/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';
import { FAQ, QUEST_FAQ } from './path';

const PrivateRoutes = ({ children }: React.PropsWithChildren<{}>) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (!isAuthenticated && location.pathname === FAQ) {
    return <Navigate to={QUEST_FAQ} />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

export default PrivateRoutes;
