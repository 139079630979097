import React, { useEffect, useState } from 'react';
import './ProjectsPage.scss';
import { useAccount } from '../../shared/contexts/account/AccountContexts';
import Loader from '../../shared/components/common/loader/Loader';
import Title from '../../shared/components/title/Title';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { PROJECTS, PROJECTS_ADD } from '../../shared/routes/path';
import { NavLink, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import {
  changeProjectName,
  duplicateProject,
  removeProject,
} from '../../shared/services/projects/ProjectService';
import ChangeName from '../../shared/components/common/change-name/ChangeName';
import { ChangeProjectNameData } from '../../shared/models/projects/ChangeProjectNameData';
import { ChangeProjectNameType } from '../../shared/types/projects/ChangeProjectNameType';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { ProjectResponse } from '../../shared/models/projects/ProjectResponse';
import { InputText } from 'primereact/inputtext';
import useCanManage from '../../shared/hooks/account/UseCanManage';
import LimitTooltipComponent from '../../shared/components/common/limit-tooltip/LimitTooltipComponent';

const ProjectsPage: React.FC = () => {
  const { accountResponse, setProjectResponse, projectResponse, setCanFetch } = useAccount();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const canManage = useCanManage();
  const [loader, setLoader] = useState<boolean>(false);
  const [isSaveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  let newProjectName = '';
  let projectChangeMode: ChangeProjectNameType = 'ALL';

  useEffect(() => {
    setSaveDisabled(true);
  }, []);

  useEffect(() => {
    const acceptButton = document.querySelector('.confirm-change-name-btn') as HTMLElement;

    if (acceptButton) {
      if (isSaveDisabled) {
        acceptButton.classList.add('btn--disabled');
      } else {
        acceptButton.classList.remove('btn--disabled');
      }
    }
  }, [isSaveDisabled]);

  const confirmRemove = (id: number) => {
    if (!canManage) {
      return;
    }

    confirmDialog({
      message: t('areYouSureProceed'),
      header: t('areYouSure'),
      defaultFocus: 'accept',
      accept: () => remove(id),
    });
  };

  const confirmDuplicate = (id: number) => {
    confirmDialog({
      message: t('areYouSureProceed'),
      header: t('areYouSure'),
      defaultFocus: 'accept',
      accept: () => duplicate(id),
    });
  };

  const editName = (id: number) => {
    if (!projectResponse) {
      return;
    }

    const currentProject = projectResponse.find((project) => project.id === id);
    confirmDialog({
      message: (
        <ChangeName
          selectedProject={currentProject?.firmName || ''}
          onChangeProjectName={(projectName) => (newProjectName = projectName)}
          onChangeProjectMode={(projectMode) => (projectChangeMode = projectMode)}
          isSaveDisabled={(disabled) => setSaveDisabled(disabled)}
        />
      ),
      header: t('changeProjectName'),
      defaultFocus: 'accept',
      rejectLabel: t('cancel'),
      acceptLabel: t('save'),
      acceptClassName: isSaveDisabled
        ? 'confirm-change-name-btn btn--disabled'
        : 'confirm-change-name-btn',
      accept: () => changeProjectNameEvent(id),
      onHide: () => resetValues(),
    });
  };

  const remove = (id: number) => {
    if (!canManage) {
      refreshProjects();
      return;
    }

    setCanFetch(false);
    removeProject(id)
      .then(() => refreshProjects())
      .catch(() => refreshProjects());
  };

  const duplicate = (id: number) => {
    if (!canManage) {
      refreshProjects();
      return;
    }

    setLoader(true);
    setCanFetch(false);
    duplicateProject(id)
      .then(() => refreshProjects())
      .catch(() => refreshProjects());
  };

  const refreshProjects = () => {
    setCanFetch(true);
    setProjectResponse(null);
    resetValues();
    setLoader(false);
  };

  const resetValues = () => {
    newProjectName = '';
    projectChangeMode = 'ALL';
  };

  const changeProjectNameEvent = (id: number) => {
    const currentProject = projectResponse?.find((project) => project.id === id);
    const oldProjectName = currentProject?.firmName || '';

    if (
      !id ||
      !newProjectName ||
      !projectChangeMode ||
      oldProjectName.toLowerCase().trim() === newProjectName.toLowerCase().trim() ||
      newProjectName.trim() === ''
    ) {
      return;
    }

    setCanFetch(false);
    const data = {
      projectId: id,
      projectName: newProjectName,
      mode: projectChangeMode,
    } as ChangeProjectNameData;

    changeProjectName(data)
      .then(() => refreshProjects())
      .catch(() => refreshProjects());
  };

  if (!projectResponse || !accountResponse || loader) {
    return <Loader />;
  }

  if (projectResponse.length <= 0) {
    navigate(PROJECTS_ADD);
    return <></>;
  }

  const button = () => {
    return (
      <>
        <Button
          className={`btn btn--sm ${!canManage ? 'btn--disabled-tooltip-color' : ''}`}
          onClick={() => {
            if (canManage) {
              navigate(PROJECTS_ADD);
            }
          }}
          label={t('addNewProject')}
          id="add-prompt"
        />
        {!canManage && <LimitTooltipComponent id="add-prompt" direction="bottom" />}
      </>
    );
  };

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const emptyTemplate = () => {
    return (
      <div className="no-results">
        <div className="no-results__icon material-symbols-outlined">search</div>
        <div className="no-results__title">{t('noResultsFound')}</div>
        <div className="no-results__desc">{t('noResultsFoundDesc')}</div>
      </div>
    );
  };

  const projectNameBodyTemplate = (project: ProjectResponse) => {
    return (
      <div className="project-name">
        <NavLink to={`${PROJECTS}/${project.id}/daily/gpt`}>{project.firmName}</NavLink>
        {accountResponse.isSuperAdmin && (
          <div className="project-name__additional">
            isSuperAdminProject:
            {project.accountId === 1 ? (
              <span className="project-name__ok">true</span>
            ) : (
              <span>false</span>
            )}
            | accountId: {project.accountId} | ownerName: {project.additionalInfo?.ownerName}
          </div>
        )}
      </div>
    );
  };

  const statsBodyTemplate = (project: ProjectResponse) => {
    return (
      <>
        <span className="tag" onClick={() => navigate(`${PROJECTS}/${project.id}/daily/gpt`)}>
          {t('gptName')}
        </span>
        <span className="tag" onClick={() => navigate(`${PROJECTS}/${project.id}/daily/gemini`)}>
          {t('geminiName')}
        </span>
        {project.id === 1 && (
          <>
            <span className="tag" onClick={() => navigate(`${PROJECTS}/${project.id}/daily/claude`)}>
              {t('claudeName')}
            </span>
            <span className="tag" onClick={() => navigate(`${PROJECTS}/${project.id}/daily/deep_seek`)}>
              {t('deepSeekName')}
            </span>
          </>
        )}
      </>
    );
  };

  const actionBodyTemplate = (project: ProjectResponse) => {
    return (
      <>
        {accountResponse.isSuperAdmin && (
          <Button
            className="action-btn material-symbols-outlined"
            onClick={() => confirmDuplicate(project.id)}
          >
            folder_copy
          </Button>
        )}
        <Button className="action-btn material-symbols-outlined" onClick={() => editName(project.id)}>
          edit
        </Button>
        <Button
          className={`action-btn material-symbols-outlined ${!canManage ? 'btn--disabled-tooltip' : ''}`}
          onClick={() => confirmRemove(project.id)}
          id="btn-delete-project"
        >
          delete
        </Button>
        {!canManage && <LimitTooltipComponent id="btn-delete-project" direction="left" />}
      </>
    );
  };

  const renderSearch = () => {
    return (
      <div className="page-box__buttons">
        <div className="input-search-box">
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search..."
            className="input-search"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <Title mainCategory={t('projects')} children={button()} btnLabel={t('addNewProject')} />
      <div className="project-page page-content">
        <ConfirmDialog className="dialog-box" />

        {projectResponse.length > 0 ? (
          <div className="page-box" key="page-box-project">
            <div className="page-box__title-action">
              <div className="page-box__title">{t('activeProjects')}</div>
              {renderSearch()}
            </div>
            <DataTable
              stripedRows
              paginator
              paginatorClassName="pagination"
              removableSort
              rows={50}
              rowsPerPageOptions={[10, 25, 50]}
              value={projectResponse}
              tableStyle={{ minWidth: '50rem' }}
              tableClassName="table"
              className="table-wrapper"
              filters={filters}
              emptyMessage={emptyTemplate}
            >
              <Column
                sortable
                field="firmName"
                header={t('projectName')}
                body={projectNameBodyTemplate}
                headerClassName="table__header"
              />
              <Column
                header={t('stats')}
                body={statsBodyTemplate}
                headerClassName="table__header table__header--center"
                bodyClassName="table__cell--center table__cell--gap"
                style={{ width: '220px' }}
              />
              <Column
                field="created"
                header={t('created')}
                body={(data) => format(data.created, 'yyyy-MM-dd HH:mm:ss')}
                headerClassName="table__header table__header--center"
                bodyClassName="table__cell--center table__cell--bold"
                style={{ width: '220px' }}
              />

              <Column
                header={t('action')}
                body={actionBodyTemplate}
                headerClassName="table__header table__header--center"
                bodyClassName="table__cell--flex-end table__cell--gap"
                align={'center'}
                style={accountResponse.isSuperAdmin ? { width: '120px' } : { width: '100px' }}
              />
            </DataTable>
          </div>
        ) : (
          <>Add new project</>
        )}
      </div>
    </>
  );
};

export default ProjectsPage;
