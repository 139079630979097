import React, { useEffect, useState } from 'react';
import './TeamPage.scss';
import Loader from '../../../shared/components/common/loader/Loader';
import { getTeam } from './services/TeamService';
import { TeamResponse } from './models/TeamResponse';
import { getInitials } from '../../../shared/utils/utils';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TeamPage: React.FC = () => {
  const { t } = useTranslation();
  const [team, setTeam] = useState<TeamResponse[] | null>(null);
  useEffect(() => {
    getTeam().then((data) => setTeam(data));
  }, []);

  if (!team) {
    return <Loader />;
  }

  return (
    <div className="team-page">
      <NavLink to="/settings/team/add" className="user user--new">
        {t('inviteUser')}
      </NavLink>
      {team &&
        team.map((data, index) => (
          <NavLink
            to={`/settings/team/${data.id}`}
            className={data.isActive ? 'user' : 'user user--inactive'}
            state={data}
            key={`user-${index}`}
          >
            <div>{getInitials(data.firstName + ' ' + data.lastName)}</div>
            {['OWNER', 'ADMIN'].includes(data.role) && (
              <div className="user__type">{data.role === 'ADMIN' ? 'A' : 'O'}</div>
            )}
          </NavLink>
        ))}
    </div>
  );
};

export default TeamPage;
