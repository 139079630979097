export const FORGOT = '/forgot';
export const LOGIN = '/login';
export const SIGNUP = '/signup';
export const SET_PASSWORD = '/set-password/:hash';
export const QUESTIONS = '/questions';
export const DAILY = '/daily';
export const ADD = '/add';
export const QUEST = '/guest';
export const FAQ = '/faq';
export const QUEST_FAQ = `${QUEST}${FAQ}`;
export const PROJECTS = '/projects';
export const PROJECTS_PREVIEW = `${PROJECTS}/:id`;
export const PROJECTS_ADD = `${PROJECTS}${ADD}`;
export const QUESTIONS_ID = `${QUESTIONS}/:id`;
export const INTEGRATIONS = '/integrations';
export const DASHBOARD = '/dashboard';
export const SETTINGS = '/settings';
export const MY_ACCOUNT = `${SETTINGS}/my-account`;
export const CHANGE_PASSWORD = `${SETTINGS}/change-password`;
export const BILLING_DATA = `${SETTINGS}/billing-data`;
export const SUBSCRIPTION = `${SETTINGS}/subscription`;
export const ORDERS = `${SETTINGS}/orders`;
export const TEAM = `${SETTINGS}/team`;
export const TEAM_ADD = `${TEAM}${ADD}`;
export const TEAM_PREVIEW = `${TEAM}/:id`;
export const ADMIN = '/admin';
export const USERS = '/users';
export const EDIT = '/edit';
