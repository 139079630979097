import { TokenData } from '../../models/auth/TokenData';
import { client } from '../apiClient';

export const TOKEN_KEY = 'token';

export const saveTokenData = (token: TokenData): void => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
};

export const updateTokenData = (firstName: string, lastName: string): void => {
  const tokenString = localStorage.getItem(TOKEN_KEY);

  if (tokenString) {
    const tokenData = JSON.parse(tokenString) as TokenData;
    const user = tokenData.user;
    user.firstName = firstName;
    user.lastName = lastName;
    tokenData.user = user;
    saveTokenData(tokenData);
  }
};

export const getTokenData = (): TokenData | null => {
  const tokenString = localStorage.getItem(TOKEN_KEY);

  if (tokenString) {
    const tokenData = JSON.parse(tokenString) as TokenData;
    tokenData.accessTokenExpiresAt = new Date(tokenData.accessTokenExpiresAt);
    return tokenData;
  } else {
    return null;
  }
};

export const removeTokenData = (): void => {
  localStorage.removeItem(TOKEN_KEY);
};

export const isTokenValid = (tokenData: TokenData): boolean =>
  tokenData.accessTokenExpiresAt.getTime() > Date.now();

export const refreshAccessToken = async (): Promise<void> => {
  try {
    const tokenDataFromStorage = getTokenData();
    if (!tokenDataFromStorage) {
      throw Error('Incorrect refresh token');
    }

    const data = new URLSearchParams();
    data.append('grant_type', 'refresh_token');
    data.append('client_id', process.env.REACT_APP_CLIENT_ID || '');
    data.append('refresh_token', tokenDataFromStorage.refreshToken);

    const response = await client(false, false).post('/auth/refresh', data.toString());

    const tokenData = response.data as TokenData;
    saveTokenData(tokenData);
  } catch (error) {
    throw Error('Unable to refresh token');
  }
};
