import { ChatType } from '../types/monitor/ChatType';

export const monitorTypeOptions = (
  t: (key: string) => string,
  includeClaude: boolean
): { name: string; type: ChatType }[] => {
  const options: { name: string; type: ChatType }[] = [
    { name: t('all'), type: 'ALL' },
    { name: t('gptName'), type: 'GPT' },
    { name: t('geminiName'), type: 'GEMINI' },
  ];

  if (includeClaude) {
    options.push({ name: t('claudeApi'), type: 'CLAUDE' });
    options.push({ name: t('deepSeekName'), type: 'DEEP_SEEK' });
  }

  return options;
};

export const chatTypeOptions = (
  t: (key: string) => string,
  includeClaude: boolean
): { name: string; type: ChatType }[] => {
  const options: { name: string; type: ChatType }[] = [
    { name: t('gptName'), type: 'GPT' },
    { name: t('geminiName'), type: 'GEMINI' },
  ];

  if (includeClaude) {
    options.push({ name: t('claudeName'), type: 'CLAUDE' });
    options.push({ name: t('deepSeekName'), type: 'DEEP_SEEK' });
  }

  return options;
};
