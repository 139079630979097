import { client } from '../../../../shared/services/apiClient';

export const addNewPrompt = async (projectId: number, data: any): Promise<any[]> => {
  return client(true, true)
    .put<any[]>(`/monitor/prompt/add/${projectId}`, data)
    .then((response: any) => response?.data)
    .catch((error: any) => error);
};

export const addNewSimplePrompt = async (keyword: string, data: any): Promise<any[]> => {
  return client(true, true)
    .put<any[]>(`/monitor/prompt/add-simple/${keyword}`, data)
    .then((response: any) => response?.data)
    .catch((error: any) => error);
};
