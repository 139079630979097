import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';
import { AccountResponse } from '../../models/account/AccountResponse';
import { ProjectResponse } from '../../models/projects/ProjectResponse';

interface AccountContextType {
  accountResponse: AccountResponse | null;
  setAccountResponse: Dispatch<SetStateAction<AccountResponse | null>>;
  projectResponse: ProjectResponse[] | null;
  setProjectResponse: Dispatch<SetStateAction<ProjectResponse[] | null>>;
  canFetch: boolean;
  setCanFetch: Dispatch<SetStateAction<boolean | true>>;
}

export const AccountContext = createContext<AccountContextType | undefined>(undefined);

export const AccountProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [canFetch, setCanFetch] = useState<boolean>(true);
  const [accountResponse, setAccountResponse] = useState<AccountResponse | null>(null);
  const [projectResponse, setProjectResponse] = useState<ProjectResponse[] | null>(null);

  return (
    <AccountContext.Provider
      value={{
        canFetch,
        setCanFetch,
        accountResponse,
        setAccountResponse,
        projectResponse,
        setProjectResponse,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  const context = useContext(AccountContext);
  if (!context) {
    throw Error('useAccount must be used with an AccountProvider');
  }

  return context;
};
