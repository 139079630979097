import React, { useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Link, useNavigate } from 'react-router-dom';
import './SignupPage.scss';
import { signUp } from './services/SignupService';
import { UserData } from './models/UserData';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../shared/contexts/auth/AuthContext';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { LOGIN, PROJECTS } from '../../shared/routes/path';

const SignupPage: React.FC = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { login } = useAuth();
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [apiError, setApiError] = useState<string | null>(null);

  const onSubmit = async (formData: any) => {
    if (!recaptchaRef.current) {
      setApiError('Please turn on the reCAPTCHA');
      return;
    }

    const token =
      process.env.REACT_APP_IS_DEBUG !== 'true' ? await recaptchaRef.current?.executeAsync() : '';
    const userData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      username: formData.email,
      password: formData.password,
      language: i18n.language,
      client_id: process.env.REACT_APP_CLIENT_ID || '',
      grant_type: 'password',
      browserData: JSON.stringify(navigator.userAgent),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      recaptchaToken: token,
    };

    const urlEncodedData = new URLSearchParams();
    for (const key in userData) {
      if (userData.hasOwnProperty(key)) {
        urlEncodedData.append(key, userData[key as keyof UserData]);
      }
    }

    await signUp(urlEncodedData)
      .then((response) => {
        login(response.data);
        navigate(PROJECTS);
      })
      .catch((err) => {
        if (err.statusCode === 401) {
          navigate(LOGIN);
          return;
        }

        setApiError(err.response.data.error);
      });
  };

  const clearApiError = () => {
    setApiError(null);
  };

  return (
    <div className="signup-page">
      <div className="signup-page__left">
        <div className="signup-page__title">{t('signupPageTitle')}</div>
        <div className="signup-page__subtitle">{t('signupPageDesc')}</div>
      </div>
      <div className="signup-page__right">
        <div className="logo-box" />
        <div className="form-box">
          <div className="form-box__title">{t('crateAnAccount')}:</div>
          <form onSubmit={handleSubmit(onSubmit)} className="form-box__form">
            <div className="form-box__row form-box__row--with-col">
              <div className="form-box__col">
                <div className="form-box__label">{t('firstName')}:</div>
                <InputText
                  onInput={clearApiError}
                  {...register('firstName', { required: t('fieldIsRequired') })}
                  className={errors.firstName || apiError ? 'form-box__invalid' : ''}
                />
                {errors.firstName && <span>{errors.firstName.message as string}</span>}
              </div>
              <div className="form-box__col">
                <div className="form-box__label">{t('lastName')}:</div>
                <InputText
                  onInput={clearApiError}
                  {...register('lastName', { required: t('fieldIsRequired') })}
                  className={errors.lastName || apiError ? 'form-box__invalid' : ''}
                />
                {errors.lastName && <span>{errors.lastName.message as string}</span>}
              </div>
            </div>

            <div className="form-box__row">
              <div className="form-box__label">{t('emailAddress')}:</div>
              <InputText
                onInput={clearApiError}
                {...register('email', {
                  required: t('fieldIsRequired'),
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: t('wrongEmailAddress'),
                  },
                })}
                className={errors.email || apiError ? 'form-box__invalid' : ''}
              />
              {errors.email && <span>{errors.email.message as string}</span>}
            </div>

            <div className="form-box__row">
              <div className="form-box__label">{t('password')}:</div>
              <InputText
                onInput={clearApiError}
                type="password"
                {...register('password', { required: t('fieldIsRequired') })}
                className={errors.password || apiError ? 'form-box__invalid' : ''}
              />
              {errors.password && <span>{errors.password.message as string}</span>}
              {apiError && <span>{apiError}</span>}
            </div>

            <Button className="btn" label={t('signUp')} type="submit" />
            <div className="form-box__row form-box__row--info">
              {t('alreadyHaveAccount')}? <Link to="/login">{t('signIn')}</Link>
            </div>
          </form>
        </div>
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || 'sample'}
        size="invisible"
      />
    </div>
  );
};

export default SignupPage;
