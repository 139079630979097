import React, { useEffect, useState } from 'react';
import './MyAccountPage.scss';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useForm } from 'react-hook-form';
import Loader from '../../../shared/components/common/loader/Loader';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useAccount } from '../../../shared/contexts/account/AccountContexts';
import { useTranslation } from 'react-i18next';
import { SimpleUserDataResponse } from '../../../shared/models/auth/SimpleUserDataResponse';
import { getAccount } from '../../../shared/services/account/AccountService';
import { saveMyAccountData } from './services/MyAccountService';
import { Dropdown } from 'primereact/dropdown';

const MyAccountPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { accountResponse, setAccountResponse } = useAccount();
  const [selectedLanguage, setSelectedLanguage] = useState<{ name: string; type: string } | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const langOptions = [
    { name: t('polish', { lng: 'pl' }), type: 'pl' },
    { name: t('english', { lng: 'en' }), type: 'en' },
  ];

  useEffect(() => {
    const loadAccount = async () => {
      const updatedAccountResponse = await getAccount();
      setAccountResponse(updatedAccountResponse);

      const userLang = updatedAccountResponse?.language ?? 'en';
      const currentLang = langOptions.find((lang) => lang.type === userLang);
      setSelectedLanguage(currentLang || langOptions[1]);

      setIsLoading(false);
    };

    loadAccount();
  }, [setAccountResponse]);

  const onSubmit = async (formData: any) => {
    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: accountResponse?.email || '',
      language: selectedLanguage?.type ?? 'en',
    } as SimpleUserDataResponse;

    await saveMyAccountData(data);
    accountDataChangedDialog();
  };

  const accountDataChangedDialog = () => {
    confirmDialog({
      message: t('yourDataChanged'),
      header: t('dataChanged'),
      defaultFocus: 'accept',
      acceptLabel: t('ok'),
      accept: () => {
        i18n.changeLanguage(selectedLanguage?.type).then(() => window.location.reload());
      },
    });
  };

  if (isLoading || !selectedLanguage) {
    return <Loader />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="my-account-page">
      <ConfirmDialog className="dialog-box dialog-box--accept" />

      <div className="my-account-page__row">
        <div className="form">
          <div className="form__row">
            <div className="form__label">{t('firstName')}:</div>
            <InputText
              {...register('firstName', { required: t('firstNameRequired') })}
              type="text"
              defaultValue={accountResponse?.firstName}
              className={errors.firstName ? 'form__invalid' : ''}
            />
            {errors.firstName && <span>{errors.firstName.message as string}</span>}
          </div>
          <div className="form__row">
            <div className="form__label">{t('lastName')}:</div>
            <InputText
              {...register('lastName', { required: t('lastNameRequired') })}
              type="text"
              defaultValue={accountResponse?.lastName}
              className={errors.lastName ? 'form__invalid' : ''}
            />
            {errors.lastName && <span>{errors.lastName.message as string}</span>}
          </div>
          <div className="form__row">
            <div className="form__label">{t('emailAddress')}:</div>
            <InputText type="text" value={accountResponse?.email} disabled={true} />
          </div>
          <div className="form__row">
            <div className="form__label">{t('language')}:</div>
            <Dropdown
              value={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.value)}
              options={langOptions}
              className="dropdown"
              optionLabel="name"
            />
          </div>

          <Button className="btn btn--sm" label={t('save')} />
        </div>
        <div className="my-account-page__help">
          <div className="help-box">
            <label className="help-box__title">{t('changeYourName')}</label>
            <div>{t('changeNameMsg')}</div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default MyAccountPage;
