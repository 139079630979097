import { PeriodType } from '../types/prompt/PeriodType';
import { MonitorType } from '../types/monitor/MonitorType';
import { ChatType, projectChatType } from '../types/monitor/ChatType';

export const getPeriod = (period?: string | ''): PeriodType => {
  if (period?.toUpperCase() === 'WEEKLY') {
    return 'WEEKLY';
  } else if (period?.toUpperCase() === 'MONTHLY') {
    return 'MONTHLY';
  } else {
    return 'DAILY';
  }
};

export const getPeriodUrl = (periodType: PeriodType): string => {
  switch (periodType) {
    case 'DAILY':
    case 'WEEKLY':
    case 'MONTHLY':
      return periodType.toLowerCase();

    default:
      return 'DAILY'.toLowerCase();
  }
};

export const getChatUrl = (defaultValue: string, chatType: ChatType): string => {
  switch (chatType) {
    case 'GPT':
    case 'CLAUDE':
    case 'GEMINI':
    case 'DEEP_SEEK':
      return chatType.toLowerCase();

    default:
      return 'GPT'.toLowerCase();
  }
};

export const prepareAndGetChatType = (defaultValue: ChatType, chat?: string): ChatType => {
  if (!chat) {
    return defaultValue;
  }

  const chatType = chat.toUpperCase();
  if (projectChatType.includes(chatType)) {
    if (chatType === 'GEMINI') {
      return 'GEMINI';
    } else if (chatType === 'GPT') {
      return 'GPT';
    } else if (chatType === 'CLAUDE') {
      return 'CLAUDE';
    } else if (chatType === 'DEEP_SEEK') {
      return 'DEEP_SEEK';
    }
  }

  return defaultValue;
};

export const getMonitorTypeByChatOptionsType = (chatType: ChatType): MonitorType => {
  switch (chatType) {
    case 'GPT':
      return 'GPT';

    case 'CLAUDE':
      return 'CLAUDE_API';

    case 'GEMINI':
      return 'GEMINI_API';

    case 'DEEP_SEEK':
      return 'DEEP_SEEK_API';

    default:
      return 'ALL';
  }
};

export const getStatsUrl = (monitorType: MonitorType | null, periodType: PeriodType | null) => {
  const firstPart = monitorType === 'GPT_API' ? 'api' : 'browser';
  return `${firstPart}/${periodType?.toLowerCase()}`;
};
