import { MonitorType } from '../../../types/monitor/MonitorType';
import { AdminMonitorStatsResponse } from '../../../models/admin/dashboard/AdminMonitorStatsResponse';
import { client } from '../../apiClient';
import { AdminConfigurationResponse } from '../../../models/admin/dashboard/AdminConfigurationResponse';

export const getConfiguration = async (): Promise<AdminConfigurationResponse[]> => {
  return client(true, true)
    .get<AdminConfigurationResponse[]>(`/admin/dashboard/configuration`)
    .then((response) => response.data)
    .catch((error) => error);
};

export const getActiveMonitorStats = async (): Promise<number> => {
  return client(true, true)
    .get<number>(`/admin/dashboard/monitors`)
    .then((response) => response.data)
    .catch((error) => error);
};

export const getActiveUsersStats = async (): Promise<number> => {
  return client(true, true)
    .get<number>(`/admin/dashboard/users`)
    .then((response) => response.data)
    .catch((error) => error);
};

export const getDailyRequestStats = async (): Promise<AdminMonitorStatsResponse[]> => {
  return client(true, true)
    .get<AdminMonitorStatsResponse[]>(`/admin/dashboard/`)
    .then((response) => response.data)
    .catch((error) => error);
};
