import useCanManage from '../../../hooks/account/UseCanManage';
import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';

const LimitTooltipComponent = ({ id, direction }: { id: string; direction: any | 'top' }) => {
  const { t } = useTranslation();
  const canManage = useCanManage();

  if (canManage) {
    return <></>;
  }

  return (
    <Tooltip
      target={`#${id}`}
      className={`tooltip--${direction}`}
      position={direction}
      style={{ width: '220px' }}
      autoHide={false}
      hideDelay={100}
    >
      <div dangerouslySetInnerHTML={{ __html: t('reachedLimit') }} />
    </Tooltip>
  );
};

export default LimitTooltipComponent;
