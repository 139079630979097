import { client } from '../../../../shared/services/apiClient';
import { TeamResponse, UserTeamData } from '../models/TeamResponse';

export const getTeam = async (): Promise<TeamResponse[]> => {
  return client(true, true)
    .get<TeamResponse[]>('/team/data')
    .then((response) => response.data)
    .catch((error) => error);
};

export const removeUser = async (userId: number): Promise<boolean> => {
  return client(true, true)
    .delete<TeamResponse[]>(`/team/data/${userId}`)
    .then((response) => response.data)
    .catch((error) => error);
};

export const inviteUser = async (userTeamData: UserTeamData): Promise<boolean> => {
  return client(true, true)
    .post<TeamResponse[]>(`/team/data/add`, userTeamData)
    .then((response) => response.data)
    .catch((error) => error);
};
