import axios from 'axios';
import { getTokenData, refreshAccessToken, removeTokenData } from './auth/AuthService';

export const client = (isJSONType: boolean, isAPI: boolean) => {
  const baseURL = `${process.env.REACT_APP_BACKEND_URL}${isAPI ? '/api' : ''}`;
  const contentType = isJSONType ? 'application/json' : 'application/x-www-form-urlencoded';

  let headers = {
    'Content-Type': contentType,
  };

  if (isAPI) {
    const tokenData = getTokenData();
    headers = {
      ...headers,
      ...(tokenData ? { Authorization: `Bearer ${tokenData.accessToken}` } : {}),
    };
  }

  const instance = axios.create({
    baseURL,
    headers,
  });

  if (isAPI) {
    instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            await refreshAccessToken();
            const newTokenData = getTokenData();
            if (newTokenData) {
              originalRequest.headers['Authorization'] = `Bearer ${newTokenData.accessToken}`;
              return instance(originalRequest);
            }
          } catch (err) {
            removeTokenData();
            window.location.href = '/app/login';
          }
        }
        return Promise.reject(error);
      }
    );
  }

  return instance;
};
