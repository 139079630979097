import './AddPromptPage.scss';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { addNewPrompt, addNewSimplePrompt } from './services/AddPromptService';
import Title from '../../../shared/components/title/Title';
import { useAccount } from '../../../shared/contexts/account/AccountContexts';
import Loader from '../../../shared/components/common/loader/Loader';
import { useTranslation } from 'react-i18next';
import { PROJECTS, QUESTIONS } from '../../../shared/routes/path';
import { InputText } from 'primereact/inputtext';

const AddPromptPage: React.FC = () => {
  let { projectId } = useParams();
  const { t } = useTranslation();
  const { accountResponse, projectResponse } = useAccount();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const onSubmit = async (formData: any) => {
    setLoading(true);
    const { keyword, ...formDataWithoutKeyword } = formData;
    const data = {
      ...formDataWithoutKeyword,
      gptAPI: true,
      gptBrowser: true,
      status: 'PENDING',
    };

    const projectIdNumber = Number(projectId);
    const backLink = getBackLink();
    if (!isNaN(projectIdNumber)) {
      await addNewPrompt(projectIdNumber, data)
        .then(() => navigate(backLink))
        .catch(() => {
          setLoading(false);
          console.log('error');
        });
    } else {
      await addNewSimplePrompt(formData.keyword, data)
        .then(() => navigate(backLink))
        .catch(() => {
          setLoading(false);
          console.log('error');
        });
    }
  };

  useEffect(() => {
    if (!accountResponse) {
      return;
    }

    if (
      !['PAID', 'TRIAL'].includes(accountResponse.accountType) ||
      (accountResponse.accountType === 'TRIAL' &&
        accountResponse.createdMonitorCount >= accountResponse.maxMonitorCount)
    ) {
      navigate(getBackLink());
    } else {
      setLoading(false);
    }
  }, [accountResponse]);

  const getProjectName = (): string | null => {
    const project = projectResponse?.filter((project) => project.id === Number(projectId));
    if (!project || project.length !== 1) {
      return null;
    }

    return project[0].firmName;
  };

  const getBackLink = (): string => {
    return getProjectName() ? `${PROJECTS}/${projectId}` : `${QUESTIONS}`;
  };

  if (!accountResponse || loading) {
    return <Loader />;
  }

  return (
    <>
      <Title
        mainCategory={getProjectName() ?? t('questions')}
        subCategory={t('addNewQuestion')}
        link={getBackLink()}
      />
      <div className="add-prompt-page page-content">
        <div className="page-box">
          <div className="page-box__title-action">
            <div className="page-box__title">{t('addNewQuestion')}</div>
          </div>
          <div>
            <div className="add-prompt-page__panel">
              <form onSubmit={handleSubmit(onSubmit)} className="form">
                {!projectId && (
                  <div className="form__row">
                    <div className="form__label">{t('keyword')}:</div>
                    <InputText
                      {...register('keyword', { required: 'keyword is required' })}
                      className={errors.keyword ? 'form__invalid' : ''}
                    />
                    {errors.keyword && <span>{errors.keyword.message as string}</span>}
                  </div>
                )}
                <div className="form__row">
                  <div className="form__label">{t('prompt')}:</div>
                  <InputTextarea
                    {...register('prompt', { required: 'Prompt is required' })}
                    className={errors.prompt ? 'form__invalid' : ''}
                  />
                  {errors.prompt && <span>{errors.prompt.message as string}</span>}
                </div>
                <Button className="btn btn--sm" label={t('save')} disabled={loading} />
              </form>
              <div className="add-prompt-page__help">
                {/*<div className="help-box">*/}
                {/*  <label className="help-box__title">{t('addNewProjectHelp')}</label>*/}
                {/*  <div>{t('addNewProjectHelpDesc')}</div>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPromptPage;
