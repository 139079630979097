import React, { useEffect, useState } from 'react';
import './SecondStep.scss';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createProject } from '../../services/ProjectWizardService';
import { QUESTIONS } from '../../../../../shared/routes/path';
import { useAccount } from '../../../../../shared/contexts/account/AccountContexts';
import { useProjectWizard } from '../../contexts/ProjectWizardContext';
import { ProjectData } from '../../models/ProjectData';
import { ProgressBar } from 'primereact/progressbar';

interface CustomQuestion {
  text: string;
  checked: boolean;
}
const SecondStep: React.FC = () => {
  const [apiError, setApiError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { accountResponse, setProjectResponse } = useAccount();
  const { activeStep, questionResponse, domain } = useProjectWizard();
  const { t } = useTranslation();

  const [brand, setBrand] = useState<string | null>(null);
  const [questions, setQuestions] = useState<string[]>([]);
  const [customQuestions, setCustomQuestions] = useState<CustomQuestion[]>([]);
  const [ingredients, setIngredients] = useState<string[]>([]);
  const [isActiveSaveButton, setActiveSaveButton] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [loaderProgress, setLoaderProgress] = useState<number>(0);

  useEffect(() => {
    setQuestions(questionResponse?.questions || []);
    setIngredients(questionResponse?.questions || []);
    setBrand(questionResponse?.brand || null);
  }, [questionResponse]);

  const onIngredientsChange = (e: CheckboxChangeEvent) => {
    setApiError(null);
    let _ingredients = [...ingredients];

    if (e.checked) {
      _ingredients.push(e.value);
    } else {
      _ingredients.splice(_ingredients.indexOf(e.value), 1);
    }
    setIngredients(_ingredients);
  };

  const onCustomQuestionChange = (index: number, checked: boolean) => {
    setApiError(null);
    const updatedQuestions = [...customQuestions];
    updatedQuestions[index].checked = checked;
    setCustomQuestions(updatedQuestions);
  };

  const onCustomQuestionTextChange = (index: number, text: string) => {
    const updatedQuestions = [...customQuestions];
    updatedQuestions[index].text = text;
    setCustomQuestions(updatedQuestions);
  };

  const createAndGetProjectData = (): ProjectData => {
    const checkedCustomQuestions = customQuestions
      .filter((data) => data.checked && data.text.trim() !== '')
      .map((data) => data.text);

    return {
      firmName: brand,
      questions: [...checkedCustomQuestions, ...ingredients],
      domain,
    } as ProjectData;
  };

  const save = () => {
    setActiveSaveButton(false);

    if (activeStep !== 2) {
      setActiveSaveButton(true);
      return;
    }
    const projectData = createAndGetProjectData();
    if (projectData.questions.length <= 0) {
      setApiError(t('selectMinimumOne'));
      setActiveSaveButton(true);
      return;
    }

    createProject(createAndGetProjectData())
      .then((projects) => {
        if (!projects || projects.length === 0) {
          throw new Error();
        }

        setProjectResponse(projects);
        // setLoader(true);
        navigate(QUESTIONS);
      })
      .catch((err) => setApiError(err.message))
      .finally(() => setActiveSaveButton(true));
  };

  const addCustomQuestion = () => {
    setApiError(null);
    if (!accountResponse) {
      return;
    }

    if (
      (accountResponse.accountType === 'TRIAL' &&
        customQuestions.length < accountResponse.maxMonitorCount) ||
      accountResponse.accountType === 'PAID'
    ) {
      setCustomQuestions([...customQuestions, { text: '', checked: true }]);
    }
  };

  if (loader) {
    return (
      <div className="project-wizard__loader">
        <div>{t('loadingDataPleaseWait')}</div>
        <ProgressBar value={20} />
      </div>
    );
  }

  return (
    <div className="project-wizard__page-box">
      <div className="project-wizard__form">
        <div className="form-box__row">
          <h2>{t('addCustomQuestions')}:</h2>
          <ul>
            {questions.map((question, index) => (
              <li key={`question-li-${index}`}>
                <Checkbox
                  inputId={`question-${index}`}
                  onChange={onIngredientsChange}
                  value={question}
                  checked={ingredients.includes(question)}
                />
                <label htmlFor={`question-${index}`}>{question}</label>
              </li>
            ))}
            {customQuestions.map((customQuestion, index) => (
              <li key={`custom-question-li-${index}`}>
                <Checkbox
                  inputId={`custom-question-${index}`}
                  checked={customQuestion.checked}
                  onChange={(e) => onCustomQuestionChange(index, e.checked || false)}
                />
                <InputText
                  placeholder={`Custom Question ${index + 1}`}
                  value={customQuestion.text}
                  onChange={(e) => onCustomQuestionTextChange(index, e.target.value)}
                />
              </li>
            ))}
          </ul>
          <span>{apiError}</span>
        </div>
      </div>
      <div className="project-wizard__action">
        <Button
          onClick={() => save()}
          type="submit"
          className="btn btn--sm"
          disabled={!isActiveSaveButton}
        >
          {t('saveAndLaunch')}
        </Button>

        <Button
          onClick={() => addCustomQuestion()}
          type="button"
          className="btn btn--link btn--link-blue "
        >
          {t('addCustomQuestions')}
        </Button>
      </div>
    </div>
  );
};

export default SecondStep;
