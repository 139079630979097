import React from 'react';
import './FilterBox.scss';
import { Link } from 'react-router-dom';
import { FilterProps } from './models/FilterBoxModel';

const FilterBox: React.FC<FilterProps> = ({ additionalClass, items }) => {
  return (
    <div className={`filter-box ${additionalClass ?? ''}`}>
      {items &&
        items.map((data, index) => (
          <Link
            to={data.url}
            className={`filter-box__item ${data.isActive && 'filter-box__item--active'} `}
            key={`filter-box-${data.label}-${index}`}
          >
            {data.label}
          </Link>
        ))}
    </div>
  );
};

export default FilterBox;
