import './PreviewPromptPage.scss';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Loader from '../../../shared/components/common/loader/Loader';
import { getMonitorData } from '../../../shared/services/monitor/MonitorService';
import { MonitorDataResponse } from '../../../shared/models/monitor/MonitorDataResponse';
import { FirmData } from '../../../shared/models/monitor/FirmData';
import Title from '../../../shared/components/title/Title';
import { useStats } from '../../../shared/contexts/StatsContexts';
import {
  getMonitorTypeByChatOptionsType,
  getPeriod,
  prepareAndGetChatType,
} from '../../../shared/helpers/StatsHelpers';
import FilterBox from '../../../shared/components/filter-box/FilterBox';
import { FilterItem } from '../../../shared/components/filter-box/models/FilterBoxModel';
import { Calendar } from 'primereact/calendar';
import { Tooltip } from 'primereact/tooltip';
import { useTranslation } from 'react-i18next';
import { PROJECTS, QUESTIONS } from '../../../shared/routes/path';
import Stats from '../../../shared/components/stats/Stats';
import { PromptResponse } from '../../../shared/models/monitor/PromptResponse';
import {
  getKeywordsData,
  updateKeywords,
} from '../../../shared/services/monitor/keywords/KeywordsService';
import { getPromptData } from '../../../shared/services/monitor/prompt/PromptService';
import { KeywordsDailyStatsResponse } from '../../../shared/models/monitor/keywords/stats/KeywordsDailyStatsResponse';
import { Dropdown } from 'primereact/dropdown';
import { monitorTypeOptions } from '../../../shared/helpers/MonitorTypeHelpers';
import { getKeywordsDailyStats } from '../../../shared/services/monitor/keywords/stats/KeywordsStatsService';

interface PreviewPromptPageProps {
  force: boolean;
}

const PreviewPromptPage: React.FC<PreviewPromptPageProps> = ({ force }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  let { projectId, id, type, period, chat } = useParams();
  const {
    monitorPeriod,
    setMonitorPeriod,
    range,
    setRange,
    setSelectedFirm,
    selectedFirm,
    selectedChatType,
    setSelectedChatType,
  } = useStats();
  const [promptData, setPromptData] = useState<PromptResponse>();
  const [keywordsData, setKeywordsData] = useState<FirmData[]>([]);
  const [keywordsDailyStatsData, setKeywordsDailyStatsData] =
    useState<KeywordsDailyStatsResponse | null>(null);
  const [monitorData, setMonitorData] = useState<MonitorDataResponse>({} as MonitorDataResponse);
  const [loading, setLoading] = useState<boolean>(true);

  const prevMonitorPeriod = useRef(monitorPeriod);
  const prevRange = useRef(range);
  const prevSelectedFirm = useRef(selectedFirm);
  const prevSelectedChatType = useRef(selectedChatType);

  const canAddClaude = Number(projectId) === 1;

  useEffect(() => {
    const newMonitorPeriod = getPeriod(period);
    setMonitorPeriod(newMonitorPeriod);
    setSelectedChatType(prepareAndGetChatType('ALL', chat));
  }, [type, period, chat]);

  useEffect(() => {
    if (!id) {
      console.error('Id cannot be empty');
      return;
    }

    if (force && monitorPeriod === null) {
      return;
    }

    const isMonitorPeriodChanged = prevMonitorPeriod.current !== monitorPeriod;
    const isChatTypeChanged = prevSelectedChatType.current !== selectedChatType;
    const isRangeChanged = prevRange.current !== range;
    const isSelectedFirmChanged = prevSelectedFirm.current !== selectedFirm;

    if (
      range &&
      range[1] &&
      (isMonitorPeriodChanged || isRangeChanged || isSelectedFirmChanged || isChatTypeChanged || force)
    ) {
      refresh(id);
    }

    prevMonitorPeriod.current = monitorPeriod;
    prevRange.current = range;
    prevSelectedFirm.current = selectedFirm;
    prevSelectedChatType.current = selectedChatType;
  }, [monitorPeriod, range, id, selectedFirm, selectedChatType]);

  const getPrefixForUrl = () => (force ? `${PROJECTS}/${projectId}/` : '');

  const getPeriodAndChatParam = (period: string): string => {
    const chatParam = chat ? chat.toLowerCase() : 'gpt';
    return `${period}/${chatParam}`;
  };

  const getPeriodParam = (): string => {
    return monitorPeriod ? monitorPeriod.toLowerCase() : 'daily';
  };

  const periodFilter: FilterItem[] = [
    {
      url: `${getPrefixForUrl()}${QUESTIONS}/${id}/${getPeriodAndChatParam('daily')}`,
      label: t('dailyFilter'),
      isActive: monitorPeriod === 'DAILY',
    },
    {
      url: `${getPrefixForUrl()}${QUESTIONS}/${id}/${getPeriodAndChatParam('weekly')}`,
      label: t('weeklyFilter'),
      isActive: monitorPeriod === 'WEEKLY',
    },
    {
      url: `${getPrefixForUrl()}${QUESTIONS}/${id}/${getPeriodAndChatParam('monthly')}`,
      label: t('monthlyFilter'),
      isActive: monitorPeriod === 'MONTHLY',
    },
  ];

  const onSelect = useCallback(
    (value: FirmData) => {
      if (!id) {
        console.error('Id can not be empty');
        return;
      }

      updateKeywords(id, value).then(() => setSelectedFirm(value));
    },
    [id, updateKeywords, setSelectedFirm]
  );

  const refresh = (id: string | undefined) => {
    if (!id || !Number(id) || !monitorPeriod || !selectedChatType) {
      const url = force ? getPrefixForUrl() : QUESTIONS;
      navigate(url);
      return;
    }

    const from = range ? range[0] : null;
    const to = range ? range[1] : null;
    setLoading(true);

    Promise.all([
      getPromptData(id),
      getKeywordsData(id),
      getKeywordsDailyStats(
        id,
        monitorPeriod,
        getMonitorTypeByChatOptionsType(selectedChatType),
        from,
        to
      ),
    ])
      .then(([promptResponse, keywordsResponse, keywordsDailyStatsResponse]) => {
        if (!promptResponse || promptResponse?.status === 'PENDING') {
          navigate(QUESTIONS);
          return;
        }

        setPromptData(promptResponse);

        const keywordsList: FirmData[] = keywordsResponse.map((keywordData) => ({
          name: keywordData.keyword,
          type: keywordData.keyword,
        }));
        setKeywordsData(keywordsList);
        setKeywordsDailyStatsData(keywordsDailyStatsResponse);

        const keyword = promptResponse.keyword;
        const isSelectedFirmChanged = prevSelectedFirm.current !== selectedFirm;
        if (keyword && (!prevSelectedFirm.current || isSelectedFirmChanged)) {
          setSelectedFirm({ name: keyword, type: keyword });
        }

        getMonitorData(
          id,
          keyword,
          monitorPeriod,
          getMonitorTypeByChatOptionsType(selectedChatType),
          from,
          to
        ).then((monitorDataResponse) => {
          setMonitorData(monitorDataResponse);
          setLoading(false);
        });
      })
      .catch(() => navigate(QUESTIONS));
  };

  const typeTemplate = (option: any) => {
    return <div className="p-dropdown-item">{option.name}</div>;
  };

  const filtersPanel = () => {
    return (
      <div className="filters">
        <Dropdown
          value={
            monitorTypeOptions(t, canAddClaude).find((type) => selectedChatType === type.type) ||
            monitorTypeOptions(t, canAddClaude)[0]
          }
          onChange={(e) =>
            navigate(
              `${getPrefixForUrl()}${QUESTIONS}/${id}/${getPeriodParam()}/${e.value.type.toLowerCase()}`
            )
          }
          options={monitorTypeOptions(t, canAddClaude)}
          className="dropdown"
          optionLabel="name"
          itemTemplate={typeTemplate}
        />
        <div className="calendar">
          <Calendar
            value={range}
            onChange={(e) => setRange(e.value as Date[])}
            selectionMode="range"
            readOnlyInput
            hideOnRangeSelection
            dateFormat="yy-mm-dd"
            pt={{
              root: {
                className: 'calendar',
              },
              panel: {
                className: 'calendar-panel',
              },
            }}
          />
          <span id="range" className="material-symbols-outlined question-mark question-mark--left">
            help
          </span>
          <Tooltip
            target="#range"
            content={t('rangeTooltip')}
            className="tooltip--top"
            position="top"
            style={{ width: '220px' }}
          />
        </div>
        <FilterBox items={periodFilter} />
      </div>
    );
  };

  if (loading) {
    return <Loader />;
  }

  return promptData ? (
    <>
      {loading && <Loader />}
      <Title
        mainCategory={t('questions')}
        link={QUESTIONS}
        children={filtersPanel()}
        showStatusDot={true}
      />
      <div className="preview-prompt-page page-content">
        <div className="preview-prompt-page__title">{promptData.prompt}</div>
        <Stats
          keywordsData={keywordsData}
          keywordsDailyStatsData={keywordsDailyStatsData}
          monitorData={monitorData}
          promptData={promptData}
          onSelect={onSelect}
        />
      </div>
    </>
  ) : (
    <></>
  );
};

export default PreviewPromptPage;
