import { client } from '../../../../shared/services/apiClient';
import { UserDataResponse } from '../../../../shared/models/user/data/UserDataResponse';

export const saveBillingData = async (data: UserDataResponse): Promise<UserDataResponse> => {
  return client(true, true)
    .put<UserDataResponse>('/user/data/', data)
    .then((response) => response.data)
    .catch((error) => error);
};

export const getBillingData = async (): Promise<UserDataResponse> => {
  return client(true, true)
    .get<UserDataResponse>('/user/data/')
    .then((response) => response.data)
    .catch((error) => error);
};
