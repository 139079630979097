import { subMonths } from 'date-fns';
import { RoleType } from '../types/user/accounts/RoleType';

export const COLORS_FIRST_SERIES = [
  '#009efd',
  '#00beff',
  '#00d6e6',
  '#00e9b8',
  '#99f58a',
  '#159b53',
  '#40b681',
  '#00a189',
  '#008b8a',
  '#007481',
  '#225d70',
  '#ff7070',
  '#db628e',
  '#a6619b',
  '#6e5e93',
  '#42567a',
  '#e0c951',
  '#8dba5e',
  '#46a273',
  '#01857d',
  '#176674',
  '#F66D44',
  '#FEAE65',
  '#E6F69D',
  '#AADEA7',
  '#64C2A6',
  '#a6daff',
  '#6cc3fa',
  '#68cde5',
  '#3cb9d7',
  '#3c7ad7',
  '#463cd7',
  '#9e3cd7',
  '#9966ff',
  '#ff66fc',
  '#ff66ab',
  '#1f8afc',
  '#3db2ff',
  '#5dd0e6',
  '#3ddcb8',
  '#83f58a',
  '#119353',
  '#34a681',
  '#009d89',
  '#007b8a',
  '#005e81',
  '#1a4d70',
  '#ff5a5a',
  '#cf508e',
  '#954d9b',
  '#664c93',
  '#36407a',
  '#d7b451',
  '#83b05e',
  '#3b9173',
  '#007c7d',
  '#135674',
  '#d85c3c',
  '#e9955a',
];

export const COLORS_SECOND_SERIES = [
  '#4b789a',
  '#1c98b3',
  '#00b7b7',
  '#4cd3a5',
  '#a0ea88',
  '#09522a',
  '#268120',
  '#007947',
  '#006f60',
  '#00636b',
  '#005567',
  '#9b1717',
  '#941446',
  '#792e66',
  '#533f72',
  '#34476b',
  '#916717',
  '#5f6c22',
  '#31693d',
  '#006155',
  '#0e555f',
  '#a6482e',
  '#674c28',
  '#747c50',
  '#495d46',
  '#3c7762',
  '#5c788d',
  '#417693',
  '#40808c',
  '#1d5869',
  '#22487e',
  '#27217a',
  '#571f77',
  '#4c327e',
  '#6e2d6e',
  '#6c2a4c',
  '#42708d',
  '#1e87b3',
  '#00a9b7',
  '#3cb995',
  '#8ad588',
  '#08422a',
  '#206920',
  '#006147',
  '#005c60',
  '#004e6b',
  '#004367',
  '#7b0f17',
  '#841046',
  '#692a66',
  '#4b3672',
  '#2e3f6b',
  '#815617',
  '#4f5c22',
  '#21593d',
  '#005155',
  '#0a455f',
  '#8a382e',
  '#573c28',
  '#646c50',
];

export const isAdminOrOwner = (role: RoleType): boolean => {
  return ['ADMIN', 'OWNER'].includes(role);
};

export const isValidDate = (date: string): boolean => {
  return !isNaN(Date.parse(date));
};

export const round = (num: number, fractionDigits: number): number => {
  return Number(num.toFixed(fractionDigits));
};

export const getInitials = (word: string): string => {
  const words = word.trim().split(' ');
  const initials = words.map((word) => {
    return word.length > 0 ? word[0].toUpperCase() : '';
  });
  return initials.join('');
};

export const getDefaultRange = (): Date[] => {
  const today = new Date();
  const from = subMonths(today, 1);
  return [from, today];
};

export const numberFormat = (): Intl.NumberFormat => {
  return new Intl.NumberFormat('pl-PL', {
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const getRandomColor = (): string => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
};

export const formatLargeNumber = (value: number | null): string => {
  if (!value) {
    return '-';
  }

  if (value >= 1_000_000_000) {
    return (value / 1_000_000_000).toFixed(0) + 'B';
  } else if (value >= 1_000_000) {
    return (value / 1_000_000).toFixed(0) + 'M';
  } else if (value >= 1_000) {
    return (value / 1_000).toFixed(0) + 'K';
  } else {
    return value.toString();
  }
};

export const SHARE_OF_VOICE_WEIGHT = new Map<number, number>([
  [1, 39.8],
  [2, 18.7],
  [3, 10.2],
  [4, 7.2],
  [5, 5.1],
  [6, 4.4],
  [7, 3.0],
  [8, 2.1],
  [9, 1.9],
  [10, 1.6],
  [11, 0.86],
  [12, 0.61],
  [13, 0.44],
  [14, 0.31],
  [15, 0.22],
  [16, 0.16],
  [17, 0.12],
  [18, 0.09],
  [19, 0.07],
  [20, 0.05],
  [21, 0.04],
  [22, 0.03],
  [23, 0.02],
  [24, 0.02],
  [25, 0.01],
  [26, 0.01],
  [27, 0.01],
  [28, 0.01],
  [29, 0.01],
  [30, 0.01],
]);

export const SCORE_WEIGHT = new Map<number, number>([
  [1, 40],
  [1.1, 39.58],
  [1.2, 39.16],
  [1.3, 38.73],
  [1.4, 38.31],
  [1.5, 37.89],
  [1.6, 37.47],
  [1.7, 37.04],
  [1.8, 36.62],
  [1.9, 36.2],
  [2, 35.78],
  [2.1, 35.36],
  [2.2, 34.93],
  [2.3, 34.51],
  [2.4, 34.09],
  [2.5, 33.67],
  [2.6, 33.24],
  [2.7, 32.82],
  [2.8, 32.4],
  [2.9, 31.98],
  [3, 31.56],
  [3.1, 31.13],
  [3.2, 30.71],
  [3.3, 30.29],
  [3.4, 29.87],
  [3.5, 29.44],
  [3.6, 29.02],
  [3.7, 28.6],
  [3.8, 28.18],
  [3.9, 27.76],
  [4, 27.33],
  [4.1, 26.91],
  [4.2, 26.49],
  [4.3, 26.07],
  [4.4, 25.64],
  [4.5, 25.22],
  [4.6, 24.8],
  [4.7, 24.38],
  [4.8, 23.96],
  [4.9, 23.53],
  [5, 23.11],
  [5.1, 22.69],
  [5.2, 22.27],
  [5.3, 21.84],
  [5.4, 21.42],
  [5.5, 21.0],
  [5.6, 20.58],
  [5.7, 20.16],
  [5.8, 19.73],
  [5.9, 19.31],
  [6, 18.89],
  [6.1, 18.47],
  [6.2, 18.04],
  [6.3, 17.62],
  [6.4, 17.2],
  [6.5, 16.78],
  [6.6, 16.36],
  [6.7, 15.93],
  [6.8, 15.51],
  [6.9, 15.09],
  [7, 14.67],
  [7.1, 14.24],
  [7.2, 13.82],
  [7.3, 13.4],
  [7.4, 12.98],
  [7.5, 12.56],
  [7.6, 12.13],
  [7.7, 11.71],
  [7.8, 11.29],
  [7.9, 10.87],
  [8, 10.44],
  [8.1, 10.02],
  [8.2, 9.6],
  [8.3, 9.18],
  [8.4, 8.76],
  [8.5, 8.33],
  [8.6, 7.91],
  [8.7, 7.49],
  [8.8, 7.07],
  [8.9, 6.64],
  [9, 6.22],
  [9.1, 5.8],
  [9.2, 5.38],
  [9.3, 4.96],
  [9.4, 4.53],
  [9.5, 4.11],
  [9.6, 3.69],
  [9.7, 3.27],
  [9.8, 2.84],
  [9.9, 2.42],
  [10, 2],
  [10.1, 1.99],
  [10.2, 1.98],
  [10.3, 1.97],
  [10.4, 1.96],
  [10.5, 1.95],
  [10.6, 1.94],
  [10.7, 1.93],
  [10.8, 1.92],
  [10.9, 1.91],
  [11, 1.9],
  [11.1, 1.89],
  [11.2, 1.88],
  [11.3, 1.87],
  [11.4, 1.86],
  [11.5, 1.85],
  [11.6, 1.84],
  [11.7, 1.83],
  [11.8, 1.82],
  [11.9, 1.81],
  [12, 1.8],
  [12.1, 1.79],
  [12.2, 1.78],
  [12.3, 1.77],
  [12.4, 1.76],
  [12.5, 1.75],
  [12.6, 1.74],
  [12.7, 1.73],
  [12.8, 1.72],
  [12.9, 1.71],
  [13, 1.7],
  [13.1, 1.69],
  [13.2, 1.68],
  [13.3, 1.67],
  [13.4, 1.66],
  [13.5, 1.65],
  [13.6, 1.64],
  [13.7, 1.63],
  [13.8, 1.62],
  [13.9, 1.61],
  [14, 1.6],
  [14.1, 1.59],
  [14.2, 1.58],
  [14.3, 1.57],
  [14.4, 1.56],
  [14.5, 1.55],
  [14.6, 1.54],
  [14.7, 1.53],
  [14.8, 1.52],
  [14.9, 1.51],
  [15, 1.5],
  [15.1, 1.49],
  [15.2, 1.48],
  [15.3, 1.47],
  [15.4, 1.46],
  [15.5, 1.45],
  [15.6, 1.44],
  [15.7, 1.43],
  [15.8, 1.42],
  [15.9, 1.41],
  [16, 1.4],
  [16.1, 1.39],
  [16.2, 1.38],
  [16.3, 1.37],
  [16.4, 1.36],
  [16.5, 1.35],
  [16.6, 1.34],
  [16.7, 1.33],
  [16.8, 1.32],
  [16.9, 1.31],
  [17, 1.3],
  [17.1, 1.29],
  [17.2, 1.28],
  [17.3, 1.27],
  [17.4, 1.26],
  [17.5, 1.25],
  [17.6, 1.24],
  [17.7, 1.23],
  [17.8, 1.22],
  [17.9, 1.21],
  [18, 1.2],
  [18.1, 1.19],
  [18.2, 1.18],
  [18.3, 1.17],
  [18.4, 1.16],
  [18.5, 1.15],
  [18.6, 1.14],
  [18.7, 1.13],
  [18.8, 1.12],
  [18.9, 1.11],
  [19, 1.1],
  [19.1, 1.09],
  [19.2, 1.08],
  [19.3, 1.07],
  [19.4, 1.06],
  [19.5, 1.05],
  [19.6, 1.04],
  [19.7, 1.03],
  [19.8, 1.02],
  [19.9, 1.01],
  [20, 1],
  [20.1, 0.99],
  [20.2, 0.98],
  [20.3, 0.97],
  [20.4, 0.96],
  [20.5, 0.95],
  [20.6, 0.94],
  [20.7, 0.93],
  [20.8, 0.92],
  [20.9, 0.91],
  [21, 0.9],
  [21.1, 0.89],
  [21.2, 0.88],
  [21.3, 0.87],
  [21.4, 0.86],
  [21.5, 0.85],
  [21.6, 0.84],
  [21.7, 0.83],
  [21.8, 0.82],
  [21.9, 0.81],
  [22, 0.8],
  [22.1, 0.79],
  [22.2, 0.78],
  [22.3, 0.77],
  [22.4, 0.76],
  [22.5, 0.75],
  [22.6, 0.74],
  [22.7, 0.73],
  [22.8, 0.72],
  [22.9, 0.71],
  [23, 0.7],
  [23.1, 0.69],
  [23.2, 0.68],
  [23.3, 0.67],
  [23.4, 0.66],
  [23.5, 0.65],
  [23.6, 0.64],
  [23.7, 0.63],
  [23.8, 0.62],
  [23.9, 0.61],
  [24, 0.6],
  [24.1, 0.59],
  [24.2, 0.58],
  [24.3, 0.57],
  [24.4, 0.56],
  [24.5, 0.55],
  [24.6, 0.54],
  [24.7, 0.53],
  [24.8, 0.52],
  [24.9, 0.51],
  [25, 0.5],
  [25.1, 0.49],
  [25.2, 0.48],
  [25.3, 0.47],
  [25.4, 0.46],
  [25.5, 0.45],
  [25.6, 0.44],
  [25.7, 0.43],
  [25.8, 0.42],
  [25.9, 0.41],
  [26, 0.4],
  [26.1, 0.39],
  [26.2, 0.38],
  [26.3, 0.37],
  [26.4, 0.36],
  [26.5, 0.35],
  [26.6, 0.34],
  [26.7, 0.33],
  [26.8, 0.32],
  [26.9, 0.31],
  [27, 0.3],
  [27.1, 0.29],
  [27.2, 0.28],
  [27.3, 0.27],
  [27.4, 0.26],
  [27.5, 0.25],
  [27.6, 0.24],
  [27.7, 0.23],
  [27.8, 0.22],
  [27.9, 0.21],
  [28, 0.2],
  [28.1, 0.19],
  [28.2, 0.18],
  [28.3, 0.17],
  [28.4, 0.16],
  [28.5, 0.15],
  [28.6, 0.14],
  [28.7, 0.13],
  [28.8, 0.12],
  [28.9, 0.11],
  [29, 0.1],
  [29.1, 0.09],
  [29.2, 0.08],
  [29.3, 0.07],
  [29.4, 0.06],
  [29.5, 0.05],
  [29.6, 0.04],
  [29.7, 0.03],
  [29.8, 0.02],
  [29.9, 0.01],
  [30, 0.01],
]);

export const getTextForVisibility = (percent: number): string => {
  if (percent === 0) {
    return 'notVisible';
  }
  if (percent > 0 && percent <= 2) {
    return 'minimal';
  }

  if (percent > 2 && percent <= 9) {
    return 'emerging';
  }

  if (percent > 9 && percent <= 29) {
    return 'good';
  }

  if (percent > 29 && percent <= 49) {
    return 'strong';
  }

  if (percent > 49 && percent <= 69) {
    return 'excellent';
  }

  return 'topPerformer';
};
