import React from 'react';
import './AdminPage.scss';
import Title from '../../shared/components/title/Title';
import { useTranslation } from 'react-i18next';
import Tabs from '../../shared/components/tabs/Tabs';
import { TabItem } from '../../shared/components/tabs/models/TabsModel';

interface AdminProps {
  children: React.ReactNode;
  restUrl: string;
}

const AdminPage: React.FC<AdminProps> = ({ children, restUrl }) => {
  const { t } = useTranslation();

  const menuItems: TabItem[] = [
    {
      icon: 'id_card',
      url: `/admin/dashboard`,
      label: t('dashboard'),
      isActive: restUrl === 'dashboard',
    },
    {
      icon: 'password',
      url: `/admin/users`,
      label: t('users'),
      isActive: restUrl === 'users',
    },
    {
      icon: 'settings',
      url: `/admin/settings`,
      label: t('settings'),
      isActive: restUrl === 'settings',
    },
  ];

  return (
    <>
      <Title mainCategory={t('admin')} />
      <div className="admin-page page-content">
        <div className="page-box">
          <div className="page-box__title-action">
            <div className="page-box__title">
              {menuItems.filter((item) => item.isActive)[0]?.label ?? ''}
            </div>
          </div>
          <Tabs items={menuItems} additionalClass="page-box__tabs" />
          <div className="page-box__content">
            <main>{children}</main>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminPage;
