import { client } from '../../../../shared/services/apiClient';
import { QuestionResponse } from '../../../../shared/models/projects/QuestionResponse';
import { ProjectData } from '../models/ProjectData';
import { ProjectResponse } from '../../../../shared/models/projects/ProjectResponse';

export const getQuestions = async (domain: string): Promise<QuestionResponse> => {
  return client(true, true)
    .get<QuestionResponse>(`/projects/question/${domain}`)
    .then((response) => response.data);
};

export const createProject = async (projectData: ProjectData): Promise<ProjectResponse[]> => {
  return client(true, true)
    .post<ProjectResponse[]>('/projects/add', projectData)
    .then((response) => response.data);
};
