import React from 'react';
import './AdminSettingsPage.scss';
import { Button } from 'primereact/button';
import { updateAllModelQuestions } from '../../../shared/services/monitor/prompt/PromptService';

const AdminSettingsPage: React.FC = () => {
  const updateAllModelQuestionsEvent = () => {
    updateAllModelQuestions()
      .then(() => alert('ok'))
      .catch(() => console.log('error'));
  };

  return (
    <div className="settings">
      <ul>
        <li>
          <Button
            className="btn btn--link"
            label="Update all model questions"
            onClick={() => updateAllModelQuestionsEvent()}
          />
        </li>
        <li>
          <Button className="btn btn--link" label="Clean old entry (Disable)" />
        </li>
        <li>
          <Button className="btn btn--link" label="Create user account (Disable)" />
        </li>
      </ul>
    </div>
  );
};

export default AdminSettingsPage;
