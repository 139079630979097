import React, { useEffect, useState, useRef } from 'react';
import './Panel.scss';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { useAccount } from '../../../contexts/account/AccountContexts';
import Loader from '../loader/Loader';
import { useTranslation } from 'react-i18next';
import { PROJECTS, PROJECTS_ADD, QUESTIONS } from '../../../routes/path';
import { Button } from 'primereact/button';
import { getProjects } from '../../../services/projects/ProjectService';
import { ProjectResponse } from '../../../models/projects/ProjectResponse';

const ITEM_HEIGHT = 36;
const ITEM_GAP = 10;

const Panel: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { accountResponse, projectResponse, setProjectResponse, canFetch, setCanFetch } = useAccount();
  const [isOpen, setIsOpen] = useState(false);
  const [projects, setProjects] = useState<ProjectResponse[]>([]);
  const showPricing = Boolean(process.env.REACT_SHOW_PRICING) || false;
  const submenuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setIsOpen(location.pathname.startsWith(PROJECTS));
  }, [location.pathname]);

  useEffect(() => {
    const fetchProjects = async () => {
      if (!canFetch) {
        return;
      }

      try {
        if (projectResponse && projects.length === 0) {
          setProjects(projectResponse);
        }
        if (!projectResponse && canFetch) {
          const fetchedProjects = await getProjects();
          setProjectResponse(fetchedProjects);
          setProjects(fetchedProjects || []);
          if (!fetchedProjects || fetchedProjects.length === 0) {
            navigate(PROJECTS_ADD);
          }
        }
      } catch (error) {
        console.error('Failed to fetch projects:', error);
      }
    };
    fetchProjects();
  }, [projectResponse, projects, navigate, setProjectResponse, setCanFetch]);

  if (!accountResponse || !projects || !projectResponse) {
    return <Loader />;
  }

  const projectsCount = projects.length;

  const reorderedProjects = () => {
    const activeProject = projects.find((project) =>
      location.pathname.includes(`${PROJECTS}/${project.id}`)
    );

    if (activeProject) {
      return [activeProject, ...projects.filter((project) => project.id !== activeProject.id)];
    }

    return projects;
  };

  const finalProjects = reorderedProjects();

  return (
    <div className="panel">
      <div className="logo">
        <NavLink to={PROJECTS}>
          <div className="logo-box" />
        </NavLink>
      </div>
      <div className="panel__menu-panel">
        <div className="panel__menu menu">
          <div className="menu__item-container">
            {projectsCount > 0 && (
              <Button
                className="btn btn--toggle material-symbols-outlined"
                onClick={() => setIsOpen(!isOpen)}
              >
                {isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
              </Button>
            )}
            <NavLink
              to={PROJECTS}
              className={() =>
                (location.pathname.includes(PROJECTS) && !location.pathname.includes(`${PROJECTS}/`)
                  ? 'menu__item--active'
                  : '') + ' menu__item menu__item--open-btn'
              }
            >
              <div className="menu__item-box">
                <i className="material-symbols-outlined">category</i>
                {t('projects')}
              </div>
            </NavLink>
          </div>
          {projectsCount > 0 && isOpen && (
            <div
              className="menu__submenu"
              ref={submenuRef}
              style={{
                height: isOpen ? projectsCount * ITEM_HEIGHT + (projectsCount - 1) * ITEM_GAP + 'px' : 0,
              }}
            >
              {finalProjects.map((project, index) => (
                <NavLink
                  to={`${PROJECTS}/${project.id}`}
                  className={({ isActive }) => (isActive ? 'menu__item--active' : '') + ' menu__item'}
                  key={`project-submenu-${index}`}
                >
                  <div className="menu__item-text-container">{project.firmName}</div>
                </NavLink>
              ))}
            </div>
          )}

          <div className="menu__separator" />
          <NavLink
            to={QUESTIONS}
            className={({ isActive }) => (isActive ? 'menu__item--active' : '') + ' menu__item'}
          >
            <i className="material-symbols-outlined">dashboard</i>
            {t('questions')}
          </NavLink>
          <NavLink
            to="/integrations"
            className={({ isActive }) => (isActive ? 'menu__item--active' : '') + ' menu__item'}
          >
            <i className="material-symbols-outlined">extension</i>
            {t('integrations')}
          </NavLink>
          {showPricing && (
            <NavLink
              to="/pricing"
              className={({ isActive }) => (isActive ? 'menu__item--active' : '') + ' menu__item'}
            >
              <i className="material-symbols-outlined">credit_card</i>
              {t('pricing')}
            </NavLink>
          )}
          <NavLink
            to="/faq"
            className={({ isActive }) => (isActive ? 'menu__item--active' : '') + ' menu__item'}
          >
            <i className="material-symbols-outlined">help</i>
            {t('faq')}
          </NavLink>
          <NavLink
            to="https://chatbeat.com/howto"
            target="_blank"
            className={({ isActive }) => (isActive ? 'menu__item--active' : '') + ' menu__item'}
          >
            <i className="material-symbols-outlined">help</i>
            {t('improveAIRanking')}
          </NavLink>
        </div>
        <div className="panel__end">
          {accountResponse && accountResponse.accountType !== 'PAID' && showPricing && (
            <div className="panel__upgrade">
              <h4>{t('upgradePlanTrialMsg')}</h4>
              <NavLink to="/pricing" className="btn btn--white btn--sm">
                {t('upgradeNow')}
              </NavLink>
            </div>
          )}

          <div className="panel__footer">
            © {format(new Date(), 'yyyy')}
            <br />
            All rights reserved by Chatbeat
          </div>
        </div>
      </div>
    </div>
  );
};

export default Panel;
