import { MonitorPeriod } from '../../../../types/monitor/MonitorPeriod';
import { MonitorType } from '../../../../types/monitor/MonitorType';
import { KeywordsDailyStatsResponse } from '../../../../models/monitor/keywords/stats/KeywordsDailyStatsResponse';
import { MonitorPromptsKeywordsStatsResponse } from './MonitorPromptsKeywordsStatsResponse';
import { getDefaultRange } from '../../../../utils/utils';
import { client } from '../../../apiClient';
import { format } from 'date-fns';
import { PeriodType } from '../../../../types/prompt/PeriodType';
import { ChatType } from '../../../../types/monitor/ChatType';

export const getKeywordsDailyStats = async (
  id: string,
  period?: MonitorPeriod | 'DAILY',
  type?: MonitorType | 'ALL',
  from?: Date | null,
  to?: Date | null
): Promise<KeywordsDailyStatsResponse> => {
  const defaultRange = getDefaultRange();
  from = from ? new Date(from) : defaultRange[0];
  to = to ? new Date(to) : defaultRange[1];

  const formattedFrom = format(from, 'yyyy-MM-dd');
  const formattedTo = format(to, 'yyyy-MM-dd');

  return client(true, true)
    .get<KeywordsDailyStatsResponse>(
      `/monitor/keywords/stats/${id}/${period}/${type}/${formattedFrom}/${formattedTo}`
    )
    .then((response) => response.data)
    .catch((error) => error);
};

export const getKeywordsDailyStatsForProject = async (
  projectId: number,
  chatType: ChatType,
  period?: PeriodType | 'DAILY',
  from?: Date | null,
  to?: Date | null
): Promise<MonitorPromptsKeywordsStatsResponse[]> => {
  const defaultRange = getDefaultRange();
  from = from ? new Date(from) : defaultRange[0];
  to = to ? new Date(to) : defaultRange[1];

  const formattedFrom = format(from, 'yyyy-MM-dd');
  const formattedTo = format(to, 'yyyy-MM-dd');

  return client(true, true)
    .get<MonitorPromptsKeywordsStatsResponse[]>(
      `/monitor/keywords/stats/project/${projectId}/${chatType}/${period}/${formattedFrom}/${formattedTo}`
    )
    .then((response) => response.data)
    .catch((error) => error);
};
