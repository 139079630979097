import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from '../../contexts/account/AccountContexts';
import { getAccount } from '../../services/account/AccountService';
import { useAuth } from '../../contexts/auth/AuthContext';
import { getProjects } from '../../services/projects/ProjectService';
import { useState } from 'react';

const useAccountData = () => {
  const { i18n } = useTranslation();
  const { setAccountResponse, setProjectResponse } = useAccount();
  const { isAuthenticated } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isAuthenticated) {
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const [accountData, projectData] = await Promise.all([getAccount(), getProjects()]);
        await i18n.changeLanguage(accountData.language);
        setAccountResponse(accountData);
        setProjectResponse(projectData);
      } catch (error) {
        console.error('Error fetching account or project data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [isAuthenticated, i18n, setAccountResponse, setProjectResponse]);

  return loading;
};

export default useAccountData;
