import { client } from '../../apiClient';
import { UserDataResponse } from '../../../models/admin/users/UserDataResponse';

export const getAllUsers = async (): Promise<UserDataResponse[]> => {
  return client(true, true)
    .get<UserDataResponse[]>(`/admin/dashboard/all-users`)
    .then((response) => response.data)
    .catch((error) => error);
};

export const registerUser = async (formData: URLSearchParams): Promise<any> => {
  return client(false, true).post<any>('/admin/users/register-user', formData);
};
