import { addDays, format } from 'date-fns';
import { PeriodType } from '../../../types/prompt/PeriodType';
import { MonitorDataResponse } from '../../../models/monitor/MonitorDataResponse';
import { ChartMatrixData, GeneralData, GeneralDataRecords, StatsTableData } from '../models/StatsModel';
import { COLORS_FIRST_SERIES } from '../../../utils/utils';
import {
  KeywordsDailyStatsRecord,
  KeywordsDailyStatsResponse,
} from '../../../models/monitor/keywords/stats/KeywordsDailyStatsResponse';
import { PieChartData } from '../../chart/models/ChartModel';
import { MonitorType } from '../../../types/monitor/MonitorType';

export const prepareGeneralData = (
  keywordsDailyStatsResponse: KeywordsDailyStatsResponse | null,
  selectedBrand: string,
  t: (key: string) => string
): GeneralData | null => {
  if (!keywordsDailyStatsResponse) {
    return null;
  }

  const shareOfVoiceChartData: PieChartData[] = [];
  const result: GeneralDataRecords = {};

  Object.keys(keywordsDailyStatsResponse).forEach((key) => {
    const records = keywordsDailyStatsResponse[key];

    const validPositions = records
      .map((record) => record.averagePosition)
      .filter((value): value is number => value !== null);

    const validShareOfVoice = records
      .map((record) => record.averageShareOfVoice)
      .filter((value): value is number => value !== null && value !== 0);

    const averagePosition = parseFloat(
      (validPositions.reduce((sum, pos) => sum + pos, 0) / (validPositions.length || 1)).toFixed(1)
    );
    const averageShareOfVoicePosition =
      validShareOfVoice.reduce((sum, pos) => sum + pos, 0) / validShareOfVoice.length || 0;
    const averageShareOfVoice = averageShareOfVoicePosition || 0;

    const sortedRecords = records
      .filter((record) => record.averagePosition !== null)
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    let trend = undefined;
    if (sortedRecords.length >= 2) {
      const firstPosition = sortedRecords[0].averagePosition!;
      const lastPosition = sortedRecords[sortedRecords.length - 1].averagePosition!;
      trend =
        !lastPosition || !firstPosition
          ? undefined
          : parseFloat(((lastPosition - firstPosition) * -1).toFixed(2));
    }

    const roundedAverageShareOfVoice = Math.floor(averageShareOfVoice * 100) / 100;
    result[key] = {
      averagePosition,
      averageShareOfVoice: roundedAverageShareOfVoice,
      trend,
    };

    shareOfVoiceChartData.push({
      value: roundedAverageShareOfVoice,
      name: key,
    });
  });

  return {
    shareOfVoiceChartData: getPieChartWithOthers(shareOfVoiceChartData, selectedBrand, t),
    cumulativeData: result,
  };
};

export const getPieChartWithOthers = (
  shareOfVoiceChartData: PieChartData[],
  forcedBrand: string,
  t: (key: string) => string
): PieChartData[] => {
  const MAX_ITEMS = 9;

  const sortedData = shareOfVoiceChartData.sort((a, b) => b.value - a.value);
  const forcedBrandData = sortedData.find((item) => item.name === forcedBrand);

  let topItems = sortedData.slice(0, MAX_ITEMS);
  const totalValue = topItems.reduce((acc, item) => acc + item.value, 0);
  const othersTotalValue = 100 - totalValue;
  const others = { value: parseFloat(othersTotalValue.toFixed(2)), name: t('others') };
  topItems = others.value > 0 ? [...topItems, others] : topItems;

  if (forcedBrandData) {
    topItems = topItems.filter((item) => item.name !== forcedBrand);
    topItems = [forcedBrandData, ...topItems];
  }

  return topItems;
};

export const createBrandsMatrix = (
  periodType: PeriodType,
  keywordsDailyStatsResponse: KeywordsDailyStatsResponse | null,
  selectedKeyword: string
): ChartMatrixData => {
  if (!keywordsDailyStatsResponse) {
    return { matrix: [], secondMatrix: [], colorsMap: new Map<string, string>() };
  }

  const colorsMap = new Map<string, string>();
  const headers: string[] = ['date'];
  const allBrands = Object.keys(keywordsDailyStatsResponse);

  const brandAverages = allBrands.map((brand) => {
    const records = keywordsDailyStatsResponse[brand];
    const avg = records.reduce((sum, record) => sum + (record.averagePosition ?? 0), 0) / records.length;
    return { brand, avg };
  });

  let topBrands = brandAverages
    .sort((a, b) => a.avg - b.avg)
    .slice(0, 6)
    .map((item) => item.brand);

  if (!topBrands.includes(selectedKeyword) && allBrands.includes(selectedKeyword)) {
    topBrands.pop();
    topBrands.push(selectedKeyword);
  }
  headers.push(...topBrands);

  const uniqueDates = Object.entries(keywordsDailyStatsResponse).map(([, records]) =>
    records.map((record) => record.date.toString())
  )[0];

  const matrix: (string | number)[][] = [];

  uniqueDates.forEach((date) => {
    const row: (string | number)[] = [prepareHeaderDate(date, periodType)];
    topBrands.forEach((brand, index) => {
      const record = keywordsDailyStatsResponse[brand].find((rec) => rec.date.toString() === date);

      const value: number | null = record?.averagePosition ?? null;
      row.push(value ?? (null as unknown as string | number));
      colorsMap.set(brand, COLORS_FIRST_SERIES[index]);
    });
    matrix.push(row);
  });

  matrix.unshift(headers);
  fillMissingData(matrix);

  return { matrix, secondMatrix: [], colorsMap };
};

export const prepareHeaderDate = (date: string, periodType: PeriodType): string => {
  if (periodType === 'WEEKLY') {
    let toDate = addDays(new Date(date), 6);
    const now = new Date();

    if (toDate > now) {
      toDate = now;
    }

    return `${date} - ${format(toDate, 'yyyy-MM-dd')}`;
  }

  return date;
};

const fillMissingData = (matrix: (string | number)[][]): void => {
  const numberOfColumns = matrix[0].length;
  const numberOfRows = matrix.length;

  for (let col = 1; col < numberOfColumns; col++) {
    for (let row = numberOfRows - 1; row > 0; row--) {
      if (matrix[row][col] === null || matrix[row][col] > 30) {
        matrix[row][col] = 31;
      }
    }
  }
};

export const createKeywordMatrix = (
  periodType: PeriodType,
  keywordsDailyStatsRecords: KeywordsDailyStatsRecord[],
  t: (key: string) => string
): ChartMatrixData => {
  if (!keywordsDailyStatsRecords) {
    return { matrix: [], secondMatrix: [], colorsMap: new Map<string, string>() };
  }

  const matrixMonitor: (string | number)[][] = [];
  // const matrixShareOfVoice: (string | number)[][] = [];
  const headersAveragePosition: string[] = ['date', t('averagePosition')];
  // const headersShareOfVoice: string[] = ['date', 'Share of voice'];

  const uniqueDates: Set<string> = new Set(
    keywordsDailyStatsRecords.map((record) => record.date.toString())
  );
  const uniqueHeadersAveragePosition = Array.from(new Set(headersAveragePosition));
  // const uniqueHeadersShareOfVoice = Array.from(new Set(headersShareOfVoice));
  const colorsMap = new Map<string, string>([
    ['Average position', COLORS_FIRST_SERIES[0]],
    // ['Share of voice', COLORS_SECOND_SERIES[0]],
  ]);

  uniqueDates.forEach((date) => {
    const headerDate = prepareHeaderDate(date, periodType);
    const rowAveragePosition: (string | number)[] = [headerDate];
    // const rowShareOfVoice: (string | number)[] = [headerDate];
    const record = keywordsDailyStatsRecords.find((record) => record.date.toString() === date);
    rowAveragePosition.push(record?.averagePosition ?? (null as unknown as string | number));
    // rowShareOfVoice.push(record?.averageShareOfVoice ?? (null as unknown as string | number));
    matrixMonitor.push(rowAveragePosition);
    // matrixShareOfVoice.push(rowShareOfVoice);
  });

  matrixMonitor.unshift(uniqueHeadersAveragePosition);
  // matrixShareOfVoice.unshift(uniqueHeadersShareOfVoice);

  let numberOfColumns = matrixMonitor[0].length;
  let numberOfRowsAveragePosition = matrixMonitor.length;

  for (let col = 1; col < numberOfColumns; col++) {
    for (let row = numberOfRowsAveragePosition - 1; row > 0; row--) {
      if (matrixMonitor[row][col] === null || matrixMonitor[row][col] > 30) {
        matrixMonitor[row][col] = 31;
      }

      // if (matrixShareOfVoice[row][col] === null) {
      //   matrixShareOfVoice[row][col] = 0;
      // }
    }
  }

  return { matrix: matrixMonitor, secondMatrix: [], colorsMap };
};

export const getApiText = (monitorType: MonitorType) => {
  switch (monitorType) {
    case 'GPT':
    case 'GPT_API_BROWSER':
      return 'gpt';

    case 'GPT_API':
      return 'gptApi';

    case 'GEMINI_API':
      return 'geminiFlash';

    case 'GEMINI_API_BROWSER':
      return 'geminiFlashPro';

    case 'CLAUDE_API':
      return 'claudeApi';

    case 'DEEP_SEEK_API':
      return 'deepSeekName';

    default:
      return '';
  }
};

export const prepareStatsTableData = (
  prompt: string,
  monitorData: MonitorDataResponse,
  keywordsDailyStatsRecords: KeywordsDailyStatsRecord[]
): StatsTableData[] => {
  return Object.keys(monitorData).map((date) => {
    const record = keywordsDailyStatsRecords?.find(
      (keywordsDailyStatsRecord) => keywordsDailyStatsRecord.date.toString() === date
    );
    const averagePosition = record && record.averagePosition ? record.averagePosition.toString() : '-';
    const averageShareOfVoice =
      record && record.averageShareOfVoice ? record.averageShareOfVoice.toString() + '%' : '-';

    return {
      created: date,
      prompt: prompt,
      averagePosition,
      averageShareOfVoice,
    };
  });
};
