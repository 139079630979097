import { KeywordResponse } from '../../../models/monitor/KeywordResponse';
import { client } from '../../apiClient';
import { FirmData } from '../../../models/monitor/FirmData';

export const getKeywordsData = async (id: string): Promise<KeywordResponse[]> => {
  return client(true, true)
    .get<KeywordResponse[]>(`/monitor/keywords/list/${id}`)
    .then((response) => response.data)
    .catch((error) => error);
};

export const addKeyword = async (id: number, keyword: string): Promise<KeywordResponse[]> => {
  return client(true, true)
    .put<KeywordResponse[]>(`/monitor/keywords/add/${id}`, [keyword])
    .then((response) => response.data)
    .catch((error) => error);
};

export const regenerateKeywords = async (id: number): Promise<KeywordResponse[]> => {
  return client(true, true)
    .get<KeywordResponse[]>(`/monitor/keywords/regenerate-keywords/${id}`)
    .then((response) => response.data)
    .catch((error) => error);
};

export const updateKeywords = async (id: string, firmData: FirmData): Promise<KeywordResponse> => {
  if (!firmData) {
    throw Error('Keyword is empty');
  }

  const keywordData = { keyword: firmData.type };
  return client(true, true)
    .post<KeywordResponse>(`/monitor/prompt/update-keyword/${id}`, keywordData)
    .then((response) => response.data)
    .catch((error) => error);
};
