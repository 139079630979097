import React, { useState } from 'react';
import './FirstStep.scss';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getQuestions } from '../../services/ProjectWizardService';
import { useProjectWizard } from '../../contexts/ProjectWizardContext';
import Loader from '../../../../../shared/components/common/loader/Loader';
import { DOMAIN_REGEX } from '../../../../../shared/utils/regex';

const FirstStep: React.FC = () => {
  const { setActiveStep, setQuestionResponse, setDomain } = useProjectWizard();
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [apiError, setApiError] = useState<string | null>(null);

  const onSubmit = async (loginFormData: any) => {
    setLoading(true);

    const domain = loginFormData.domain.trim();
    getQuestions(domain)
      .then((data) => {
        setLoading(false);
        setQuestionResponse(data);
        setActiveStep(2);
        setDomain(domain);
      })
      .catch((err) => {
        setLoading(false);
        setApiError(err.message);
      });
  };

  return (
    <>
      {loading && <Loader />}
      <form onSubmit={handleSubmit(onSubmit)} className="project-wizard__page-box">
        <div className="project-wizard__form">
          <div className="form-box__row">
            <h2>Enter domain:</h2>
            <InputText
              {...register('domain', {
                required: t('fieldIsRequired'),
                pattern: {
                  value: DOMAIN_REGEX,
                  message: t('wrongDomain'),
                },
              })}
              placeholder="example.com"
              className={errors.domain || apiError ? 'form-box__invalid' : ''}
            />
            {errors.domain && <span>{errors.domain.message as string}</span>}
          </div>
          <div>
            Please enter the <b>domain</b> for the brand you would like to track.
          </div>
        </div>
        <div className="project-wizard__action">
          <Button className="btn btn--sm" type="submit" disabled={loading}>
            {t('next')}
          </Button>
        </div>
      </form>
    </>
  );
};

export default FirstStep;
