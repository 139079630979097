import React from 'react';
import './EmptyState.scss';
import { EmptyStateData } from './models/EmptyStateData';

interface EmptyStateProps {
  emptyStateData: EmptyStateData;
  className?: string | '';
}

const EmptyState: React.FC<EmptyStateProps> = ({ emptyStateData, className = '' }) => {
  return (
    <div className={`empty-state ${className}`}>
      {emptyStateData.imageClassName ? (
        <div className={`empty-state__image ${emptyStateData.imageClassName}`}></div>
      ) : (
        ''
      )}
      <div className="empty-state__title">{emptyStateData.title}</div>
      <div
        className="empty-state__content"
        dangerouslySetInnerHTML={{ __html: emptyStateData.content }}
      />
    </div>
  );
};

export default EmptyState;
