import React from 'react';
import './FaqPage.scss';
import Title from '../../shared/components/title/Title';
import { useTranslation } from 'react-i18next';

const FaqPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Title mainCategory={t('faq')} />
      <div className="page-content page-content--faq">
        <div className="page-box">
          <div className="page-box__title-action">
            <div className="page-box__title">{t('whatIsChatBeat')}</div>
          </div>
          <div
            className="page-box__content"
            dangerouslySetInnerHTML={{ __html: t('whatIsChatBeatDesc') }}
          />
          <div className="page-box__title-action">
            <div className="page-box__title">{t('howDoesChatBeatEstimateBrandVisibility')}</div>
          </div>
          <div
            className="page-box__content"
            dangerouslySetInnerHTML={{ __html: t('howDoesChatBeatEstimateBrandVisibilityDesc') }}
          />
          <div className="page-box__title-action">
            <div className="page-box__title">{t('whatIsBrandScore')}</div>
          </div>
          <div
            className="page-box__content"
            dangerouslySetInnerHTML={{ __html: t('whatIsBrandScoreDesc') }}
          />
          <div className="page-box__title-action">
            <div className="page-box__title">{t('canIViewTheRawResponse')}</div>
          </div>
          <div
            className="page-box__content"
            dangerouslySetInnerHTML={{ __html: t('canIViewTheRawResponseDesc') }}
          />
          <div className="page-box__title-action">
            <div className="page-box__title">{t('howIsMyBrandsPosition')}</div>
          </div>
          <div className="page-box__content">{t('howIsMyBrandsPositionDesc')}</div>
          <div className="page-box__title-action">
            <div className="page-box__title">{t('iAskedChatGptaQuestion')}</div>
          </div>
          <div className="page-box__content">{t('iAskedChatGptaQuestionDesc')}</div>
          <div className="page-box__title-action">
            <div className="page-box__title">{t('whichModels')}</div>
          </div>
          <div className="page-box__content">{t('whichModelsDesc')}</div>
        </div>
      </div>
    </>
  );
};

export default FaqPage;
