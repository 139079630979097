import React, { useEffect, useState } from 'react';
import './BillingDataPage.scss';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useForm } from 'react-hook-form';
import { UserDataResponse } from '../../../shared/models/user/data/UserDataResponse';
import Loader from '../../../shared/components/common/loader/Loader';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useTranslation } from 'react-i18next';
import { getBillingData, saveBillingData } from './services/BillingDataService';
import { useAccount } from '../../../shared/contexts/account/AccountContexts';
import { useNavigate } from 'react-router-dom';
import { isAdminOrOwner } from '../../../shared/utils/utils';

const BillingDataPage: React.FC = () => {
  const { t } = useTranslation();
  const { accountResponse } = useAccount();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserDataResponse | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  if (accountResponse && !isAdminOrOwner(accountResponse.role)) {
    navigate('/settings/my-account');
  }

  const onSubmit = async (formData: any) => {
    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      addressLine1: formData.line1,
      addressLine2: formData.line2,
      nip: formData.nip,
      city: formData.city,
      zipCode: formData.zipCode,
      country: formData.country,
      state: formData.state,
    } as UserDataResponse;

    saveBillingData(data).then(() => billingDataChangedDialog());
  };

  useEffect(() => {
    updateData();
  }, []);

  const updateData = () => getBillingData().then((data) => setUserData(data));

  const billingDataChangedDialog = () => {
    confirmDialog({
      message: 'Your data has been successfully changed.',
      header: 'Data Changed',
      defaultFocus: 'accept',
      acceptLabel: 'OK',
      accept: () => updateData(),
    });
  };

  if (!userData) {
    return <Loader />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="billing-data-page">
      <ConfirmDialog className="dialog-box dialog-box--accept" />

      <div className="billing-data-page__row">
        <div className="form">
          <div className="form__row">
            <div className="form__label">{t('firstName')}:</div>
            <InputText
              {...register('firstName', { required: 'First name is required' })}
              type="text"
              defaultValue={userData.firstName}
              className={errors.firstName ? 'form__invalid' : ''}
            />
            {errors.firstName && <span>{errors.firstName.message as string}</span>}
          </div>
          <div className="form__row">
            <div className="form__label">{t('lastName')}:</div>
            <InputText
              {...register('lastName', { required: 'Last name is required' })}
              type="text"
              defaultValue={userData.lastName}
              className={errors.lastName ? 'form__invalid' : ''}
            />
            {errors.lastName && <span>{errors.lastName.message as string}</span>}
          </div>
          <div className="form__row">
            <div className="form__label">{t('emailAddress')}:</div>
            <InputText
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: 'Please enter a valid email address',
                },
              })}
              defaultValue={userData.email}
              type="text"
              className={errors.email ? 'form__invalid' : ''}
            />
            {errors.email && <span>{errors.email.message as string}</span>}
          </div>
          <div className="form__row">
            <div className="form__label">{t('line1')}:</div>
            <InputText
              {...register('line1', { required: 'Line 1 is required' })}
              defaultValue={userData.addressLine1}
              type="text"
              className={errors.line1 ? 'form__invalid' : ''}
            />
            {errors.line1 && <span>{errors.line1.message as string}</span>}
          </div>
          <div className="form__row">
            <div className="form__label">{t('line2')}:</div>
            <InputText
              {...register('line2')}
              defaultValue={userData.addressLine2}
              type="text"
              className={errors.line2 ? 'form__invalid' : ''}
            />
            {errors.line2 && <span>{errors.line2.message as string}</span>}
          </div>
          <div className="form__row">
            <div className="form__label">{t('zipCode')}:</div>
            <InputText
              {...register('zipCode', { required: 'Zip code is required' })}
              defaultValue={userData.zipCode}
              type="text"
              className={errors.zipCode ? 'form__invalid' : ''}
            />
            {errors.zipCode && <span>{errors.zipCode.message as string}</span>}
          </div>
          <div className="form__row">
            <div className="form__label">{t('city')}:</div>
            <InputText
              {...register('city', { required: 'City is required' })}
              defaultValue={userData.city}
              type="text"
              className={errors.city ? 'form__invalid' : ''}
            />
            {errors.city && <span>{errors.city.message as string}</span>}
          </div>
          <div className="form__row">
            <div className="form__label">{t('country')}:</div>
            <InputText
              {...register('country', { required: 'Country is required' })}
              defaultValue={userData.country}
              type="text"
              className={errors.country ? 'form__invalid' : ''}
            />
            {errors.country && <span>{errors.country.message as string}</span>}
          </div>
          <div className="form__row">
            <div className="form__label">{t('stateProvince')}:</div>
            <InputText
              {...register('state', { required: 'State/Province is required' })}
              defaultValue={userData.state}
              type="text"
              className={errors.state ? 'form__invalid' : ''}
            />
            {errors.state && <span>{errors.state.message as string}</span>}
          </div>
          <div className="form__row">
            <div className="form__label">{t('nip')}:</div>
            <InputText {...register('nip')} defaultValue={userData.nip} type="text" />
          </div>
          <Button className="btn btn--sm" label={t('save')} />
        </div>
        <div className="billing-data-page__help">
          <div className="help-box">
            <label className="help-box__title">{t('changeBillingAddress')}</label>
            <div>
              Update the address where your billing information is sent. Ensure your details are accurate
              to avoid interruptions in service or issues with payment processing. This address will be
              used for invoicing and any related correspondence. Please provide the correct street, city,
              state, and postal code to keep your account up-to-date.
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default BillingDataPage;
