import React, { useEffect, useState } from 'react';
import './AdminUsersPage.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import { getAllUsers } from '../../../shared/services/admin/users/AdminUsersService';
import { UserDataResponse } from '../../../shared/models/admin/users/UserDataResponse';
import { UsersTableData } from './models/UsersTableData';
import Loader from '../../../shared/components/common/loader/Loader';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog, ConfirmDialogReturn } from 'primereact/confirmdialog';
import AdminRegisterUser from './components/AdminRegisterUser';

const AdminUsersPage: React.FC = () => {
  const { t } = useTranslation();
  let confirmRef: ConfirmDialogReturn;

  const [tableData, setTableData] = useState<UsersTableData[]>([]);
  const hideDialog = () => {
    if (confirmRef) {
      confirmRef.hide();
    }

    getAllUsers().then((usersData) => setTableData(prepareTableData(usersData)));
  };
  const addNewAccount = () => {
    confirmRef = confirmDialog({
      message: <AdminRegisterUser afterSave={hideDialog} />,
      header: 'Add new account',
      defaultFocus: 'accept',
      acceptClassName: 'confirm-change-name-btn',
      footer: () => null,
    });
  };

  useEffect(() => {
    getAllUsers().then((usersData) => setTableData(prepareTableData(usersData)));
  }, []);

  const prepareTableData = (usersData: UserDataResponse[]): UsersTableData[] => {
    return usersData.map((data) => {
      return {
        userId: data.id,
        accountId: data.accountId,
        email: data.email,
        status: data.isActive,
        limit: data.accountId,
      };
    });
  };

  if (!tableData) {
    return <Loader />;
  }

  return (
    <div className="settings">
      <ConfirmDialog className="dialog-box" />

      <div className="settings__action-panel">
        <Button className="btn btn--sm" label="Add new account" onClick={(event) => addNewAccount()} />
      </div>
      <DataTable
        stripedRows
        paginator
        paginatorClassName="pagination"
        removableSort
        sortField="userId"
        sortOrder={1}
        rows={10}
        rowsPerPageOptions={[10, 25, 50]}
        value={tableData}
        tableStyle={{ minWidth: '50rem' }}
        tableClassName="table"
        className="table-wrapper"
      >
        <Column
          field="userId"
          header="User ID"
          headerClassName="table__header table__header--center"
          bodyClassName="table__cell--center"
          style={{ width: '45px' }}
        />
        <Column
          field="accountId"
          header="Account ID"
          headerClassName="table__header table__header--center"
          bodyClassName="table__cell--center"
          style={{ width: '60px' }}
        />
        <Column
          field="email"
          header="User name"
          headerClassName="table__header"
          style={{ minWidth: '300px' }}
        />
        <Column
          field="status"
          header="Account status"
          headerClassName="table__header table__header--center"
          bodyClassName="table__cell--center"
          style={{ width: '120px' }}
        />
        <Column
          field="limit"
          header="Limit"
          headerClassName="table__header table__header--center"
          bodyClassName="table__cell--center"
          style={{ width: '80px' }}
        />
        <Column
          header="Actions"
          headerClassName="table__header table__header--center"
          style={{ width: '120px' }}
        />
      </DataTable>
    </div>
  );
};

export default AdminUsersPage;
