import React from 'react';
import './Tabs.scss';
import { Link } from 'react-router-dom';
import { TabsProps } from './models/TabsModel';

const Tabs: React.FC<TabsProps> = ({ additionalClass, items }) => {
  return (
    <div className={`tabs ${additionalClass}`}>
      {items &&
        items.map((item, index) => (
          <Link
            to={item.url}
            className={`tabs__item ${item.isActive && 'tabs__item--active'}`}
            key={`tab-${item.label}-${index}`}
          >
            {item.icon && <span className="material-symbols-outlined">{item.icon}</span>}
            {item.label}
          </Link>
        ))}
    </div>
  );
};

export default Tabs;
