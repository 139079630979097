import React from 'react';
import './SubscriptionPage.scss';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { useAccount } from '../../../shared/contexts/account/AccountContexts';
import { useNavigate } from 'react-router-dom';
import { isAdminOrOwner } from '../../../shared/utils/utils';

const SubscriptionPage: React.FC = () => {
  const { accountResponse } = useAccount();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (accountResponse && !isAdminOrOwner(accountResponse.role)) {
    navigate('/settings/my-account');
    return <></>
  }

  return (
    <div className="subscription-page">
      <div>
        <div className="subscription-page__row">
          <div>Account status:</div>
          <div>Active</div>
        </div>
        <div className="subscription-page__row">
          <div>Plan:</div>
          <div>Enterprise</div>
        </div>
        <div className="subscription-page__row">
          <div>Start date:</div>
          <div>2024-08-13</div>
        </div>
        <div className="subscription-page__row">
          <div>Renewal date:</div>
          <div>2024-09-13</div>
        </div>
        <div className="subscription-page__row">
          <div>Next payment:</div>
          <div>2024-09-13</div>
        </div>
        <div className="subscription-page__row">
          <div>Estimated costs:</div>
          <div>$0.00</div>
        </div>
        <div className="subscription-page__row">
          <div>Payment method:</div>
          <div>Visa **12</div>
        </div>
        <div className="subscription-page__row">
          <div>Actions:</div>
          <div>
            <Button className="btn btn--sm btn--red" label="Cancel subscription" />
          </div>
        </div>
      </div>
      <div className="subscription-page__help">
        <div className="help-box">
          <label className="help-box__title">{t('currentSubscription')}</label>
          <div>{t('currentSubscriptionDesc')}</div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPage;
