import React from 'react';
import './PricingPage.scss';
import { Button } from 'primereact/button';
import Title from '../../shared/components/title/Title';
import { useTranslation } from 'react-i18next';

const PricingPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Title mainCategory={t('pricing')} />
      <div className="pricing-page page-content">
        <div className="pricing-page__content">
          <div className="price-box">
            <div className="price-box__content">
              <label className="price-box__title">Startup</label>
              <div className="price-box__period-price">
                <span className="price-box__price">$XXX</span>
                <span className="price-box__period">/month</span>
              </div>
              <div className="price-box__desc">
                lorem ipsum lorem lorem ipsum lorem lorem ipsum lorem lorem ipsum lorem lorem ipsum lorem
                lorem ipsum lorem lorem ipsum
              </div>
              <ul>
                <li>lorem ipsum</li>
                <li>lorem ipsum</li>
                <li>lorem ipsum</li>
                <li>lorem ipsum</li>
              </ul>
            </div>
            <Button className="btn btn--secondary" label="Monthly billing" />
          </div>

          <div className="price-box price-box--promo">
            <div className="price-box__content">
              <label className="price-box__title">Business</label>
              <div className="price-box__period-price">
                <span className="price-box__price">$XXX</span>
                <span className="price-box__period">/month</span>
              </div>
              <div className="price-box__desc">
                lorem ipsum lorem lorem ipsum lorem lorem ipsum lorem lorem ipsum lorem lorem ipsum lorem
                lorem ipsum lorem lorem ipsum
              </div>
              <ul>
                <li>lorem ipsum</li>
                <li>lorem ipsum</li>
                <li>lorem ipsum</li>
                <li>lorem ipsum</li>
                <li>lorem ipsum</li>
              </ul>
            </div>
            <Button label="Monthly billing" />
          </div>

          <div className="price-box">
            <div className="price-box__content">
              <label className="price-box__title">Enterprise</label>
              <div className="price-box__period-price">
                <span className="price-box__price">$XXX</span>
                <span className="price-box__period">/month</span>
              </div>
              <div className="price-box__desc">
                lorem ipsum lorem lorem ipsum lorem lorem ipsum lorem lorem ipsum lorem lorem ipsum lorem
                lorem ipsum lorem lorem ipsum
              </div>
              <ul>
                <li>lorem ipsum</li>
                <li>lorem ipsum</li>
                <li>lorem ipsum</li>
                <li>lorem ipsum</li>
                <li>lorem ipsum</li>
                <li>lorem ipsum</li>
                <li>lorem ipsum</li>
              </ul>
            </div>
            <Button className="btn btn--secondary" label="Monthly billing" />
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingPage;
