import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      noPromptsYet: 'No prompts Yet',
      noPromptsYetMsg:
        'It looks like you haven\'t set up any monitors yet. Prompts help you keep track of your brand\'s position and share of voice across various queries. Start by adding a prompt to begin tracking the metrics that matter most to your business.<br><br>Click "Add new prompt" to get started and stay informed with real-time insights tailored to your needs.',
      addNewQuestion: 'Add new question',
      upgradeNow: 'Upgrade now',
      upgradePlanTrialMsg: 'Upgrade now for premium features and better performance!',
      questions: 'Questions',
      integrations: 'Integrations',
      pricing: 'Pricing',
      settings: 'Settings',
      logout: 'Logout',
      myAccount: 'My account',
      changePassword: 'Change password',
      subscription: 'Subscription',
      orders: 'Orders',
      team: 'Team',
      changeYourName: 'Change Your Name',
      changeNameMsg:
        'You can update your first and last name as they appear on your account. Ensure the information is accurate, as it will be used for communication, invoicing, and legal documents. Once updated, your new name will reflect across all areas of your account. Please double-check the spelling before saving any changes.',
      changeBillingAddress: 'Change your Billing Address',
      firstName: 'First name',
      lastName: 'Last name',
      emailAddress: 'Email address',
      line1: 'Line 1',
      line2: 'Line 2',
      city: 'City',
      zipCode: 'Zip code',
      country: 'Country',
      stateProvince: 'State/Province',
      save: 'Save',
      interval: 'Interval',
      prompt: 'Prompt',
      addNew: 'Add new',
      remove: 'Remove',
      manageUser: 'Manage user',
      manageUserMsg:
        'User management allows you to view and manage user details such as first name, last name, and email. You can also remove users from the system if needed.',
      availableIntegrations: 'Available integrations',
      chatGPTDesc:
        'ChatGPT is an AI language model by OpenAI that generates human-like text responses, useful for answering questions, conversations, and various text-based tasks.',
      claudeDesc:
        'Claude is an AI chatbot developed by Anthropic, designed for natural language  understanding and conversation. It focuses on safety and providing helpful, clear responses.',
      geminiDesc:
        'Google Gemini is an AI model by Google that processes and generates text and images, enhancing search and productivity tools.',
      googleSheetsDesc:
        'Google Sheets is a web-based spreadsheet tool by Google that allows users to create, edit, and share spreadsheets online. It supports real-time collaboration and is part of Google Workspace.',
      newPassword: 'New password',
      rePassword: 'Re-password',
      comingSoon: 'Coming soon',
      whatAreIntegrationsInApp: 'What are integrations in our app?',
      integrationDesc:
        "Integrations in our app allow you to connect with external tools like ChatGPT, Google Gemini and Claude. This enables you to access the additional features these tools offer directly within our app. Integrations help automate processes, analyze data, generate content, and streamline collaboration, allowing you to manage information all in one place. It's a solution that enhances and expands the app’s capabilities, making your work more efficient.",
      changePasswordDesc:
        'In this section, you can update your account password for better security. Please ensure that your new password is at least 6 characters long and difficult to guess. After successfully changing your password, you will be automatically logged out for security reasons, and you will need to log in again with your new password.',
      daily: 'Daily',
      weekly: 'Weekly',
      currentSubscription: 'Current subscription',
      currentSubscriptionDesc:
        'In this section, you can review the details of your current subscription plan. You will find information about the features offered, usage limits, and the benefits available within your plan. You can also compare different plans to understand which additional features may be beneficial for you. If you feel you need more capabilities or better access to advanced features, consider the option to upgrade your plan. Make sure you are taking full advantage of everything our app has to offer!',
      billingData: 'Billing data',
      nip: 'VAT ID',
      role: 'Role',
      admin: 'Admin',
      owner: 'Owner',
      user: 'User',
      selectRole: 'Select role',
      users: 'Users',
      dashboard: 'Dashboard',
      inviteUser: 'Invite user',
      inviteUserDesc:
        'The invite users feature allows you to send invitations to others, enabling them to share access to your account. This makes collaboration easier by giving team members the ability to work within the same account.',
      invite: 'Invite',
      dailyFilter: 'Daily',
      weeklyFilter: 'Weekly',
      monthlyFilter: 'Monthly',
      browserData: 'Browser data',
      apiData: 'API data',
      brandPositioningOverview: 'Brand Positioning Overview',
      stats: 'Stats',
      brandVsTop5: 'Brand vs Top 5',
      averagePosition: 'Average Position',
      trends: 'Trends',
      selectBrandForMonitoring: 'Select a Brand for Monitoring',
      orWriteCustomName: 'or write custom name',
      exploreAIAnswers: 'Explore AI Answers',
      date: 'Date',
      addFirm: 'Add firm',
      everyDay: 'Every day',
      everyWeek: 'Every week',
      action: 'Action',
      created: 'Created',
      keyword: 'Keyword',
      loginToYourAccount: 'Login to your account',
      forgotPassword: 'Forgot password',
      password: 'Password',
      dontHaveAnAccount: "Don't have an account",
      signUp: 'Sign Up',
      signIn: 'Sign In',
      alreadyHaveAccount: 'Already have account',
      crateAnAccount: 'Create an account',
      passwordReset: 'Password reset',
      passwordResetDesc: 'Enter your account email address and we will send you a password reset link.',
      continue: 'Continue',
      back: 'Back',
      language: 'Language',
      polish: 'Polish',
      english: 'English',
      estimatedTraffic: 'Estimated traffic',
      monitoringQuestions: 'Monitoring questions',
      setNewPassword: 'Set new password',
      isRequired: 'is required',
      fieldIsRequired: 'Field is required',
      loginPageTitle: 'Stay Ahead with Chatbeat',
      loginPageDesc:
        'Monitor your brand mentions and track competitors with AI-powered analytics. Sign up now and discover how Chatbeat can help you stay ahead of the competition!',
      wrongEmailAddress: 'Please enter a valid email address',
      signupPageTitle: 'Unlock Powerful Insights with Chatbeat',
      signupPageDesc:
        'Join Chatbeat today and leverage AI-driven analytics to monitor your brand’s presence. Sign up now to track real-time mentions and outshine the competition with data that matters!',
      next: 'Next',
      addCustomQuestions: 'Add custom questions',
      saveAndLaunch: 'Save & Launch',
      chooseQuestions: 'Choose questions',
      projects: 'Projects',
      activeProjects: 'Active projects',
      addNewProject: 'Add new project',
      popularQuestionsFor: 'Popular questions for',
      trafficAndAveragePosition: 'Traffic & Average Position',
      brandScoreAndAveragePosition: 'Brand Score & Average Position',
      brandScore: 'Brand Score',
      shareOfVoice: 'Share of Voice',
      averageShareOfVoice: 'Average Share of Voice',
      traffic: 'Traffic',
      visibility: 'Visibility',
      projectName: 'Project name',
      addNewProjectHelp: 'Adding a New Question to Your Project',
      addNewProjectHelpDesc:
        'In the Add New Question section, you can configure automated questions for your project. The Query field allows you to define the question that will be used to interact with the chatbot. Additionally, each question added in this section will be assigned to the project.',
      backToProjects: 'Back to projects',
      loadingDataPleaseWait: 'Loading data, please wait...',
      gatheringInformation: 'Gathering information...',
      rangeTooltip:
        'Defines the range of data displayed on the chart. Adjusts the start and end points of the data shown to focus on a specific time period or data segment.',
      position: 'Position',
      wrongDomain: 'Invalid domain',
      gatheringStatsForThisRecord: 'Generating statistics for this company',
      noDataAvailable: 'No data available',
      projectNoDataAvailableDesc:
        'There is currently no data to display for Traffic & Share of Voice. Please adjust your filters or try again later to see updated results.',
      noShareOfVoiceData: 'Data is on the Way',
      noShareOfVoiceDataDesc:
        "We're working on gathering insights. This chart will show your brand's share of voice once the data is ready.",
      noPositioningData: 'No Positioning Data Yet',
      noPositioningDataDesc:
        "Once insights are collected, this graph will showcase your brand's market positioning.",
      noAIData: 'Awaiting Data',
      noAIDataDesc:
        'There is currently no data to display for your AI Visibility. Make sure your data sources are connected and providing the latest information, and the chart will populate soon.',
      thisCompanyAlreadyExist: 'This company is already on the list',
      all: 'All',
      gpt: 'GPT 4o',
      gptApi: 'GPT 4o mini',
      claudeApi: 'Claude',
      geminiFlash: 'Gemini Flash',
      geminiFlashPro: 'Gemini Flash Pro',
      bing: 'Bing',
      projectDailyStatsMigration: 'Daily Stats Migration',
      statsMigration: 'Stats Migration',
      unlockFeature:
        'If you want to unlock this feature, <a href="mailto:help@chatbeat.com">contact&nbsp;us</a>',
      reachedLimit:
        'You\'ve reached your limit or don\'t have the necessary permissions to perform this action. Please  <a href="mailto:help@chatbeat.com">contact&nbsp;us</a> to unlock this feature.',
      areYouSureProceed: 'Are you sure you want to proceed?',
      areYouSure: 'Are you sure?',
      tooltipAveragePosition:
        'The average position of the keyword in the text, indicating its typical placement relative to other keywords.',
      tooltipShareOfVoice:
        'The percentage of total market exposure your brand has compared to competitors within a specific industry or channel, measured over a selected time period.',
      tooltipTrends:
        'Displays the trend of the average keyword position from the start to the end of the specified range. Tracks how the average position has changed over time',
      tooltipKeyword:
        'Select a brand or keyword to monitor. If your brand is not listed, you can enter a new one to start tracking.',
      gptName: 'GPT',
      geminiName: 'Gemini',
      claudeName: 'Claude',
      deepSeekName: 'DeepSeek',
      improveAIRanking: 'Improve AI Ranking',
      refreshAllStats: 'Refresh all stats',

      // ADMIN
      requestCount: 'Requests',
      monitorId: 'Monitor',
      lp: 'No.',
      activeMonitors: 'Active monitors',
      dailyMonitorRequest: 'Daily request',
      activeUsers: 'Active users',
      semrushAPI: 'Semrush API',
      gptAPI: 'GPT API',
      gptBrowser: 'GPT Browser',
      geminiAPI: 'Gemini API',
      geminiBrowser: 'Gemini browser',
      faq: 'FAQ',
      whatIsChatBeat: 'What is Chatbeat?',
      whatIsChatBeatDesc:
        'Chatbeat is a tool for measuring the exposure and visibility of your brand within major AI models like ChatGPT, Gemini, or Claude. It analyzes how your brand performs in responses to questions where it’s compared to competitors in your industry. Using a proprietary visibility estimation algorithm, ChatBeat helps you understand how your brand’s exposure evolves over time and tracks the sentiment associated with your brand in these large AI models.<br><br>ChatBeat does for ChatGPT what tools like Semrush or Ahrefs do for Google.',
      howDoesChatBeatEstimateBrandVisibility: 'How Does Chatbeat Estimate Brand Visibility?',
      howDoesChatBeatEstimateBrandVisibilityDesc:
        'It’s important to clarify that Chatbeat doesn’t aim to estimate the exact number of visits ChatGPT generates for your domain. Instead, it focuses on how your brand performs relative to competitors—whether its prominence within AI models is increasing or decreasing.<br><br>The foundation of the algorithm behind the AI Visibility lies in the position your brand holds in response to questions that compare it to others in your industry. Much like keyword tracking tools monitor rankings on Google, ChatBeat tracks key questions that users might ask in ChatGPT. How do we determine which questions are key? We predict this based on the analysis of organic traffic surrounding your brand.<br><br>However, ranking for selected questions isn’t the only factor in estimating your brand’s visibility within models like GPT. The algorithm also considers elements such as the value of traffic on your brand’s main domain and the overall traffic volume generated by ChatGPT itself.',
      whatIsBrandScore: 'What is Brand Score?',
      whatIsBrandScoreDesc:
        'Brand Score is a metric that evaluates how prominently a brand appears in AI-driven search results. It provides a percentage score reflecting the brand’s presence and ranking across various queries. The score is calculated based on the brand’s position in search results, with higher ranks earning more points. For example, a brand that ranks consistently at the top will achieve a score of 100%, indicating excellent visibility.',
      whatIsShareOfVoice: 'What is Share of Voice?',
      whatIsShareOfVoiceDesc:
        'Share of Voice in Chatbeat represents how prominently your brand appears in response to selected queries. This score is expressed as a percentage, with higher rankings contributing more significantly to your brand’s visibility. For example, the first position accounts for 40%, the second for 20%, the third for 10%, and so on. The higher your brand ranks, the greater its AI Visibility. The total Share of Voice for all brands visible in response to a specific query always sums up to 100%.',
      canIViewTheRawResponse: 'Can I View the Raw Responses Provided by GPT?',
      canIViewTheRawResponseDesc:
        'Absolutely. Simply click on any question, and at the bottom of the page, you can review the exact responses we received from the GPT model (or another monitored AI model).',
      howIsMyBrandsPosition: 'How is My Brand’s Position for a Specific Question Calculated?',
      howIsMyBrandsPositionDesc:
        'Chatbeat analyzes responses received from GPT (or other models) and calculates the average from 10 daily queries for each monitored question. This approach smooths out fluctuations that might occur with individual queries, allowing you to observe broader trends.',
      iAskedChatGptaQuestion:
        'I Asked ChatGPT a Question, and My Brand’s Position Was Different from What Chatbeat Shows. Why?',
      iAskedChatGptaQuestionDesc:
        'Results in ChatGPT can vary across individual queries. That’s why Chatbeat conducts approximately 10 queries per question daily. Additionally, responses in your personal ChatGPT account may be influenced by personalization based on your query history. If a particular brand frequently appears in your interactions with ChatGPT, it’s more likely that ChatGPT will recommend it to you.',
      whichModels: 'Which Models Does Chatbeat Monitor?',
      whichModelsDesc:
        'Currently, Chatbeat monitors ChatGPT, but support for other popular models like Gemini, Claude, and more will be available soon.',
      notVisible: 'Not Visible',
      minimal: 'Minimal',
      emerging: 'Emerging',
      good: 'Good',
      strong: 'Strong',
      excellent: 'Excellent',
      topPerformer: 'Top Performer',
      notVisibleTooltip: 'The brand has no measurable visibility in the rankings.',
      minimalTooltip: 'Visibility is extremely low and requires urgent improvement.',
      emergingTooltip:
        'Visibility is starting to develop but remains very limited; more efforts are needed to build presence.',
      goodTooltip: 'The brand has noticeable visibility, but there’s potential for further improvement.',
      strongTooltip: 'Visibility is solid and well above average.',
      excellentTooltip:
        'The brand demonstrates exceptional visibility, with minor optimization opportunities.',
      topPerformerTooltip: 'The brand is a leader in visibility, showcasing unmatched performance.',
      regenerateKeywords: 'Regenerate',
      yourDataChanged: 'Your data has been successfully changed.',
      dataChanged: 'Data Changed',
      outsideTop30: 'Outside Top 30',
      others: 'Others',
      mixedQuestionWarning:
        'At least one question in the project is monitoring the brand model. Unlink the selected projects if you want to monitor the brand.',
      changeProject: 'Move to another project',
      cancel: 'Cancel',
      withoutAProject: 'Without a project',
      changeProjectName: 'Change the project name',
      whereShouldISetTheKeyword: 'Where should I set the keyword?',
      applyToAllQuestions: 'Apply to all questions',
      applyToPartQuestions:
        'Apply to questions that currently have a keyword set, such as the project name',
      mixedWarning:
        'This question monitors the model, which may cause the information in the project to be imprecise. If you want to monitor the brand, disconnect this question from the project.',
      changeNameTootlip:
        'By default, all questions in the project have the project name set as the keyword.',
      deepSeekDesc:
        'DeepSeek is an advanced technology that uses artificial intelligence for precise data search and analysis. It enables fast discovery of hidden insights in complex datasets, supporting decision-making and process optimization.',
      noResultsFound: 'No results found',
      noResultsFoundDesc: 'Adjust your filters and try again.',
    },
  },
  pl: {
    translation: {
      noPromptsYet: 'Brak dostępnych pytań',
      noPromptsYetMsg:
        'Wygląda na to, że jeszcze nie dodałeś żadnych monitorów. Podpowiedzi pomagają śledzić pozycję Twojej marki oraz udział w różnych zapytaniach. Zacznij od dodania podpowiedzi, aby rozpocząć monitorowanie najważniejszych metryk dla Twojego biznesu.<br><br>Kliknij "Dodaj nowe zapytanie", aby rozpocząć i być na bieżąco dzięki spersonalizowanym informacjom w czasie rzeczywistym.',
      addNewQuestion: 'Dodaj nowe pytanie',
      upgradeNow: 'Zmień plan teraz',
      upgradePlanTrialMsg:
        'Zmień plan na wyższy, aby uzyskać dostęp do funkcji premium i lepszej wydajności!',
      questions: 'Pytania',
      integrations: 'Integracje',
      pricing: 'Cennik',
      settings: 'Ustawienia',
      logout: 'Wyloguj',
      myAccount: 'Moje konto',
      changePassword: 'Zmień hasło',
      subscription: 'Subskrypcja',
      orders: 'Zamówienia',
      team: 'Zespół',
      changeYourName: 'Zmień swoje imię i nazwisko',
      changeNameMsg:
        'Możesz zaktualizować swoje imię i nazwisko, tak jak są one widoczne na Twoim koncie. Upewnij się, że informacje są poprawne, ponieważ będą one używane do komunikacji, fakturowania i dokumentów prawnych. Po dokonaniu zmiany, nowe dane będą widoczne we wszystkich obszarach Twojego konta. Proszę dokładnie sprawdzić pisownię przed zapisaniem jakichkolwiek zmian.',
      changeBillingAddress: 'Zmień swój adres rozliczeniowy',
      firstName: 'Imię',
      lastName: 'Nazwisko',
      emailAddress: 'Adres e-mail',
      line1: 'Adres',
      line2: 'Adres cd.',
      city: 'Miasto',
      zipCode: 'Kod pocztowy',
      country: 'Kraj',
      stateProvince: 'Województwo',
      save: 'Zapisz',
      interval: 'Interwał',
      prompt: 'Zapytanie',
      addNew: 'Dodaj nowy',
      remove: 'Usuń',
      manageUser: 'Zarządzaj użytkownikiem',
      estimatedTraffic: 'Szacowany traffic',
      outsideTop30: 'Poza pierwszą 30-tką',
      manageUserMsg:
        'Zarządzanie użytkownikami umożliwia przeglądanie i edytowanie szczegółów użytkownika, takich jak imię, nazwisko i adres e-mail. Możesz również usunąć użytkowników z systemu w razie potrzeby.',
      availableIntegrations: 'Dostępne integracje',
      chatGPTDesc:
        'ChatGPT to model językowy AI stworzony przez OpenAI, który generuje teksty przypominające ludzkie odpowiedzi. Jest przydatny do odpowiadania na pytania, prowadzenia rozmów oraz realizacji różnych zadań związanych z tekstem.',
      claudeDesc:
        'Claude to chatbot AI stworzony przez firmę Anthropic, zaprojektowany do rozumienia języka naturalnego i prowadzenia rozmów. Skupia się na bezpieczeństwie oraz dostarczaniu pomocnych i klarownych odpowiedzi.',
      geminiDesc:
        'Google Gemini to model AI opracowany przez firmę Google, który przetwarza i generuje tekst oraz obrazy, wzbogacając narzędzia do wyszukiwania i zwiększając wydajność.',
      googleSheetsDesc:
        'Google Sheets to internetowe narzędzie do arkuszy kalkulacyjnych stworzone przez firmę Google, które umożliwia użytkownikom tworzenie, edytowanie i udostępnianie arkuszy kalkulacyjnych online. Obsługuje współpracę w czasie rzeczywistym i jest częścią Google Workspace.',
      newPassword: 'Nowe hasło',
      rePassword: 'Powtórz hasło',
      comingSoon: 'Wkrótce',
      whatAreIntegrationsInApp: 'Czym są integracje w naszej aplikacji?',
      integrationDesc:
        'Integracje w naszej aplikacji pozwalają na połączenie z zewnętrznymi narzędziami, takimi jak ChatGPT, Google Gemini i Claude. Umożliwia to bezpośredni dostęp do dodatkowych funkcji, które te narzędzia oferują w ramach naszej aplikacji. Integracje pomagają automatyzować procesy, analizować dane, generować treści i usprawniać współpracę, co pozwala na zarządzanie informacjami w jednym miejscu. To rozwiązanie zwiększa i rozszerza możliwości aplikacji, czyniąc Twoją pracę bardziej efektywną.',
      changePasswordDesc:
        'W tej sekcji możesz zaktualizować swoje hasło do konta w celu poprawy bezpieczeństwa. Upewnij się, że Twoje nowe hasło ma co najmniej 6 znaków i jest trudne do odgadnięcia. Po pomyślnym zmianie hasła zostaniesz automatycznie wylogowany z powodów bezpieczeństwa i będziesz musiał zalogować się ponownie za pomocą nowego hasła.',
      daily: 'Codzienne',
      weekly: 'Weekendowe',
      currentSubscription: 'Obecny plan',
      currentSubscriptionDesc:
        'W tej sekcji możesz sprawdzić szczegóły swojego obecnego planu subskrypcyjnego. Zobaczysz informacje na temat oferowanych funkcji, limitów oraz korzyści, które są dostępne w ramach Twojego planu. Możesz również porównać różne plany, aby zrozumieć, które z dodatkowych funkcji mogą być dla Ciebie przydatne. Jeśli czujesz, że potrzebujesz więcej możliwości lub lepszego dostępu do zaawansowanych funkcji, rozważ możliwość aktualizacji swojego planu. Upewnij się, że korzystasz z pełni możliwości, które nasza aplikacja ma do zaoferowania!',
      billingData: 'Dane rozliczeniowe',
      nip: 'NIP',
      role: 'Rola',
      selectRole: 'Wybierz role',
      admin: 'Administrator',
      owner: 'Właściciel',
      user: 'Użytkownik',
      users: 'Użytkownicy',
      dashboard: 'Dashboard',
      noResultsFound: 'Brak wyników',
      noResultsFoundDesc: 'Dostosuj filtry i spróbuj ponownie.',
      inviteUser: 'Zaproś użytkownika',
      inviteUserDesc:
        'Funkcja zapraszania użytkowników umożliwia wysyłanie zaproszeń do innych osób, dając im dostęp do Twojego konta. Ułatwia to współpracę, pozwalając członkom zespołu na wspólne działanie w ramach tego samego konta.',
      invite: 'Zaproś',
      dailyFilter: 'Dzienne',
      weeklyFilter: 'Tygodniowe',
      monthlyFilter: 'Miesięczne',
      browserData: 'Dane z przeglądarki',
      apiData: 'Dane z API',
      brandPositioningOverview: 'Przegląd Pozycjonowania Marki',
      stats: 'Statystyki',
      averagePosition: 'Średnia pozycja',
      averageShareOfVoice: 'Średnia Share of Voice',
      trends: 'Trend',
      selectBrandForMonitoring: 'Wybierz markę do śledzenia',
      orWriteCustomName: 'lub wpisz własną frazę',
      exploreAIAnswers: 'Przeglądaj odpowiedzi AI',
      date: 'Data',
      addFirm: 'Dodaj firmę',
      everyDay: 'Każdego dnia',
      everyWeek: 'W weekend',
      action: 'Akcje',
      created: 'Stworzono',
      keyword: 'Słowo kluczowe',
      loginToYourAccount: 'Zaloguj się do swojego konta',
      forgotPassword: 'Zapomniałeś hasła',
      password: 'Hasło',
      dontHaveAnAccount: 'Nie masz konta',
      signUp: 'Zarejestruj',
      signIn: 'Zaloguj',
      alreadyHaveAccount: 'Masz już konto',
      crateAnAccount: 'Załóż konto',
      passwordReset: 'Reset hasła',
      passwordResetDesc: 'Wprowadź adres e-mail swojego konta, a wyślemy Ci link do zresetowania hasła.',
      continue: 'Kontynuj',
      back: 'Wróć',
      language: 'Język',
      polish: 'Polski',
      english: 'Angielski',
      monitoringQuestions: 'Monitorowane pytania',
      setNewPassword: 'Ustaw nowe hasło',
      isRequired: 'jest wymagane',
      fieldIsRequired: 'Pole jest wymagane',
      loginPageTitle: 'Wyprzedź konkurencję z Chatbeat',
      loginPageDesc:
        'Monitoruj wzmianki o swojej marce i śledź konkurencję dzięki analizom AI. Zarejestruj się teraz i odkryj, jak Chatbeat może pomóc Ci wyprzedzić konkurencję!',
      wrongEmailAddress: 'Niepoprawny adres email',
      signupPageTitle: 'Odblokuj potężne dane z Chatbeat',
      signupPageDesc:
        'Dołącz do Chatbeat i korzystaj z analiz wspieranych przez AI, aby monitorować obecność swojej marki. Zarejestruj się już teraz, aby śledzić wzmianki w czasie rzeczywistym i wyprzedzić konkurencję dzięki kluczowym danym!',
      next: 'Dalej',
      addCustomQuestions: 'Dodaj swoje pytanie',
      saveAndLaunch: 'Zapisz i uruchom',
      chooseQuestions: 'Wybierz pytania',
      projects: 'Projekty',
      activeProjects: 'Aktywne projekty',
      addNewProject: 'Dodaj nowy projekt',
      projectName: 'Nazwa projektu',
      addNewProjectHelp: 'Dodawanie Nowego Pytania do Projektu',
      addNewProjectHelpDesc:
        'W sekcji Dodaj Nowe Pytanie możesz skonfigurować automatyczne pytania dla swojego projektu. Pole Zapytanie pozwala określić pytanie, które będzie używane do interakcji z chatbotem. Dodatkowo, każde pytanie dodane w tej sekcji zostanie przypisane do projektu.',
      backToProjects: 'Wróć do projektów',
      loadingDataPleaseWait: 'Ładowanie danych, proszę czekać...',
      gatheringInformation: 'Trwa zbieranie informacji...',
      rangeTooltip: 'Określa zakres danych wyświetlanych na wykresie.',
      position: 'Pozycja',
      wrongDomain: 'Nieprawidłowa domena',
      gatheringStatsForThisRecord: 'Trwa generowanie statystyk dla tej firmy',
      noDataAvailable: 'Brak danych',
      projectNoDataAvailableDesc:
        'Obecnie brak danych do wyświetlenia. Spróbuj zmienić filtry lub sprawdź ponownie później, aby zobaczyć zaktualizowane wyniki.',
      thisCompanyAlreadyExist: 'Ta firma już istnieje',
      all: 'Wszystkie',
      gpt: 'GPT',
      geminiApi: 'Gemini',
      claudeApi: 'Claude',
      deepSeekName: 'DeepSeek',
      reachedLimit:
        'Przekroczyłeś limit lub nie masz odpowiednich uprawnień do wykonania tej akcji. <a href="mailto:help@chatbeat.com">Skontaktuj się z nami</a>, aby odblokować tę funkcję.',
      noShareOfVoiceData: 'Dane są w drodze',
      noShareOfVoiceDataDesc: 'Pracujemy nad zbieraniem informacji. Ten wykres pokaże Share of voice',
      noPositioningData: 'Brak danych o pozycjonowaniu',
      noPositioningDataDesc: 'Gdy zbierzemy dane, ten wykres pokaże pozycję Twojej marki na rynku.',
      noAIData: 'Oczekiwanie na dane',
      noAIDataDesc:
        'Aktualnie brak danych do wyświetlenia dla Twojego wskaźnika widoczności AI. Upewnij się, że Twoje źródła danych są połączone i dostarczają najnowsze informacje, a wykres wkrótce zostanie zaktualizowany.',
      areYouSureProceed: 'Czy na pewno chcesz kontynuować?',
      areYouSure: 'Czy jesteś pewien?',
      deepSeekDesc:
        'DeepSeek to zaawansowana technologia wykorzystująca sztuczną inteligencję do precyzyjnego wyszukiwania i analizy danych. Umożliwia szybkie odkrywanie ukrytych informacji w złożonych zbiorach danych, wspierając podejmowanie decyzji i optymalizację procesów.',
      tooltipAveragePosition:
        'Średnia pozycja słowa kluczowego w tekście, wskazująca jego typowe umiejscowienie względem innych słów kluczowych.',
      tooltipShareOfVoice:
        'Procent całkowitej ekspozycji rynkowej Twojej marki w porównaniu do konkurencji w określonej branży lub kanale, mierzony w wybranym zakresie czasowym.',
      tooltipTrends:
        'Wyświetla trend średniej pozycji słowa kluczowego od początku do końca określonego zakresu. Śledzi, jak średnia pozycja zmieniała się w czasie.',
      tooltipKeyword:
        'Wybierz markę lub słowo kluczowe do monitorowania. Jeśli Twojej marki nie ma na liście, możesz wprowadzić nową, aby rozpocząć śledzenie.',
      faq: 'FAQ',
      whatIsChatBeat: 'Czym jest Chatbeat?',
      whatIsChatBeatDesc:
        'To narzędzie do pomiaru ekspozycji/widoczności Twojej marki w dużych modela AI takich jak ChatGPT, Gemini, czy Claude. Narzędzie analizuje pozycje jakie Twoja marka zajmuje na pytania, w których jest porównywana do innych podmiotów z Twojej branży i wykorzystuje autorski algorytm estymacji widoczności, który pomaga zrozumieć jak ekspozycja marki zmienia się w czasie, ale także sentyment związany z Twoją marką w dużych modelach AI.<br><br>Chatbeat jest dla ChatGPT tym, czym Semrush czy Ahrefs jest dla Google.',
      howDoesChatBeatEstimateBrandVisibility: 'W jaki sposób Chatbeat estymuje widoczność marki?',
      howDoesChatBeatEstimateBrandVisibilityDesc:
        'Na początku należy podkreślić, że celem Chatbeat nie jest jest odgadnięcie dokładnej ilości wizyt, jakie na Twojej domenie generuje ChatGPT, ale raczej tego jak Twoja marka wypada na tle konkurentów. Czy jej prominencja wewnątrz modeli AI rośnie czy maleje.<br><br>Podstawą działania algorytmu stojącego za estymowanych ruchem, czy metryką AI Visibility jest pozycja jaką wybrana marka zajmuje na pytania, które zestawiają ją z innymi podmiotami z jej branży. Podobnie jak w narzędziach do monitorowania pozycji w Google śledzi się słowa kluczowe - w ChatBeat śledzi sę kluczowe pytania, które użytkownicy mogą zadawać w ChatGPT. Skąd wiemy, że wybrane pytania są kluczowe? Staramy się to przewidzieć w oparciu o analizę ruchu organicznego wokół wybranej marki.<br><br>Pozycja na wybrane pytania to jednak nie jedyna składowa, która pomaga nam w estymacji ruchu, czy widoczności Twojej marki wewnątrz modeli takich jak GPT. Algorytm uwzględnia także takie składowe jak wartość ruchu  na głównej domenie związanej z marką, czy wartość ruchu jakim cieszy się sam ChatGPT.\n',
      whatIsBrandScore: 'Co to jest Brand Score?',
      whatIsBrandScoreDesc:
        'Brand Score to wskaźnik oceniający, jak często i wyraźnie marka pojawia się w wynikach wyszukiwania opartych na sztucznej inteligencji. Wyrażany jest w procentach i odzwierciedla obecność oraz pozycjonowanie marki w różnych zapytaniach. Wynik obliczany jest na podstawie pozycji marki w wynikach wyszukiwania – wyższe miejsca przynoszą więcej punktów. Na przykład marka, która konsekwentnie zajmuje najwyższe pozycje, osiągnie wynik 100%, co wskazuje na doskonałą widoczność.',
      whatIsShareOfVoice: 'Co to jest Share of Voice?',
      whatIsShareOfVoiceDesc:
        'Share of Voice w Chatbeat reprezentuje, jak wyraźnie Twoja marka pojawia się w odpowiedzi na wybrane zapytania. Wynik ten wyrażony jest w procentach, przy czym wyższe pozycje mają większy wpływ na widoczność Twojej marki. Na przykład, pierwsza pozycja odpowiada za 40%, druga za 20%, trzecia za 10% i tak dalej. Im wyżej Twoja marka znajduje się w rankingu, tym wyższy jest jej wynik widoczności. Całkowity Share of Voice dla wszystkich marek widocznych w odpowiedzi na konkretne zapytanie zawsze wynosi 100%.',
      canIViewTheRawResponse: 'Czy mogę przeglądać surowe odpowiedzi jakich udzielił GPT?',
      canIViewTheRawResponseDesc:
        'Naturalnie. Wystarczy kliknąć w dowolne pytanie, a następnie w dolnej części strony można przeglądać odpowiedzi jakie uzyskaliśmy od modelu GPT (lub innego).',
      howIsMyBrandsPosition: 'W jaki sposób liczona jest pozycja mojej marki na wybrane pytanie?',
      howIsMyBrandsPositionDesc:
        'Chatbeat analizuje odpowiedzi jakie otrzymał od GPT (lub innego modelu) i wylicza średnią z 10 zapytań jakie codziennie realizujemy dla każdego z monitorowanych pytań. W ten sposób łagodzimy wahania jakie mogą pojawiać się przy pojedynczych zapytaniach do GPT co pozwala dostrzegać trendy.',
      iAskedChatGptaQuestion:
        'Wpisałem pytanie do ChatGPT i pozycja mojej marki była inna niż ta, którą pokazuje Chatbeat. Dlaczego?',
      iAskedChatGptaQuestionDesc:
        'Wyniki wewnątrz ChatGPT mogą się różnić przy pojedynczych zapytaniach. Dlatego codziennie odpytujemy modele blisko 10 razy per pytanie. Co więcej, odpowiedzi jakie uzyskujesz w swoim personalnym koncie ChatGPT są personalizowane w oparciu o historie Twoich zapytań. Jeśli wybrana marka często pojawiała się w Twoich konwersacjach z ChatGPT - jest większa szansa, że ChatGPT poleci Ci tę markę.',
      whichModels: 'Jakie modele monitoruje Chatbeat?',
      whichModelsDesc:
        'W tej chwili monitorujemy ChatGPT, choć wkrótce będzie można monitorować także Gemini, Claude i inne popularne modele.',
      notVisibleTooltip: 'Marka nie ma mierzalnej widoczności w rankingach.',
      minimalTooltip: 'Widoczność jest ekstremalnie niska i wymaga pilnej poprawy.',
      emergingTooltip:
        'Widoczność zaczyna się rozwijać, ale wciąż pozostaje bardzo ograniczona; potrzebne są dalsze wysiłki w celu zbudowania obecności.',
      goodTooltip: 'Marka ma zauważalną widoczność, ale istnieje potencjał do dalszej poprawy.',
      strongTooltip: 'Widoczność jest solidna i znacznie powyżej średniej.',
      excellentTooltip:
        'Marka wykazuje wyjątkową widoczność, z niewielkimi możliwościami optymalizacji.',
      topPerformerTooltip: 'Marka jest liderem w widoczności, prezentując niezrównaną wydajność.',
      regenerateKeywords: 'Zregeneruj firmy',
      yourDataChanged: 'Twoje dane zostały zapisane.',
      dataChanged: 'Zmiana danych',
      popularQuestionsFor: 'Popularne pytania dla',
      others: 'Inne',
      improveAIRanking: 'Poprawa rankingu AI',
      mixedQuestionWarning:
        'Przynajmniej jedno pytanie w projekcie monitoruje model marki. Odepnij zaznaczone projekty, jeśli chcesz monitorować markę.',
      changeProject: 'Przenieś do innego projektu',
      cancel: 'Anuluj',
      withoutAProject: 'Bez projektu',
      changeProjectName: 'Zmień nazwę projektu',
      whereShouldISetTheKeyword: 'Gdzie mam ustawić słowo kluczowe?',
      applyToAllQuestions: 'Zastosuj do wszystkich pytań',
      applyToPartQuestions:
        'Zastosuj do pytań, które mają obecnie ustawione słowo kluczowe, takie jak nazwa projektu',
      mixedWarning:
        'To pytanie monitoruje model, przez co informacje w projekcie mogą być nieprecyzyjne. Jeśli chcesz monitorować markę, odepnij to pytanie od projektu',
      changeNameTootlip:
        'Domyślnie, wszystkie pytania w projekcie mają nazwę projektu ustawioną jako słowo kluczowe.',
      unlockFeature:
        'Jeśli chcesz odblokować tę funkcję, <a href="mailto:help@chatbeat.com">skontaktuj się z nami</a>',
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    detection: {
      order: ['navigator', 'htmlTag', 'localStorage', 'cookie', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
