import { PeriodType } from '../../../../shared/types/prompt/PeriodType';
import { ChartMatrixData } from '../../../../shared/components/stats/models/StatsModel';
import { COLORS_FIRST_SERIES, COLORS_SECOND_SERIES } from '../../../../shared/utils/utils';
import { prepareHeaderDate } from '../../../../shared/components/stats/services/StatsService';
import { ProjectDailyStatsResponse } from '../../../../shared/models/projects/stats/ProjectDailyStatsResponse';
import { ProjectGeneralData } from '../models/ProjectGeneralData';

export const prepareProjectGeneralData = (
  projectDailyStatsRecords: ProjectDailyStatsResponse
): ProjectGeneralData | null => {
  if (!projectDailyStatsRecords) {
    return null;
  }

  let totalPosition = 0;
  let positionCount = 0;

  let totalTraffic = 0;
  let trafficCount = 0;

  let totalVisibilityScore = 0;
  let visibilityCount = 0;

  Object.values(projectDailyStatsRecords).forEach((record) => {
    if (record.averagePosition > 0) {
      totalPosition += Number(record.averagePosition);
      positionCount++;
    }
    if (record.averageEstimatedTraffic > 0) {
      totalTraffic += Number(record.averageEstimatedTraffic);
      trafficCount++;
    }
    if (record.averageVisibilityScore > 0) {
      totalVisibilityScore += Number(record.averageVisibilityScore);
      visibilityCount++;
    }
  });

  const averagePosition = positionCount > 0 ? Number((totalPosition / positionCount).toFixed(1)) : 0;
  const averageTraffic = trafficCount > 0 ? Number((totalTraffic / trafficCount).toFixed(0)) : 0;
  const averageVisibilityScore =
    visibilityCount > 0 ? Number((totalVisibilityScore / visibilityCount).toFixed(2)) : 0;

  return {
    averageTraffic,
    averagePosition,
    averageVisibilityScore,
  };
};

export const createProjectStatsMatrix = (
  periodType: PeriodType,
  projectDailyStatsRecords: ProjectDailyStatsResponse,
  t: (key: string) => string
): ChartMatrixData => {
  if (!projectDailyStatsRecords) {
    return { matrix: [], secondMatrix: [], colorsMap: new Map<string, string>() };
  }

  const matrixEstimatedTraffic: (string | number)[][] = [];
  const headersEstimatedTraffic: string[] = ['date', t('brandScore')];
  const headersCombined: string[] = [
    'date',
    // 'Share of voice',
    // 'AI Visibility score',
    t('averagePosition'),
  ];

  const uniqueDates: Set<string> = new Set(
    Object.keys(projectDailyStatsRecords).map((date) => date.toString())
  );
  const colorsMap = new Map<string, string>([
    [t('brandScore'), COLORS_FIRST_SERIES[0]],
    // ['Share of voice', COLORS_SECOND_SERIES[0]],
    // ['AI Visibility score', COLORS_SECOND_SERIES[2]],
    [t('averagePosition'), COLORS_SECOND_SERIES[0]],
  ]);

  // const shareOfVoiceMap = new Map<string, number>();
  // const aiVisibilityScoreMap = new Map<string, number>();
  const averagePositionScoreMap = new Map<string, number>();

  uniqueDates.forEach((date) => {
    const headerDate = prepareHeaderDate(date, periodType);
    const record = projectDailyStatsRecords[date.toString()];

    const rowEstimatedTraffic: (string | number)[] = [headerDate];
    rowEstimatedTraffic.push(record.averageVisibilityScore ?? (null as unknown as string | number));
    matrixEstimatedTraffic.push(rowEstimatedTraffic);

    // if (record.averageShareOfVoice !== undefined) {
    //   shareOfVoiceMap.set(headerDate, record.averageShareOfVoice);
    // }
    // if (record.averageVisibilityScore !== undefined) {
    //   aiVisibilityScoreMap.set(headerDate, record.averageVisibilityScore);
    // }

    if (record.averagePosition !== undefined) {
      averagePositionScoreMap.set(headerDate, record.averagePosition);
    }
  });

  const combinedMatrix: (string | number)[][] = [];
  combinedMatrix.push(headersCombined);

  uniqueDates.forEach((date) => {
    const headerDate = prepareHeaderDate(date, periodType);

    combinedMatrix.push([
      headerDate,
      // shareOfVoiceMap.get(headerDate) ?? (null as unknown as string | number),
      // aiVisibilityScoreMap.get(headerDate) ?? (null as unknown as string | number),
      averagePositionScoreMap.get(headerDate) ?? (null as unknown as string | number),
    ]);
  });

  matrixEstimatedTraffic.unshift(headersEstimatedTraffic);

  return {
    matrix: matrixEstimatedTraffic,
    secondMatrix: combinedMatrix,
    colorsMap,
  };
};
