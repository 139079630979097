import './LoginBox.scss';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { getInitials } from '../../utils/utils';
import { useAuth } from '../../contexts/auth/AuthContext';
import { useAccount } from '../../contexts/account/AccountContexts';
import Loader from '../common/loader/Loader';
import { useTranslation } from 'react-i18next';

const LoginBox: React.FC = () => {
  const { logout } = useAuth();
  const { t } = useTranslation();
  const { accountResponse } = useAccount();
  const [name, setName] = useState<string>('');

  useEffect(() => {
    if (!accountResponse) {
      return;
    }

    setName(accountResponse.firstName + ' ' + accountResponse.lastName);
  }, [accountResponse]);

  if (!accountResponse) {
    return <Loader />;
  }

  return (
    <div className="login-box">
      <div className="login-box__content">
        <div className="login-box__avatar">{getInitials(name)}</div>
        <div className="login-box__name">{name}</div>
      </div>
      <div className="login-box__panel">
        <NavLink
          className={({ isActive }) => (isActive ? 'login-box__item--active' : '') + ' login-box__item'}
          to="/settings"
        >
          <i className="material-symbols-outlined">settings</i>
          {t('settings')}
        </NavLink>
        <button onClick={logout} className="login-box__item">
          <i className="material-symbols-outlined">logout</i>
          {t('logout')}
        </button>
      </div>
    </div>
  );
};

export default LoginBox;
