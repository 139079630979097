import React from 'react';
import Panel from '../panel/Panel';
import './Layout.scss';
import LoginBox from '../../login-box/LoginBox';
import { NavLink } from 'react-router-dom';
import { PROJECTS } from '../../../routes/path';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="layout">
      <div className="layout__page">
        <div className="layout__left">
          <Panel />
        </div>
        <div className="layout__right">
          <main>
            <>
              <div className="layout__belt">
                <div className="logo">
                  <NavLink to={PROJECTS}>
                    <div className="logo-box" />
                  </NavLink>
                </div>
                <div className="layout__login-box">
                  <LoginBox />
                </div>
              </div>
              {children}
            </>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
