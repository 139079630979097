import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import {
  getTokenData,
  isTokenValid,
  refreshAccessToken,
  removeTokenData,
  saveTokenData,
} from '../../services/auth/AuthService';
import { TokenData } from '../../models/auth/TokenData';
import Loader from '../../components/common/loader/Loader';

interface AuthContextType {
  isAuthenticated: boolean;
  login: (tokenData: TokenData) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isInitialize, setInitialize] = useState(false);
  useEffect(() => {
    const init = async () => {
      const tokenData = getTokenData();
      if (tokenData && !isTokenValid(tokenData)) {
        refreshAccessToken()
          .then(() => checkAuthenticated())
          .catch(() => {
            removeTokenData();
            window.location.href = '/app/login';
          });
        return;
      }

      checkAuthenticated();
    };

    init();
  }, []);

  const checkAuthenticated = () => {
    const tokenData = getTokenData();

    if (tokenData && isTokenValid(tokenData)) {
      setAuthenticated(true);
    } else {
      removeTokenData();
      setAuthenticated(false);
    }

    setInitialize(true);
  };

  const login = (tokenData: TokenData) => {
    saveTokenData(tokenData);
    setAuthenticated(true);
  };

  const logout = () => {
    removeTokenData();
    setAuthenticated(false);
  };

  if (!isInitialize) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw Error('useAuth must be used with an AuthProvider');
  }

  return context;
};
