import { client } from '../../../../shared/services/apiClient';
import { UserDataResponse } from '../../../../shared/models/user/data/UserDataResponse';
import { SimpleUserDataResponse } from '../../../../shared/models/auth/SimpleUserDataResponse';

export const saveMyAccountData = async (data: SimpleUserDataResponse): Promise<UserDataResponse> => {
  return client(true, true)
    .put<SimpleUserDataResponse>('/account/update-data', data)
    .then((response) => response.data)
    .catch((error) => error);
};
