import { PromptResponse } from '../../../models/monitor/PromptResponse';
import { client } from '../../apiClient';
import { MonitorPromptResponse } from '../../../models/monitor/MonitorPromptResponse';
import { PromptsData } from '../../../../pages/prompt/models/PromptsData';

const PROMPT_ENDPOINT = '/monitor/prompt';

export const getPromptData = async (id: string): Promise<PromptResponse> => {
  return client(true, true)
    .get<MonitorPromptResponse[]>(`${PROMPT_ENDPOINT}/${id}`)
    .then((response) => response.data)
    .catch((error) => error);
};

export const refreshStats = async (projectId: number): Promise<boolean> => {
  return client(true, true)
    .get<boolean>(`${PROMPT_ENDPOINT}/refresh-stats/${projectId}`)
    .then((response) => response.data)
    .catch((error) => error);
};

export const getPromptsList = async (): Promise<PromptsData[]> => {
  return client(true, true)
    .get<PromptsData[]>(`${PROMPT_ENDPOINT}/list`)
    .then((response) => response.data)
    .catch((error) => error);
};

export const getPromptsListForProject = async (projectId: number): Promise<PromptsData[]> => {
  return client(true, true)
    .get<PromptsData[]>(`${PROMPT_ENDPOINT}/list/${projectId}`)
    .then((response) => response.data)
    .catch((error) => error);
};

export const removePrompt = async (id: number | undefined): Promise<boolean> => {
  return client(true, true)
    .delete<boolean>(`${PROMPT_ENDPOINT}/remove/${id}`)
    .then((response) => response.data)
    .catch((error) => error);
};

export const setPromptProject = async (
  promptId: number,
  data: { project: number | null }
): Promise<boolean> => {
  return client(true, true)
    .post<boolean>(`${PROMPT_ENDPOINT}/update/${promptId}`, data)
    .then((response) => response.data)
    .catch((error) => error);
};

export const updateAllModelQuestions = async (): Promise<boolean> => {
  return client(true, true)
    .get<boolean>(`${PROMPT_ENDPOINT}/update-model-questions`)
    .then((response) => response.data)
    .catch((error) => error);
};
