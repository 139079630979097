import React from 'react';
import './SettingsPage.scss';
import Title from '../../shared/components/title/Title';
import Tabs from '../../shared/components/tabs/Tabs';
import { TabItem } from '../../shared/components/tabs/models/TabsModel';
import { useAccount } from '../../shared/contexts/account/AccountContexts';
import { AccountType } from '../../shared/types/account/AccountType';
import { useTranslation } from 'react-i18next';
import { isAdminOrOwner } from '../../shared/utils/utils';

interface SettingsProps {
  children: React.ReactNode;
  restUrl: string;
}

const SettingsPage: React.FC<SettingsProps> = ({ children, restUrl }) => {
  const { t } = useTranslation();
  const { accountResponse } = useAccount();
  const showPricing = Boolean(process.env.REACT_SHOW_PRICING) || false;
  const unsupportedStatus = ['TRIAL', 'LOST_TRIAL'] as AccountType[];
  const menuItems: TabItem[] = [
    {
      icon: 'id_card',
      url: `/settings/my-account`,
      label: t('myAccount'),
      isActive: restUrl === 'my-account',
    },
    {
      icon: 'password',
      url: `/settings/change-password`,
      label: t('changePassword'),
      isActive: restUrl === 'change-password',
    },
    ...(accountResponse && isAdminOrOwner(accountResponse.role) && showPricing
      ? [
          {
            icon: 'receipt_long',
            url: `/settings/billing-data`,
            label: t('billingData'),
            isActive: restUrl === 'billing-data',
          },
          {
            icon: 'account_balance',
            url: `/settings/subscription`,
            label: t('subscription'),
            isActive: restUrl === 'subscription',
          },
        ]
      : []),
    ...(accountResponse &&
    !unsupportedStatus.includes(accountResponse.accountType) &&
    isAdminOrOwner(accountResponse.role) &&
    showPricing
      ? [
          {
            icon: 'shopping_cart',
            url: `/settings/orders`,
            label: t('orders'),
            isActive: restUrl === 'orders',
          },
        ]
      : []),
    {
      icon: 'group',
      url: `/settings/team`,
      label: t('team'),
      isActive: restUrl === 'team',
    },
  ];

  return (
    <>
      <Title mainCategory={t('settings')} />
      <div className="page-content">
        <div className="page-box">
          <div className="page-box__title-action">
            <div className="page-box__title">
              {menuItems.filter((item) => item.isActive)[0]?.label ?? ''}
            </div>
          </div>
          <Tabs items={menuItems} additionalClass="page-box__tabs" />
          <div className="page-box__content">
            <main>{children}</main>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsPage;
