import { ProjectDailyStatsResponse } from '../../../models/projects/stats/ProjectDailyStatsResponse';
import { client } from '../../apiClient';
import { CumulativeProjectDailyStats } from '../../../../pages/projects/preview/models/CumulativeProjectDailyStats';
import { PieChartData } from '../../../components/chart/models/ChartModel';
import { getDefaultRange } from '../../../utils/utils';
import { format } from 'date-fns';
import { ChatType } from '../../../types/monitor/ChatType';

export const getProjectCumulativeDailyStats = async (
  cumulativeProjectDailyStats: CumulativeProjectDailyStats
): Promise<ProjectDailyStatsResponse> => {
  return client(true, true)
    .post<ProjectDailyStatsResponse>(`/projects/daily-stats`, cumulativeProjectDailyStats)
    .then((response) => response.data)
    .catch((error) => error);
};

export const getProjectShareOfVoice = async (
  id: number,
  chatType: ChatType,
  from?: Date | null,
  to?: Date | null
): Promise<PieChartData[]> => {
  const defaultRange = getDefaultRange();
  from = from ? new Date(from) : defaultRange[0];
  to = to ? new Date(to) : defaultRange[1];

  const formattedFrom = format(from, 'yyyy-MM-dd');
  const formattedTo = format(to, 'yyyy-MM-dd');

  return client(true, true)
    .get<PieChartData[]>(
      `/projects/daily-stats/share-of-voice/${id}/${chatType}/${formattedFrom}/${formattedTo}`
    )
    .then((response) => response.data)
    .catch((error) => error);
};
