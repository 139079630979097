import React, { useState } from 'react';
import './AdminUsersEditPage.scss';
import { useTranslation } from 'react-i18next';
import Loader from '../../../../shared/components/common/loader/Loader';
import { useAccount } from '../../../../shared/contexts/account/AccountContexts';
import { useParams } from 'react-router-dom';

const AdminUsersEditPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { accountResponse, setProjectResponse, projectResponse, setCanFetch } = useAccount();
  const projectsForId = projectResponse?.filter((project) => project.accountId === Number(id));

  if (!projectsForId) {
    return <Loader />;
  }

  return (
    <div className="settings">
      {projectsForId.map((project) => {
        return <div>{project.firmName}</div>;
      })}
    </div>
  );
};

export default AdminUsersEditPage;
