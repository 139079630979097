import React, { useState } from 'react';
import './AdminRegisterUser.scss';
import { useTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';
import { useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { UserData } from '../../../signup/models/UserData';
import { LOGIN } from '../../../../shared/routes/path';
import { registerUser } from '../../../../shared/services/admin/users/AdminUsersService';

interface AdminRegisterUserProps {
  afterSave: () => void;
}

const AdminRegisterUser: React.FC<AdminRegisterUserProps> = ({ afterSave }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [apiError, setApiError] = useState<string | null>(null);

  const onSubmit = async (formData: any) => {
    const userData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      username: formData.email,
      password: formData.password,
      language: i18n.language,
      client_id: process.env.REACT_APP_CLIENT_ID || '',
      grant_type: 'password',
      browserData: JSON.stringify(navigator.userAgent),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      recaptchaToken: 'true',
    };

    const urlEncodedData = new URLSearchParams();
    for (const key in userData) {
      if (userData.hasOwnProperty(key)) {
        urlEncodedData.append(key, userData[key as keyof UserData]);
      }
    }

    await registerUser(urlEncodedData)
      .then(() => {
        afterSave();
      })
      .catch((err) => {
        if (err.statusCode === 401) {
          navigate(LOGIN);
          return;
        }

        setApiError(err.response.data.error);
      });
  };

  const clearApiError = () => {
    setApiError(null);
  };

  return (
    <div className="">
      <form onSubmit={handleSubmit(onSubmit)} className="form-box__form">
        <div className="form-box__row form-box__row--with-col">
          <div className="form-box__col">
            <div className="form-box__label">{t('firstName')}:</div>
            <InputText
              onInput={clearApiError}
              {...register('firstName', { required: t('fieldIsRequired') })}
              className={errors.firstName || apiError ? 'form-box__invalid' : ''}
            />
            {errors.firstName && <span>{errors.firstName.message as string}</span>}
          </div>
          <div className="form-box__col">
            <div className="form-box__label">{t('lastName')}:</div>
            <InputText
              onInput={clearApiError}
              {...register('lastName', { required: t('fieldIsRequired') })}
              className={errors.lastName || apiError ? 'form-box__invalid' : ''}
            />
            {errors.lastName && <span>{errors.lastName.message as string}</span>}
          </div>
        </div>

        <div className="form-box__row">
          <div className="form-box__label">{t('emailAddress')}:</div>
          <InputText
            onInput={clearApiError}
            {...register('email', {
              required: t('fieldIsRequired'),
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: t('wrongEmailAddress'),
              },
            })}
            className={errors.email || apiError ? 'form-box__invalid' : ''}
          />
          {errors.email && <span>{errors.email.message as string}</span>}
        </div>

        <div className="form-box__row">
          <div className="form-box__label">{t('password')}:</div>
          <InputText
            onInput={clearApiError}
            type="password"
            {...register('password', { required: t('fieldIsRequired') })}
            className={errors.password || apiError ? 'form-box__invalid' : ''}
          />
          {errors.password && <span>{errors.password.message as string}</span>}
          {apiError && <span>{apiError}</span>}
        </div>

        <Button className="btn btn--sm" label="Create account" type="submit" />
      </form>
    </div>
  );
};

export default AdminRegisterUser;
