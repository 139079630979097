import React from 'react';
import './TeamEditPage.scss';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { getInitials, isAdminOrOwner } from '../../../../shared/utils/utils';
import { getTokenData } from '../../../../shared/services/auth/AuthService';
import { TokenData } from '../../../../shared/models/auth/TokenData';
import { useTranslation } from 'react-i18next';
import { useAccount } from '../../../../shared/contexts/account/AccountContexts';
import { removeUser } from '../services/TeamService';

const TeamEditPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { accountResponse } = useAccount();
  const { id, email, firstName, lastName, role, isActive } = location.state || {};
  const tokenData: TokenData | null = getTokenData();
  const permissionsMap = new Map([
    ['ADMIN', t('admin')],
    ['USER', t('user')],
    ['OWNER', t('owner')],
  ]);

  const confirmRemove = () => {
    confirmDialog({
      message: t('areYouSureProceed'),
      header: t('areYouSure'),
      defaultFocus: 'accept',
      accept: () => remove(),
    });
  };

  const remove = () => {
    removeUser(id).then((isRemove) => {
      if (isRemove) {
        navigate('/settings/team');
      }
    });
  };

  if (!tokenData) {
    return <></>;
  }

  return (
    <div className="team-edit-page">
      <ConfirmDialog className="dialog-box" />

      <div>
        <div className="team-edit-page__content">
          <div className={isActive ? 'user' : 'user user--inactive'}>
            {getInitials(`${firstName} ${lastName}`)}
          </div>
          <div className="team-edit-page__data">
            <div className="team-edit-page__row">
              <span>{t('firstName')}:</span>
              <span>{firstName}</span>
            </div>
            <div className="team-edit-page__row">
              <span>{t('lastName')}:</span>
              <span>{lastName}</span>
            </div>
            <div className="team-edit-page__row">
              <span>{t('emailAddress')}:</span>
              <span>{email}</span>
            </div>
            <div className="team-edit-page__row">
              <span>{t('role')}:</span>
              <span>{permissionsMap.get(role)}</span>
            </div>
            {tokenData.user.id !== id &&
              role !== 'OWNER' &&
              accountResponse &&
              isAdminOrOwner(accountResponse.role) && (
                <div>
                  <span className=" btn--link btn--link-red" onClick={() => confirmRemove()}>
                    {t('remove')}
                  </span>
                </div>
              )}
          </div>
        </div>
      </div>
      <div className="change-password-page__help">
        <div className="help-box">
          <label className="help-box__title">{t('manageUser')}</label>
          <div>{t('manageUserMsg')}</div>
        </div>
      </div>
    </div>
  );
};

export default TeamEditPage;
