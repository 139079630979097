import { client } from '../apiClient';
import { AccountResponse } from '../../models/account/AccountResponse';
import { ProjectResponse } from '../../models/projects/ProjectResponse';
import { MonitorPrompt } from '../../models/monitor/MonitorPrompt';
import { ChangeProjectNameData } from '../../models/projects/ChangeProjectNameData';

export const changeProjectName = async (
  changeProjectNameData: ChangeProjectNameData
): Promise<boolean> => {
  return client(true, true)
    .post<MonitorPrompt[]>(`/projects/change-name`, changeProjectNameData)
    .then((response) => response.data)
    .catch((error) => error);
};

export const getProjects = async (): Promise<ProjectResponse[]> => {
  return client(true, true)
    .get<AccountResponse[]>(`/projects/`)
    .then((response) => response.data)
    .catch((error) => error);
};

export const removeProject = async (id: number): Promise<boolean> => {
  return client(true, true)
    .delete(`/projects/${id}`)
    .then((response) => response.data)
    .catch((error) => error);
};

export const duplicateProject = async (id: number): Promise<boolean> => {
  return client(true, true)
    .post(`/projects/copy/${id}`)
    .then((response) => response.data)
    .catch((error) => error);
};

export const getPromptsForProject = async (projectId: number): Promise<MonitorPrompt[]> => {
  return client(true, true)
    .get<MonitorPrompt[]>(`/monitor/prompt/list/project/prompts/${projectId}`)
    .then((response) => response.data)
    .catch((error) => error);
};
