import React, { useCallback, useEffect, useState } from 'react';
import './PromptsPage.scss';
import { PromptsData } from './models/PromptsData';
import Loader from '../../shared/components/common/loader/Loader';
import Title from '../../shared/components/title/Title';
import EmptyState from '../../shared/components/common/empty-state/EmptyState';
import { useTranslation } from 'react-i18next';
import PromptsTable from '../../shared/components/prompts-table/PromptsTable';
import { useStats } from '../../shared/contexts/StatsContexts';
import { getPromptsList } from '../../shared/services/monitor/prompt/PromptService';

const PromptsPage: React.FC = () => {
  const { setMonitorPeriod, setSelectedFirm } = useStats();
  const [monitorData, setMonitorData] = useState<PromptsData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState(false);
  const { t } = useTranslation();

  const emptyStateData = {
    title: t('noPromptsYet'),
    content: t('noPromptsYetMsg'),
  };

  setMonitorPeriod(null);

  const fetchMonitorData = async () => {
    getPromptsList()
      .then((monitorData) => {
        setMonitorData(monitorData);
        setLoading(false);
        setMonitorPeriod('DAILY');
        setSelectedFirm(null);
      })
      .catch((err) => err);
  };

  // useAutoRefreshView(fetchMonitorData, 60_000);
  useEffect(() => {
    fetchMonitorData();
  }, [refresh, t]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Title mainCategory={t('questions')} />
      <div className="prompts-page page-content">
        <div className="page-box">
          {monitorData.length > 0 ? (
            <PromptsTable
              tableTitle={t('monitoringQuestions')}
              data={monitorData}
              refreshAfterDelete={() => setRefresh((prev) => !prev)}
              place="QUESTIONS"
              chatType={'ALL'}
              periodType={'DAILY'}
            />
          ) : (
            <EmptyState emptyStateData={emptyStateData} className="prompts-page__main" />
          )}
        </div>
      </div>
    </>
  );
};

export default PromptsPage;
