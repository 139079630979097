import React from 'react';
import AppRoutes from './shared/routes/AppRoutes';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './shared/contexts/auth/AuthContext';
import { AccountProvider } from './shared/contexts/account/AccountContexts';
import './i18n';

const App = () => {
  return (
    <div className="page">
      <BrowserRouter basename="/app">
        <AuthProvider>
          <AccountProvider>
            <AppRoutes />
          </AccountProvider>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
