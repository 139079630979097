import React, { useState } from 'react';
import './LoginPage.scss';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { signIn } from './services/LoginService';
import { AuthData } from './models/LoginFormData';
import { useAuth } from '../../shared/contexts/auth/AuthContext';
import { useTranslation } from 'react-i18next';
import { PROJECTS } from '../../shared/routes/path';

const LoginPage: React.FC = () => {
  const [apiError, setApiError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { login } = useAuth();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (loginFormData: any) => {
    const data = {
      ...loginFormData,
      grant_type: 'password',
      client_id: process.env.REACT_APP_CLIENT_ID || '',
      browserData: JSON.stringify(navigator.userAgent),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    const urlEncodedData = new URLSearchParams();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        urlEncodedData.append(key, data[key as keyof AuthData]);
      }
    }

    await signIn(urlEncodedData.toString())
      .then((response) => {
        login(response.data);
        navigate(PROJECTS);
      })
      .catch((err) => setApiError(err.response.data.error));
  };

  const clearApiError = () => {
    setApiError(null);
  };

  return (
    <div className="login-page">
      <div className="login-page__left">
        <div className="logo-box" />
        <form onSubmit={handleSubmit(onSubmit)} className="form-box">
          <div className="form-box__title">{t('loginToYourAccount')}:</div>
          <div className="form-box__form">
            <div className="form-box__row">
              <div className="form-box__label">{t('emailAddress')}:</div>
              <InputText
                onInput={clearApiError}
                {...register('username', {
                  required: t('fieldIsRequired'),
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: t('wrongEmailAddress'),
                  },
                })}
                className={errors.username || apiError ? 'form-box__invalid' : ''}
              />
              {errors.username && <span>{errors.username.message as string}</span>}
            </div>

            <div className="form-box__row">
              <div className="form-box__label">{t('password')}:</div>
              <InputText
                type="password"
                {...register('password', { required: t('fieldIsRequired') })}
                className={errors.password || apiError ? 'form-box__invalid' : ''}
                onInput={clearApiError}
              />
              {errors.password && <span>{errors.password.message as string}</span>}
              {apiError && <span>{apiError}</span>}
            </div>
            <div className="form-box__row form-box__row--link">
              <Link to="/forgot">{t('forgotPassword')}?</Link>
            </div>

            <Button className="btn" label={t('signIn')} type="submit" />
            <div className="form-box__row form-box__row--info">
              {t('dontHaveAnAccount')}? <Link to="/signup">{t('signUp')}</Link>
            </div>
          </div>
        </form>
      </div>
      <div className="login-page__right">
        <div className="login-page__title">{t('loginPageTitle')}</div>
        <div className="login-page__subtitle" dangerouslySetInnerHTML={{ __html: t('loginPageDesc') }} />
      </div>
    </div>
  );
};

export default LoginPage;
